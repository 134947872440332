import React from "react";
import { InsightBarChart } from "./InsightBarChart";
import styles from "./InsightSummaryInLandingPage.less";
import { currencyFormatter } from "../../../utils/currency";
import { useGotoPage } from "../../../hooks/useGotoPage";
import { Pages, SubPages } from "../../../models/Nav";
import { useGetInsightsOverviewData } from "../../../hooks/useInsightPlatformQuery";
import { IInsightUsageBO, PlatformTypeEnum } from "../../../models/InsightsPlatformModels";
import LoadingState from "../../ResponseBoundary/LoadingState";
import { LogComponent, LogElement, LogTarget } from "../../../models/LogModel";
import { trackEventCallback } from "../../../utils/AppInsights";

export const InsightSummaryInLandingPage: React.FC = () => {
    const gotoPage = useGotoPage();

    const { data: insightOverviewCost, isLoading } = useGetInsightsOverviewData();

    let anomalyData = undefined;
    let anomalyTotalCost = 0;
    let anomalyUsageSummaryData = {} as Record<PlatformTypeEnum, number>;
    let anomalyDetailList:IInsightUsageBO[] = [];

    let recommendationData = undefined;
    let recommendationTotalCost = 0;
    let recommendationUsageSummaryData = {} as Record<PlatformTypeEnum, number>;
    let recommendationDetailList:IInsightUsageBO[] = [];

    let realizedData = undefined;
    let realizedTotalCost = 0;
    let realizedUsageSummaryData = {} as Record<PlatformTypeEnum, number>;
    let realizedDetailList:IInsightUsageBO[] = [];

    let anomalyHeader = '';
    let recommendationHeader = '';
    let realizedHeader = '';

    if (!isLoading && insightOverviewCost != undefined) 
    {
        anomalyData = insightOverviewCost!.Anomaly;
        anomalyTotalCost = anomalyData?.totalSaving | 0;
        anomalyUsageSummaryData = anomalyData?.usageSummary || [];
        anomalyDetailList = anomalyData?.detailList || [];

        recommendationData = insightOverviewCost!.Recommendation;
        recommendationTotalCost = recommendationData?.totalSaving | 0;
        recommendationUsageSummaryData = recommendationData?.usageSummary || [];
        recommendationDetailList = recommendationData?.detailList || [];

        realizedData = insightOverviewCost!.Realized;
        realizedTotalCost = realizedData?.totalSaving | 0;
        realizedUsageSummaryData = realizedData?.usageSummary || [];
        realizedDetailList = realizedData?.detailList || [];

        anomalyHeader = `Anomalies: ${currencyFormatter(anomalyTotalCost || 0)}`;
        recommendationHeader = `Potential Savings: ${currencyFormatter(recommendationTotalCost || 0)}`;
        realizedHeader = `Realized Savings: ${currencyFormatter(realizedTotalCost || 0)}`;
    }

    const message = 'Review your service\'s anomalies and cost saving opportunities in the Insight Portal. Each insight provides details for investigation and actionable recommendations on how to resolve.';


    
    return (
        <div>
            <div className={styles.section}>
                <div className={styles.sectionHeaderContent2}>
                    <h4
                        className={styles.sectionHeaderContent2}
                        onClick={() => {
                            trackEventCallback(LogComponent.InsightOverviewInLandingPage, LogElement.InsightOverview, "Navigate to Insight overview", LogTarget.Title);
                            gotoPage(`${Pages.InsightPortal}/${SubPages.InsightPortalOverview}`);
                        }}
                    >
                        Insights
                    </h4>
                    <button style={{backgroundColor:"#0F6CBD",  height:20, color:"white", fontWeight:600, fontSize:9, fontFamily:"Segoe UI", border:"none", borderRadius:"3px", padding:"4px 3px 3px 5px", letterSpacing:2, position:"absolute", right:10}}>NEW</button>
                </div>
            </div>
            {isLoading ? (<LoadingState />): (
                <div style={{height:530, marginTop:16}}>
                    <div style={{height:56, marginBottom:40}}><span style={{fontSize:13, fontWeight:400, height:14, color:"#484644", marginTop: 6, display: "inline-block"}}>{message}</span></div>
                    <div className={styles.title}>
                        <span className={styles.titleTotalCost}>{currencyFormatter(anomalyTotalCost, 2, "$")}</span> Monthly Anomalous Cost Increase
                    </div>
                    <InsightBarChart metricValueRecord={anomalyUsageSummaryData}/>

                    {insightOverviewCost == undefined || recommendationTotalCost == 0 ? (
                                <></>
                            ) : (
                                <div>
                                    <div className={styles.title} style={{marginTop:40}}>
                                        <span className={styles.titleTotalCost}>{currencyFormatter(recommendationTotalCost, 2, "$")}</span> Total Cost Saving Opportunities (Monthly)
                                    </div>
                                    <InsightBarChart metricValueRecord={recommendationUsageSummaryData}/>
                                </div>
                    )}

                </div>
            )}
            <div className={styles.cardFooter}>
                <button
                    className={styles.navButton} onClick={function (): void {
                        gotoPage(`${Pages.InsightPortal}/${SubPages.InsightPortalOverview}`);
                    }}>
                    View Insights
                </button>
            </div>
        </div>
    );
};