import { Button, Link, MessageBar, MessageBarBody, MessageBarTitle } from "@fluentui/react-components";
import { CosmicSourceTypeEnum, ICosmicHierarchicalMetricReport, ICosmicHierarchicalWorkloadReport } from "../../models/CosmicModels";
import { LogComponent, LogElement } from "../../models/LogModel";

import CosmicSelector from "./CosmicSelector";
import { LineChartContainer } from "../azure/common/LineChartContainer";
import PartnerMetricsCard from "./PartnerMetricsCard";
import PartnerServiceDetailTable from "./PartnerServiceDetailTable";
import React from "react";
import commonStyles from "../common/styles/Common.less";
import { exportStringToCsv } from "../../utils/common";
import styles from './CosmicView.less';
import { useFlights } from "../../hooks/useSettings";
import { useGetCosmicWorkloadDailyAppMetric } from "../../hooks/useCosmicQuery";

interface IPartnerViewProps {
    type: CosmicSourceTypeEnum;
}

const getMetricData = (metricName: string, metricsType: ICosmicHierarchicalMetricReport[]) => {
    if (!metricsType) return [];

    return metricsType
        .filter((singleMetric) => singleMetric.metric === metricName)
        .flatMap((singleMetric) => singleMetric.data);
};

const PartnerView: React.FC<IPartnerViewProps> = (props) => {
    const { data: flights } = useFlights();
    const { cosmicHierarchicalMetricReport: appCoresWorkloadMetrics, isLoading: isLoadingAppCores } = useGetCosmicWorkloadDailyAppMetric("AppCores");
    const { cosmicHierarchicalMetricReport: appCoreCogsWorkloadMetrics, isLoading: isLoadingAppCoreCogs } = useGetCosmicWorkloadDailyAppMetric("AppCoreCogs");

    const metricDataConfig = [
        { title: "App Request Cores by Cosmic Workload", metric: "CpuRequestCores", logElement: LogElement.CosmicAppRequestCores, workloads: getMetricData("CpuRequestCores", appCoresWorkloadMetrics) },
        { title: "App Core Cost by Cosmic Workload", metric: "AppCoreCogs", logElement: LogElement.CosmicAppCoreCOGS, workloads: getMetricData("AppCoreCogs", appCoreCogsWorkloadMetrics) },
        { title: "App Limit Cores by Cosmic Workload", metric: "CpuLimitCores", logElement: LogElement.CosmicAppLimitCores, workloads: getMetricData("CpuLimitCores", appCoresWorkloadMetrics) },
        { title: "App Empty Cores by Cosmic Workload", metric: "EmptyAppCores", logElement: LogElement.CosmicAppEmptyCores, workloads: getMetricData("EmptyAppCores", appCoresWorkloadMetrics) },
    ];
    const utilizationMetricDataConfig = { title: "App P99 Utilization by Cosmic Workload", metric: "CpuUtilizationP99", logElement: LogElement.CosmicAppP99CpuUtilization, data: getMetricData("CpuUtilizationP99", appCoresWorkloadMetrics) };
    
    const metricDataConfigForAppCoreV2 = [
        { title: "App Cores by Cosmic Workload", metric: "AppCores", logElement: LogElement.CosmicAppRequestCores, workloads: getMetricData("AppCores", appCoresWorkloadMetrics) },
        { title: "App Core Cost by Cosmic Workload", metric: "AppCoreCogs", logElement: LogElement.CosmicAppCoreCOGS, workloads: getMetricData("AppCoreCogs", appCoreCogsWorkloadMetrics) },
        { title: "App Empty Cores by Cosmic Workload", metric: "EmptyAppCores", logElement: LogElement.CosmicAppEmptyCores, workloads: getMetricData("EmptyAppCores", appCoresWorkloadMetrics) },
        { title: "App P99 Utilization by Cosmic Workload", metric: "CpuUtilizationP99", logElement: LogElement.CosmicAppP99CpuUtilization, workloads: getMetricData("CpuUtilizationP99", appCoresWorkloadMetrics) }
    ];

    return (
        <div className={styles.cardContainer}>
            {/*<InfoMessage />*/}
             <LinkMessage />
            <CosmicSelector />

            {!flights?.enableCosmicAppCoreV2Flight && (
                <>
                    <PartnerMetricsCard appCoreCogs={appCoreCogsWorkloadMetrics} isLoadingAppCoreCogs={isLoadingAppCoreCogs} />
                    <MetricsCharts
                        metricsConfig={metricDataConfig}
                        isLoadingAppCores={isLoadingAppCores}
                        isLoadingAppCoreCogs={isLoadingAppCoreCogs}
                        type={props.type}
                    /> 
                    <LineChartContainer
                        key={utilizationMetricDataConfig.title}
                        title={utilizationMetricDataConfig.title}
                        isLoading={!utilizationMetricDataConfig.data}
                        isNotCurrency
                        series={utilizationMetricDataConfig.data.map(singleMetric => ({
                            name: singleMetric.workload,
                            data: singleMetric.data.map(metric => [metric.date.valueOf(), (metric.metricValue ?? 0) * 100])
                        })) || []}
                        logProps={{
                            logComponent: LogComponent.CosmicPartnerView,
                            logElement: utilizationMetricDataConfig.logElement,
                            customProperties: { type: props.type }
                        }}
                        fillMissingAbnormalData
                        suffix="%"
                        anomalies={[]}
                    />
                    <PartnerServiceDetailTable defaultSorting="CpuRequestCores" />
                </>
            )}

            {flights?.enableCosmicAppCoreV2Flight && (
                <>
                    <PartnerMetricsCard appCoreCogs={appCoreCogsWorkloadMetrics} isLoadingAppCoreCogs={isLoadingAppCoreCogs} />
                    <MetricsCharts
                        metricsConfig={metricDataConfigForAppCoreV2}
                        isLoadingAppCores={isLoadingAppCores}
                        isLoadingAppCoreCogs={isLoadingAppCoreCogs}
                        type={props.type}
                    /> 
                    <PartnerServiceDetailTable defaultSorting="AppCores" />
                </>
            )}
        </div>
    );
};

const MetricsCharts: React.FC<{ metricsConfig: { title: string; metric: string; logElement: LogElement; workloads: ICosmicHierarchicalWorkloadReport[] }[], isLoadingAppCores: boolean, isLoadingAppCoreCogs: boolean, type: CosmicSourceTypeEnum }> = ({ metricsConfig, isLoadingAppCores, isLoadingAppCoreCogs, type }) => (
    <div className={`${commonStyles.gridWrapper} ${commonStyles.gridColumn2}`} style={{ padding: "0 0 24px 0" }}>
        {metricsConfig.map(({ title, metric, logElement, workloads }) => (
            <div key={title} className={styles.chartContainer} style={{ position: "relative" }}>
                <LineChartContainer
                    key={title}
                    title={title}
                    isLoading={title.includes("Cost") ? isLoadingAppCoreCogs : isLoadingAppCores}
                    isNotCurrency={!title.includes("Cost")}
                    series={workloads.map(singleMetric => ({
                        name: singleMetric.workload,
                        data: singleMetric.data.map(metric => [metric.date.valueOf(), (metric.metricValue ?? 0) * (title.includes('Utilization') ? 100 : 1)])
                    })) || []}
                    logProps={{
                        logComponent: LogComponent.CosmicPartnerView,
                        logElement,
                        customProperties: { type }
                    }}
                    fillMissingAbnormalData
                    suffix={title.includes('Utilization') ? '%' : ''}
                    showArea={!title.includes('Utilization')}
                    anomalies={[]} />
            
                {((title.includes("Cost") && !isLoadingAppCoreCogs) || (!title.includes("Cost") && !isLoadingAppCores)) && (
                    <Button onClick={()=> handleDownload(title, metric, workloads)} > Download CSV </Button>
                )}
            </div>
        ))}
    </div>
);

// const InfoMessage = () => (
//     <MessageBar intent="info" style={{ marginBottom: "24px" }}>
//         <MessageBarBody>
//             <MessageBarTitle>Important</MessageBarTitle>
//             AIRS typically locks the data for the last month on a specific date. Therefore, please be aware that the COGS values for the last month may be refreshed on certain dates.<br />
//         </MessageBarBody>
//     </MessageBar>
// );
const LinkMessage = () => (
    <MessageBar intent="success" style={{ marginBottom: "14px" }}>
        <MessageBarBody>
            <MessageBarTitle>Sovereign Cloud Dashboard: </MessageBarTitle>
            <Link href="https://o365exchange.visualstudio.com/O365%20Core/_wiki/wikis/O365%20Core.wiki/456975/Cosmic-Jaws-Sovereign-Cloud-Dashboard" target="__blank">
                More details
            </Link>
            </MessageBarBody>
    </MessageBar>
);
export default PartnerView;

function handleDownload(title: string, metricName: string, data: ICosmicHierarchicalWorkloadReport[]): React.MouseEventHandler<HTMLButtonElement> | undefined {
    if (!data) {
        return;
    }
    const csvData = prepareCSVData(metricName, data);
    exportStringToCsv(csvData, title);
}
function prepareCSVData(metric: string, data: ICosmicHierarchicalWorkloadReport[]){
    const csvData: [number, string, number][] = [];
    const series = data.map(singleMetric => ({
        name: singleMetric.workload,
        data: singleMetric.data.map(metric => [metric.date.valueOf(), metric.metricValue ?? 0 ])
    })) || [];

    for(const workloadName in series) {
        const data = series[workloadName].data;
        for (const item of data) {
            const cost = item[1];
            csvData.push([item[0], series[workloadName].name, cost]);
        }
    }
    // list of data for each row, [date, workload, metricValue], sort by date, metricValue
    csvData.sort((a, b) => (new Date(a[0]).getTime() - new Date(b[0]).getTime()) || (b[2] - a[2]));

    // Date, Workload, AppCores
    const rowData = csvData.map((item) => item.join(","));
    return ["ExtractionDate", "Workload", metric].join(",") + "\n" + rowData.join("\n");
}