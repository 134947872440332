export const enum LogComponent {
    PivotHeadPane = "pivotHeadPane",
    PivotTreePane = "pivotTreePane",
    PivotNavPane = "pivotNavPane",
    SubstrateChartingPane = "SubstrateChartingPane",
    TransChartingPane = "TransChartingPane",
    ProcessHostingChartingPane = "ProcessHostingChartingPane",
    NetworkChartingPane = "NetworkChartingPane",
    HDDChartingPane = "HDDChartingPane",
    SSDChartingPane = "SSDChartingPane",
    SearchPane = "SearchPane",
    RightSidePane = "rightSidePane",
    SaveViewDial = "saveViewDial",
    ShareDial = "shareDial",
    UpdateFilters = "updateFilters",
    Url = "Url",
    TeachingBubbles = 'teachingBubbles',
    AzureComputeRegionDetail = 'azureComputeRegionDetail',
    CosmosView = 'cosmosView',
    CosmicView = 'cosmicView',
    CosmicPartnerView = 'cosmicPartnerView',
    Storage = 'storage',
    Processing = 'processing',
    AzureTabs = 'azureTabs',
    LandingPage = 'landingPage',
    PublicAzureTabs = 'publicAzureTabs',
    AzscTabs = 'azscTabs',
    NetworkV2Tabs = 'networkV2Tabs',
    TransactionV2Tabs = 'transactionV2Tabs',
    HddStorageV2Tabs = 'hddStorageV2Tabs',
    SsdStorageV2Tabs = 'ssdStorageV2Tabs',
    NetworkRequest = 'networkRequest',
    SubstrateCostTable = 'substrateCostTable',

    Azure = "azure",
    Substrate = "substrate",
    TransactionV2 = "TransactionV2",
    SubstrateV2 = "SubstrateV2",
    NetworkV2 = "NetworkV2",
    HddV2 = "HddV2",
    ProcessHostingV2 = "ProcessHostingV2",
    SsdV2 = "SsdV2",
    CogsMetricCost = "CogsMetricCost",
    FeedbackChart = "FeedbackChart",

    EffiencyTracker = "EffiencyTracker",

    IOInsights = "IOInsights",

    PCMPlatformBackend = "PCMPlatformBackend",

    TelemetryView = 'telemetryView',
    Cosmos = 'cosmos',
    Blueshift = 'blueshift',
    CosmosStorage = 'cosmosStorage',
    CosmosProcessing = 'cosmosProcessing',
    BlueshiftStorage = 'blueshiftStorage',
    BlueshiftProcessing = 'blueshiftProcessing',
    Kusto = 'kusto',
    GenevaMds = 'genevaMds',
    GenevaMdm = 'genevaMdm',
    Aria = 'aria',
    PassiveMonitoringView = 'passiveMonitoringView',

    InsightPortal = 'insightPortal',
    InsightOverviewInLandingPage = 'insightOverviewInLandingPage',
    InsightTable = 'insightTable',
    InsightCard = 'insightCard',
}

export const enum LogElement {
    ViewAll = "ViewAll",
    ClearAll = "ClearAll",
    SaveView = "SaveView",
    AddFromSearch = "AddFromSearch",
    ShareView = "shareView",
    TimeRange = "timeRange",
    HideTree = "hideTree",
    SavedView = "savedView",
    AddSubView = "addSubView",
    ShareSavedView = "shareSavedView",
    DeleteSavedView = "deleteSavedView",
    MultiSelect = "multiSelect",
    Overview = "overview",
    Substrate = "substrate",
    Transaction = "transaction",
    SSD = "ssd",
    AzureCompute = "azureCompute",
    AzurePublic = "azurePublic",
    AzureAzsc = "azureAzsc",
    Cosmos = "cosmos",
    MemoryRedundancy = "memoryRedundancy",
    AnomalyDetection = "anomalyDetection",
    CosmicJaws = "cosmicJAWS",
    ES365CostTransparency = "ES365CostTransparency",
    PerfCollector = "perfCollector",
    AzSCMigration = "azscMigration",
    CosmicMigration = "cosmicMigration",
    LearnMore = "learnMore",
    Download = "Download",
    Columns = "Columns",
    ItemRWQ = "itemRWQ",
    Assistants = "assistants",
    ServiceInstance = "serviceInstance",
    RequestsProcessed = "requestsProcessed",
    DsapiRequest = "dsapiRequest",
    CfmSubmitted = "cfmSubmitted",
    ScenarioTag = "scenarioTag",
    FoldTrans = "foldTrans",
    SortTrans = "sortTrans",
    ShowMoreTop10Trans = "ShowMoreTop10Trans",
    ItemSize = "itemSize",
    SDSFastStorage = "sdsFastStorage",
    KvCache = "kvCache",
    LearnMoreSubstrate = "learnMoreSubstrate",
    LearnMoreTrans = "learnMoreTrans",
    LearnMoreProcessHosting = "learnMoreProcessHosting",
    LearnMoreNetwork = "learnMoreNetwork",
    LearnMoreHDD = "learnMoreHDD",
    LearnMoreSSD = "learnMoreSSD",
    DownloadSubstrate = "downloadSubstrate",
    DownloadTrans = "downloadTrans",
    DownLoadNetwork = "downLoadNetwork",
    DownloadProcessHosting = "downloadProcessHosting",
    DownloadHDD = "downloadHDD",
    DownloadSSD = "downloadSSD",
    ColumnsSubstrate = "ColumnsSubstrate",
    ColumnsTrans = "ColumnsTrans",
    ColumnsProcessHosting = "ColumnsProcessHosting",
    ColumnsNetwork = "ColumnsNetwork",
    ColumnsHDD = "ColumnsHDD",
    ColumnsSSD = "ColumnsSSD",
    FoldSSD = "foldSSD",
    Fold = "Fold",
    SortSSD = "sortSSD",
    Sort = "Sort",
    SeeAll = "seeAll",
    ShowMoreResults = "showMoreResults",
    Category = "category",
    All = "all",
    ApplySelection = "applySelection",
    CancelSelection = "cancelSelection",
    Save = "save",
    CancelSaveView = "cancelSaveView",
    CopyLink = "copyLink",
    Filters = "Filters",
    Source = "source",
    Previous = 'previous',
    Next = 'next',
    Finish = 'finish',

    CosmosNavLink = 'cosmosNavLink',
    CosmosStorageNavLink = 'cosmosStorageNavLink',
    CosmosProcessingNavLink = 'cosmosProcessingNavLink',

    CosmicNavLink = 'cosmicNavLink',
    CosmicLeadershipNavLink = 'cosmicLeadershipNavLink',
    CosmicLeadershipMetricsCards = 'cosmicLeadershipMetricsCards',
    CosmicTotalCores = 'cosmicTotalCores',
    CosmicTotalCOGS = 'cosmicTotalCogs',
    CosmicPlatformLevelAppP99CpuUtilization = 'cosmicPlatformLevelAppP99CpuUtilization',
    CosmicPlatformOverheadWithRightsizing = 'cosmicPlatformOverheadWithRightsizing',
    CosmicRightsizingSavedAppCores = 'cosmicRightsizingSavedAppCores',

    // CosmicPlatformNavLink = 'cosmicPlatformNavLink',
    CosmicPartnerNavLink = 'cosmicPartnerNavLink',
    CosmicPartnerMetricsCards = 'cosmicPartnerMetricsCostCards',
    CosmicAppRequestCores = 'cosmicAppRequestCores',
    CosmicAppCoreCOGS = 'cosmicAppCoreCOGS',
    CosmicAppLimitCores = 'cosmicAppLimitCores',
    CosmicAppEmptyCores = 'cosmicAppEmptyCores',
    CosmicAppP99CpuUtilization = 'cosmicAppP99CpuUtilization',

    AzureCostChart = "azureCostChart",
    AzureCpuUtilizationChart = 'azureCpuUtilizationChart',
    AzureAcu = 'azureAcu',
    AzureAcuConsumedVsIdle = "azureAcuConsumedVsIdle",
    AzureAcuPublicVsAzsc = "azureAcuPublicVsAzsc",
    AzureAcuTopFive = "azureAcuTopFive",
    AzureIdleAcuTopFive = "azureIdleAcuTopFive",
    AzureAzscMigrationStatus = "azureAzscMigrationStatus",
    AzureUtilizationP99 = "azureUtilizationP99",
    AzureCores = "azureCores",
    AzureReclaimableCoresTopFiveOrg = "azureReclaimableCoresTopFiveOrg",
    AzureReclaimableCoresTopFiveService = "azureReclaimableCoresTopFiveService",
    AzureCpuCoresCountP99 = "azureCpuCoresCountP99",
    AzureRdosSamplePointCount = "azureRdosSamplePointCount",
    AzureCoresTrendTopFive = "AzureCoresTrendTopFive",

    SubstrateTotalCarbonEmission = "substrateTotalCarbonEmission",
    SubstrateRingCost = "substrateRingCost",
    SubstrateTopFive = "substrateTopFive",
    SubstrateMonthOverMonth = "substrateMonthOverMonth",
    SubstrateTotalCostTrends = "substrateTotalCostTrends",
    SubstrateOverviewDonutChart = "substrateOverviewDonutChart",
    SubstrateOverviewCostCards = "substrateOverviewCostCards",
    SubstrateTrendByDeploymentRing = "substrateTrendByDeploymentRing",
    CarbonMore = "carbonMore",
    SubstrateCategoryCostTrends = "SubstrateCategoryCostTrends",
    SubstrateCOGSMetricCostMonthlyTrends = "SubstrateCOGSMetricCostMonthlyTrends",
    ScenariotagTopTrends = "ScenariotagTopTrends",
    SubstrateTransactionCostTrends = "SubstrateTransactionCostTrends",
    SubstrateProcesHostCostTrends = "SubstrateProcesHostCostTrends",
    SubstrateNetworkingCostTrends = "SubstrateNetworkingCostTrends",
    SubstrateHDDCostTrends = "SubstrateHDDCostTrends",
    SubstrateSSDCostTrends = "SubstrateSSDCostTrends",

    CosmosTrendsChart = 'cosmosTrendsChart',
    CosmosGemRankingChart = 'cosmosGemRankingChart',
    CosmosUtilizationChart = 'cosmosUtilizationChart',

    SubstrateTotalCostTable = 'SubstrateTotalCostTable',
    TransactionCostTable = "TransactionCostTable",
    TransactionScenarioTagCostTable = "TransactionScenarioTagCostTable",
    NetworkCostTable = "NetworkCost",
    NetworkScenarioTagCostTable = "NetworkScenarioTagCostTable",
    HDDMailboxCostTable = "HDDMailboxCostTable",
    HDDDatasetCostTable = "HDDDatasetCostTable",
    SSDTotalCostTable = "SSDTotalCostTable",
    SSDDatasetCostTable = "SSDDatasetCostTable",
    SSDMailboxCostTable = "SSDMailboxCostTable",

    AzureServiceTagCostTable = "AzureServiceTagCostTable",
    AzureRegionTagCostTable = "AzureRegionTagCostTable",
    AzureSubscriptionTagCostTable = "AzureSubscriptionTagCostTable",

    CosmosTable = 'cosmosTable',
    StorageTable = 'storageTable',
    ProcessingTable = 'processingTable',

    ServiceDetails = "serviceDetails",
    RegionDetails = "regionDetails",
    SubscriptionDetails = "subscriptionDetails",
    BudgetDetails = "budgetDetails",

    NetworkByService = "ServiceNetwork",
    NetworkByScenarioTag = "ScenarioTagNetwork",

    TableScenarioTagsTrans = "TableScenarioTagsTrans",
    TableAppsTrans = "TableAppsTrans",
    TableScenarioTagsNetwork = "TableScenarioTagsNetwork",
    TableAppsNetwork = "TableAppsNetwork",

    ServiceTrans = "ServiceTrans",
    ScenarioTagTrans = "ScenarioTagTrans",

    HddStorageByDataset = "hddStorageByDataset",
    HddStorageByMailboxCategory = "hddStorageByMailboxCategory",

    TotalStorageSSD = "TotalStorageSSD",
    StorageDatasetSSD = "StorageDatasetSSD",
    StorageByMailboxCategorySSD = "StorageByMailboxCategorySSD",
    SubstrateTopCostProportion = "SubstrateTopCostProportion",

    Post = "post",
    Get = "get",
    Delete = "delete",
    PostResponse = "postResponse",
    GetResponse = "getResponse",
    SubstrateCostProportion = "SubstrateCostProportion",
    CogsMetricCost = "CogsMetricCost",
    DeploymentRings = "DeploymentRings",
    Top5Services = "Top5Services",
    Top10 = "Top10",

    SubstrateV2 = "SubstrateV2",
    TransactionV2 = "TransactionV2",
    ProcessHostingV2 = "ProcessHostingV2",
    NetworkV2 = "NetworkV2",
    HddV2 = "HddV2",
    SsdV2 = "SsdV2",

    AllRings = "AllRings",
    SendFeedback = "SendFeedback",
    SortSubstrate = "SortSubstrate",
    SortNetwork = "SortNetwork",
    SortProcessHosting = "SortProcessHosting",
    SortHDD = "SortHDD",
    SubstrateWiki = "SubstrateWiki",
    CarbonVideo = "CarbonVideo",


    NavigateToSubstrate = "NavigateToSubstrate",
    NavigateToSubstratePlatform = "NavigateToSubstratePlatform",
    NavigateToAzure = "NavigateToAzure",
    NavigateToCosmic = "NavigateToCosmic",
    NavigateToCosmos = "NavigateToCosmos",
    NavigateToTelemetry = "NavigateToTelemetry",
    HomePage = "HomePage",

    ViewInsight = "ViewInsight",
    InsightSummary = "InsightSummary",
    InsightDetails = "InsightDetails",
    AnomalyTrend = "AnomalyTrend",
    AnomalyByDimTrend = "AnomalyByDimTrend",
    LikedInsight = "LikedInsight",
    DisLikedInsight = "DisLikedInsight",

    DownloadBackendTable = "DownloadBackendTable",
    SubstratePlatform = "SubstratePlatform",
    CogsCalculator = "CogsCalculator",

    //Efficiency Tracker
    EfficiencyTracker = "efficiencyTracker",
    EffiencyTrackerTable = "EffiencyTrackerTable",
    EffiencyTrackerChart = "EffiencyTrackerChart",
    EfficiencyTrackerBigBets = "EfficiencyTrackerBigBets",

    TelemetryTrendsChart = 'TelemetryTrendsChart',
    TelemetryTable = 'TelemetryTable',
    TelemetryCosmosTable = 'TelemetryCosmosTable',
    BlueshiftTable = 'BlueshiftTable',
    KustoTable = 'KustoTable',
    GenevaMdsTable = 'GenevaMdsTable',
    GenevaMdmTable = 'GenevaMdmTable',
    AriaTable = 'AriaTable',
    TelemetryNavLink = 'cosmosNavLink',
    TelemetryCosmosNavLink = 'telemetryCosmosNavLink',
    BlueshiftNavLink = 'blueshiftNavLink',
    KustoNavLink = 'kustoNavLink',
    GenevaMdsNavLink = 'genevaMdsNavLink',
    GenevaMdmNavLink = 'genevaMdmNavLink',
    AriaNavLink = 'ariaNavLink',
    PassiveMonitoringNavLink = 'passiveMonitoringNavLink',

    InsightPortalAnomaly = 'InsightPortalAnomaly',
    InsightPortalRecommendation = 'InsightPortalRecommendation',
    InsightPortalRealizedSaings = 'InsightPortalRealizedSaings',

    InsightOverview = 'insightOverview',
    InsightWiki = 'insightWiki',
    InsightAnomalyTab = 'insightAnomalyTab',
    InsightRecommendationTab = 'insightRecommendationTab',
    InsightRealizedTab = 'insightRealizedTab',
    InsightExpandSidePanel = 'insightExpandSidePanel',
    InsightSortInsights = 'insightSortInsights',
    InsightTSG = 'insightTSG',
    InsightTrendCard = 'insightTrendCard',
    InsightBreakdownPivot = 'insightBreakdownPivot',
}

export const enum LogTarget {
    Button = "Button",
    Tab = "Tab",
    Link = "Link",
    Nav = "Nav",
    Toggle = "Toggle",
    Other = "Other",
    Chart = 'Chart',
    Title = "Title",
}

export const enum EventType {
    Hovering = 'Hovering',
    Click = 'Click',
}
