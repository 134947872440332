import styles from "./BudgetLayout.less";
import { Stack } from "@fluentui/react";
import * as React from "react";
import BudgetBanner from "./BudgetBanner";
import BudgetNavigation from "./BudgetNavigation";

interface IBudgetLayoutProps {
    noPadding?: boolean;
}

export const BudgetLayout: React.FC<IBudgetLayoutProps> = (props) => {
    return (
        <Stack grow className={styles.page}>
            <BudgetBanner />
            <Stack grow horizontal className={styles.stack}>
                <Stack.Item className={styles.navigation}>
                    <BudgetNavigation />
                </Stack.Item>
                <Stack.Item className={props.noPadding ? styles.pageContainerNoPadding : styles.pageContainer}>
                    {props.children}
                </Stack.Item>
            </Stack>
        </Stack>
    );
}