export const AnomalyPivotTableColumnNames: (keyof IInsightUsageBO)[] = [ "resourceUri", "serviceName", "insightStatus", "platformType", "insightName",  "resourceType", "resourceUsage", "costSaving" ];

export enum PlatformTypeEnum {
    Azure = "Azure",
    Substrate = "Substrate",
    Cosmic = "Cosmic",
}

export enum InsightTypeEnum {
    Anomaly = "Anomaly",
    Recommendation = "Recommendation",
    Realized = "Realized",
}

export interface IAnomalyData {
    totalSaving: number;
    usageSummary: Record<PlatformTypeEnum, number>;
    detailList: IInsightUsageBO[];
}

// Insight Usage BO
export interface IInsightUsageBO {
    insightId: number;
    resourceUri: string;
    serviceName: string;
    insightStatus: string;
    insightName: string;
    platformType: string;
    resourceType: string;
    resourceUsage: number;
    costSaving: number;
}

export interface IInsightBaseInfo {
    insightId: string;
    insightType: string;
    insightName: string;
    impactedResource: string;
    baseInfo: Record<string, string>;
    resourceId: string;
    insightStartDate: string;
    status: string;
    serviceId: string;
    efficiencyTrackerId: string;
    ackTime: string;
}

export interface IInsightUsage {
    insightStartTime: string;
    usage: IInsightTrend[];
}

export interface IInsightTrend {
    timeStamp: string;
    currentUsage: number;
    currentCost: number;
    baselineUsage: number;
    baselineCost: number;
}

export const enum BreakdownTypeEnum {
    Resources = "Resources",
    Cost = "Cost",
}

export interface ITSG {
    recommendationText: string;
    recommendationURL: string;
}


