import * as React from "react";

import { CategoryDivision, CategoryDivisionDisplayName } from "../../models/CategoryDivision";
import { CellContext, ColumnDef, ColumnDefBase, ExpandedState, RowData, SortingState, flexRender, getCoreRowModel, getExpandedRowModel, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import { FiltersAction, FiltersView } from "../../reducers/filterReducer";
import { DefaultButton, FontIcon, Icon, IconButton, PrimaryButton, SpinButton, Spinner, Stack, TextField } from "@fluentui/react";
import { Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from "@mui/material";
import { clearEmptyBudgets, getBudgetFiscalYear, getLastFiscalYear, suppleBudgets } from "../../utils/BudgetUtils";
import moment, { now } from "moment";
import { useGetCostAndBudget, useGetLatestBudgetVersion, useGetServiceLevelBudget, useGetSubmitOverview, useInitializeNewFinOpsBudgets } from "../../hooks/useFinOpsBudget";

import { BudgetLayout } from "./BudgetLayout";
import { IAppState } from "../../store";
import { IFinOpsBudget } from "../../models/FinOpsBudgets";
import { IServiceTreeData } from "../../reducers/serviceTreeReducer";
import { ServiceTreeLevel } from "../../models/serviceTree";
import WorkloadFilter from "../common/FiltersBanner/WorkloadFilter/WorkloadFilter";
import { flatten, sum, toNumber } from "lodash";
import { useCategoryFilters } from "../../hooks/useFilters";
import { useSelector } from "react-redux";
import { useQueryClient } from "react-query";
import { Endpoints } from "../../utils/Constants";
import { currencyFormatter } from "../../utils/currency";
import styles from "./Budget.less";

declare module '@tanstack/react-table' {
    interface TableMeta<TData extends RowData> {
      updateData: (rowIndex: number, columnId: string, value: unknown) => void
    }

    interface ColorKeyColumnBase<TData extends RowData, TValue = unknown> extends ColumnDefBase<TData, TValue> {
        headerStyles: React.CSSProperties;
    }
}
  
// Give our default column cell renderer editing superpowers!
const numberCellFormatter = (cell: CellContext<IFinOpsBudget, number | string | undefined>) => {
    if (cell.row.depth === 0) return currencyFormatter(toNumber(cell.getValue()));
    const updateTable = (rowId: number, cloumnId: string, value: number) => {
        let newIncreaseRate = 0;
        let newQuantityBudget = 0;
        let newCostBudget = 0;
        if (cloumnId === 'increaseRate') {
            newIncreaseRate = value;
            newQuantityBudget = cell.row.original.lastYearQuantity * (100 + value) / 100;
            newCostBudget = cell.row.original.lastYearCost * (100 + value) / 100;
        } else if (cloumnId === 'quantityBudget') {
            newIncreaseRate = cell.row.original.lastYearQuantity ? (value - cell.row.original.lastYearQuantity) / cell.row.original.lastYearQuantity * 100 : 0;
            newQuantityBudget = value;
            newCostBudget = newIncreaseRate * cell.row.original.lastYearCost / cell.row.original.lastYearQuantity / 100;
        } else if (cloumnId === 'costBudget') {
            newIncreaseRate = cell.row.original.lastYearCost ? (value - cell.row.original.lastYearCost) / cell.row.original.lastYearCost * 100 : 0;
            newQuantityBudget = cell.row.original.lastYearQuantity * (100 + newIncreaseRate) / 100;
            newCostBudget = value;
        } else {
            return;
        }
        cell.table.options.meta?.updateData(rowId, 'increaseRate', parseFloat(newIncreaseRate.toFixed(2)));
        cell.table.options.meta?.updateData(rowId, 'quantityBudget', Math.round(newQuantityBudget));
        cell.table.options.meta?.updateData(rowId, 'costBudget', Math.round(newCostBudget));

        // Update parent row, adjust service level budget data.
        const parentRow = cell.row.getParentRow();
        if (parentRow) {
            const newBudget = sum(parentRow.subRows.map(row => toNumber(row.getValue('costBudget'))));
            cell.table.options.meta?.updateData(parentRow.index, 'costBudget', newBudget);
            cell.table.options.meta?.updateData(parentRow.index, 'quantityBudget', sum(parentRow.subRows.map(row => toNumber(row.getValue('quantityBudget')))));
            cell.table.options.meta?.updateData(parentRow.index, 'increaseRate', (toNumber(newBudget) / (toNumber(parentRow.getValue('spend')) || 1) * 100)?.toFixed(1));
        }
    }
    const initialValue = cell.getValue();
    // We need to keep and update the state of the cell normally
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [value, setValue] = React.useState<string | number |undefined>(Math.round(toNumber(initialValue || 0)));
    // If the initialValue is changed external, sync it up with our state
    // eslint-disable-next-line react-hooks/rules-of-hooks
    React.useEffect(() => {
        setValue(initialValue)
    }, [initialValue]);

    return (
        <SpinButton
        styles={{spinButtonWrapper: cell.column.id === 'costBudget' ? styles.costSpinButton : undefined}}
        value = {(value === undefined ? 0 : value).toString()}
        step = {cell.column.id === 'increaseRate' ? 0.1 : 100}
        onChange={(_e, value) => value && updateTable(cell.row.index, cell.column.id, toNumber(value))}
        />
    );
};
const textCellFormatter = (cell: CellContext<IFinOpsBudget, number | string | undefined>) => {
    if (cell.row.depth === 0) return cell.getValue();
    const updateTable = (rowId: number, columnId: string, value: string) => {
        cell.table.options.meta?.updateData(rowId, columnId, value);
    }
    const initialValue = cell.getValue();
    // We need to keep and update the state of the cell normally
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [value, setValue] = React.useState(initialValue);
    // If the initialValue is changed external, sync it up with our state
    // eslint-disable-next-line react-hooks/rules-of-hooks
    React.useEffect(() => {
        setValue(initialValue)
    }, [initialValue]);

    return (
        <TextField
            multiline
            value={(value || '').toString()}
            onChange={(_e, value) => value && updateTable(cell.row.index, cell.column.id, value)}
        />
    );
};

const CURRENT_BUDGET_FISCAL_YEAR = getBudgetFiscalYear();

export const Budget: React.FC = () => {
    const [editing, setEditing] = React.useState(false);

    const selectedOwners = useCategoryFilters().filters.filters.Owner;
    const currentOwner = selectedOwners && selectedOwners.length > 0 ? selectedOwners[0] : undefined;
    const {data: initialData, isLoading} = useGetServiceLevelBudget({
      fiscalYear: CURRENT_BUDGET_FISCAL_YEAR,
      serviceGM: currentOwner
    });
    
    const columns = React.useMemo<ColumnDef<IFinOpsBudget, number | string>[]>(
        () => {
            let baseColumns: ColumnDef<IFinOpsBudget, number | string | undefined>[] = [
                {
                    id: 'service',
                    header: 'Service',
                    accessorFn: row => serviceTree.indexMap.get(row.serviceId)?.n || row.serviceId || '',
                    footer: 'Total',
                    columns: [
                        {
                            id: 'category',
                            header: 'Category',
                            accessorKey: 'category',
                            cell: ({row, getValue}) => (
                                <>
                                {getValue()}
                                </>
                            ),
                        },
                        {
                            id: 'type',
                            header: 'Type',
                            accessorKey: 'type',
                        },
                    ]
                },
                {
                    id: 'spend',
                    header: `${getLastFiscalYear(CURRENT_BUDGET_FISCAL_YEAR)} Spend`,
                    accessorKey: 'lastYearCost',
                    cell: cell => currencyFormatter(toNumber(cell.getValue())),
                    footer: ({table}) => {
                        return currencyFormatter(sum(table.getFilteredRowModel().rows.map(row => row.original.lastYearCost)));
                    }
                },
                {
                    id: 'quantity',
                    header: `${getLastFiscalYear(CURRENT_BUDGET_FISCAL_YEAR)} Quantity`,
                    accessorKey: 'lastYearQuantity',
                    cell: cell => Math.round(toNumber(cell.getValue())).toLocaleString(),
                },
                {
                    id: 'unit',
                    header: 'Unit',
                    accessorKey: 'unit',
                },
                {
                    id: 'blendedRate',
                    header: 'Blended Rate',
                    accessorFn: row => {
                        let n = row.lastYearCost / (row.lastYearQuantity || 1);
                        const numberBeforeDecimal = Math.floor(n);
                        n -= numberBeforeDecimal;
                        if (n === 0) {
                            return numberBeforeDecimal;
                        }
                        return parseFloat(numberBeforeDecimal + n.toFixed(1-Math.floor(Math.log(n)/Math.log(10))));
                    },
                }];

            // Budget related info.
            baseColumns = baseColumns.concat([{
                id: 'increaseRate',
                header: `${CURRENT_BUDGET_FISCAL_YEAR} Growth Rate %`,
                accessorKey: 'increaseRate',
                cell: !editing ? cell => cell.getValue() : numberCellFormatter,
            },
            {
                id: 'costBudget',
                header: `${CURRENT_BUDGET_FISCAL_YEAR} Cost`,
                accessorKey: 'costBudget',
                cell: !editing ? cell => currencyFormatter(Math.round(toNumber(cell.getValue()))) : numberCellFormatter,
                footer: ({table}) => {
                    return currencyFormatter(sum(table.getFilteredRowModel().rows.map(row => toNumber(row.getValue('costBudget')))));
                }
            },
            {
                id: 'quantityBudget',
                header: `${CURRENT_BUDGET_FISCAL_YEAR} Quantity`,
                accessorKey: 'quantityBudget',
                cell: !editing ? cell => Math.round(toNumber(cell.getValue())).toLocaleString() : numberCellFormatter,
            },
            {
                id: 'justification',
                header: 'Justification',
                accessorKey: 'justification',
                cell: !editing ? cell => cell.getValue() : textCellFormatter,
            }]);

            // Only show review info when not editing
            if (!editing) {
                baseColumns = baseColumns.concat(
                    [{
                        id: 'submitter',
                        header: 'Submitter',
                        accessorKey: 'submitter',
                    },
                    {
                        id: 'version',
                        header: 'Version',
                        accessorFn: row => row.version?.format('YYYY-MM-DD') || '',
                    },
                    {
                        id: 'approver',
                        header: 'Approver',
                        accessorKey: 'approver',
                    },
                    {
                        id: 'approved',
                        header: 'Status',
                        accessorKey: 'approved',
                        cell: 
                            cell => cell.getValue() == 2 ? 'Yes':
                                cell.getValue() == 1 ? 'No' :
                                cell.getValue() === 0 ? 'Not reviewed' : '',
                    },
                    {
                        id: 'approvedTime',
                        header: 'Approved Time',
                        accessorFn: row => row.approvedTime?.format('YYYY-MM-DD') || '',
                    },
                    {
                        id: 'comment',
                        header: 'Comment',
                        accessorKey: 'comment',
                        cell: cell => cell.getValue(),
                    },
                ]);
            }

            return baseColumns;
        },
        [editing]
      );
      const [data, setData] = React.useState(initialData || []);
      React.useEffect(() => {
        setData(initialData || []);
      }, [initialData]);
      React.useEffect(() => {
        if (editing) {
            setData(data.map(serviceLevelBudget => ({
                ...serviceLevelBudget,
                children: suppleBudgets(CURRENT_BUDGET_FISCAL_YEAR, serviceLevelBudget.serviceId, serviceLevelBudget.children || [])
            })));
        } else {
            setData(data.map(serviceLevelBudget => ({
                ...serviceLevelBudget,
                children: clearEmptyBudgets(serviceLevelBudget.children)
            })));
        }
      }, [editing]);
      const [sorting, setSorting] = React.useState<SortingState>([]);
      const [expanded, setExpanded] = React.useState<ExpandedState>({});
    
      const table = useReactTable({
        data,
        columns,
        state: {
            sorting,
            expanded
        },
        getRowId: row => `${row.serviceId}-${row.category}-${row.type}-${row.unit}`,
        onSortingChange: setSorting,
        onExpandedChange: setExpanded,
        getSubRows: row => row.children,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
        // Provide our updateData function to our table meta
        meta: {
          updateData: (rowIndex, columnId, value) => {
            setData(old =>
              old.map((row, index) => {
                if (index === rowIndex) {
                  return {
                    ...old[rowIndex]!,
                    [columnId]: value,
                  }
                }
                return row
              })
            )
          },
        },
      });

      const ownerFilter = CategoryDivision.Owner;
      const serviceTree = useSelector<IAppState, IServiceTreeData>((state) => state.serviceTree);
      const onwerList = Array.from(serviceTree.owners.keys());

      const queryClient = useQueryClient();
      const mutation = useInitializeNewFinOpsBudgets(
        () => {
            queryClient.invalidateQueries([Endpoints.GetCostAndBudget]);
            alert('Submitted successfully');
            setEditing(false);
        },
        () => {
            alert('Failed to submit');
        }
      );

      return (
        <BudgetLayout>
            <h1>Submit Ask</h1>
            <Stack horizontal tokens={{childrenGap: 20}}>
            <WorkloadFilter
                key={ownerFilter}
                category={ownerFilter}
                optionList={onwerList}
                displayName={CategoryDivisionDisplayName[ownerFilter]}
                isSingleSelect
            />
            {
                !editing ?
                <PrimaryButton
                    text="Create budget"
                    onClick={() => setEditing(true)} /> :
                (
                    mutation.isLoading ?
                    <Spinner label='Saving ...' labelPosition="right" /> :
                    <>
                    <PrimaryButton
                        text="Save Budget"
                        disabled={mutation.isLoading}
                        onClick={() => mutation.mutate(
                            flatten(data.map(serviceData => clearEmptyBudgets(serviceData.children) || [])))} />
                    <DefaultButton
                        disabled={mutation.isLoading}
                        onClick={() => setEditing(false)}
                        text="Discard Changes" />
                    </>
                )
            }
            </Stack>
            <Table stickyHeader>
                <TableHead>
                    {
                        table.getHeaderGroups().map(headerGroup => (
                            <TableRow key={headerGroup.id}>
                                {
                                    headerGroup.headers.map(header => (
                                        headerGroup.depth > 0 && !['category', 'type'].includes(header.column.id) ?
                                        undefined :
                                        <TableCell key={header.id} colSpan={header.colSpan} align="center"
                                            rowSpan={headerGroup.depth === 0 && header.column.id !== 'service' ? 2 : 1}
                                            className={header.column.id == 'category' ? styles.categoryHeader :
                                                header.column.id == 'type' ? styles.typeHeader :
                                                header.column.id == 'service' ? styles.serviceHeader : styles.tableHeader
                                            }
                                            onClick={header.column.getToggleSortingHandler()}>
                                            {(
                                                flexRender(header.column.columnDef.header, header.getContext())
                                            )}
                                            {
                                                {
                                                    asc: <FontIcon iconName='SortUp'/>,
                                                    desc: <FontIcon iconName='SortDown'/>
                                                }[header.column.getIsSorted() as string] ?? null
                                            }
                                        </TableCell>
                                    ))
                                }
                            </TableRow>
                        ))
                    }
                </TableHead>
                <TableBody>
                    {table.getRowModel().rows.map(row => {
                    return (
                        <TableRow key={row.id} className={styles.tableRow}>
                        {row.getVisibleCells().map(cell => {
                            if (row.depth === 0 && cell.column.id === 'type') {
                                return undefined;
                            }
                            const isServiceCell = (row.depth === 0 && cell.column.id === 'category');
                            return (
                            <TableCell key={cell.id} align={getColumnAlignment(cell.column.id)}
                                colSpan={isServiceCell ? 2 : 1}
                                className={(cell.column.id == 'category') ?
                                    isServiceCell ? styles.serviceColumn :
                                    styles.categoryColumn : cell.column.id == 'type' ? styles.typeColumn : ''
                                }>
                                {
                                    isServiceCell ?
                                    <Stack horizontal verticalAlign="center" tokens={{childrenGap: 10}}>
                                    <IconButton
                                        iconProps={{ iconName: row.getIsExpanded() ? 'ChevronDown' : 'ChevronRight' }}
                                        onClick={row.getToggleExpandedHandler()} />
                                    {serviceTree.indexMap.get(row.original.serviceId)?.n ?? ""}
                                    </Stack> :
                                    flexRender(cell.column.columnDef.cell,cell.getContext())
                                }
                            </TableCell>
                            )
                        })}
                        </TableRow>
                    )
                    })}
                </TableBody>
                <TableFooter>
                    {
                        table.getFooterGroups().slice(1).map(footerGroup => (
                            <TableRow key={footerGroup.id} className={styles.tableFooter}>
                                {
                                    footerGroup.headers.map(header => (
                                        <TableCell align={getColumnAlignment(header.column.id)} key={header.id} colSpan={header.colSpan}
                                            className={header.column.id == 'service' ? styles.serviceFooter : ''
                                            }>
                                            {(
                                                flexRender(header.column.columnDef.footer, header.getContext())
                                            )}
                                        </TableCell>
                                    ))
                                }
                            </TableRow>
                        ))
                    }
                </TableFooter>
            </Table>
        </BudgetLayout>
      )
}

function getColumnAlignment(columnId: string) {
    switch (columnId) {
        case 'costBudget':
        case 'quantityBudget':
        case 'spend':
        case 'quantity':
        case 'increaseRate':
        case 'blendedRate':
            return 'right';
        default:
            return 'left';
    }
}