import * as React from "react";
import { BudgetLayout } from "./BudgetLayout";
import { useCategoryFilters } from "../../hooks/useFilters";

export function FinOpsReport() {
    const services = useCategoryFilters().filters.filters.Service;
    const owners = useCategoryFilters().filters.filters.Owner;
    const filtersStr = React.useMemo(() => {
        const res: string[] = [];
        if (services && services.length > 0) {
            res.push(`ServiceInfo/ServiceId in ('${services.join(`','`)}')`);
        }
        if (owners && owners.length > 0) {
            res.push(
                `ServiceOwners/L4 in ('${owners.join(`','`)}')`
            );
        }

        if (res.length === 0) {
            return '';
        } else {
            return `&filter=${res.join(' and ')}`;
        }
    }, [services, owners]);

    return (
        <BudgetLayout noPadding>
            <iframe
                width="100%" height="100%"
                title="FinOpsReport" frameBorder="0" allowFullScreen
                src={"https://msit.powerbi.com/reportEmbed?reportId=877f1386-49b8-48bc-a860-4221baaf8293&autoAuth=true&ctid=72f988bf-86f1-41af-91ab-2d7cd011db47" + filtersStr}></iframe>
        </BudgetLayout>
    );
}