
import { IIconProps, Panel, PanelType, PrimaryButton } from "@fluentui/react";
import React from "react";
import { Stack } from "@fluentui/react";
import { useState } from "react";
import styles from "./InsightSidePanel.less";
import { IInsightBaseInfo } from "../../../models/InsightsPlatformModels";
import { SectionHeader } from "../../common/SectionHeader";
import substrateStyles from "../substrateV2/Substrate.less";
import { IAnomalyDetailsTrend } from "./AnomalyDetailsTrend";
import { IRCATable } from "./RCATable";
import { useGetInsightBaseInfo, useGetInsightRCA, useGetInsightReCommendation, useGetInsightUsageTrend } from "../../../hooks/useInsightPlatformQuery";
import { EmpytCostView } from "../../substrateV2/common/EmptyCostView";
import { useSelector } from "react-redux";
import { IAppState } from "../../../store";
import { ServiceTreeItem } from "../../../models/serviceTree";
import { parseScenarioTag } from "../../../utils/PcmV2Utils";
import { useGetAppIdNameMap } from "../../../hooks/useSearchMetadataQuery";
import LoadingState from "../../ResponseBoundary/LoadingState";
import moment from "moment";

const closeIconProps: IIconProps = { iconName: "Cancel" };

interface IInsightSidePanelProps {
    isPanelOpen: boolean;
    onDismiss: () => void;
    insightId: string;
}

const panelWidthSetting: Record<string, string> = {
    ['Init']: '550px',
    ['OpenEdit']: '1000px',
};

export function getWorkLoadNames(id: string, category: string, serviceIdMap: Map<string, ServiceTreeItem>, appIdNameMap: Map<string, string>) {
    if (category == "Service") {
        return serviceIdMap.get(id)?.n || id;
    } else if (category == "GriffinApplication") {
        return appIdNameMap.get(id);
    } else if (category == "GriffinProcessor" || category == "ScenarioTag") {
        const [appId, workloadName] = parseScenarioTag(id);
        const appName = appIdNameMap.get(appId);
        return `${workloadName}${appName ? `(${appName})` : ""}`;
    } else {
        return id;
    }
}

const InsightSidePanel: React.FC<IInsightSidePanelProps> = (props) => {

    const { onDismiss } = props;

    const [panelWidth, setpanelWidth] = useState<string>(panelWidthSetting.Init);

    const handleClose = () => {
        setpanelWidth(panelWidthSetting.Init);
        setIsClosed(true);
        onDismiss();
    }

    const handleCancel = () => {
        handleClose();
        setIsClosed(true);
    };

    const serviceIdMap = useSelector<IAppState, Map<string, ServiceTreeItem>>(state => state.serviceTree.indexMap);
    const appIdNameMap = useGetAppIdNameMap();

    const onRenderFooterContent = (): JSX.Element => (
        <div>
            <PrimaryButton className={styles.panelButton} onClick={handleCancel}>Close</PrimaryButton>
        </div>
    )

    const [isClosed, setIsClosed] = useState(true);

    // Base Info.
    const { data: insightBaseInfo, isLoading: baseInfoIsLoading } = useGetInsightBaseInfo(props.insightId);

    // Usage Trend.
    const { data: insgithUsageTrend, isLoading: usageTrendIsLoading } = useGetInsightUsageTrend(props.insightId);

    // Recommendation.
    const { data: insightRecommendation, isLoading: recommendationIsLoading } = useGetInsightReCommendation(props.insightId);

    // RCA.
    const { data: insightRCA, isLoading: rcaIsLoading } = useGetInsightRCA(props.insightId);

    return (
        <Panel
            type={PanelType.custom}
            className={styles.container}
            customWidth={panelWidth}
            headerText="Insight Details"
            headerClassName={styles.title}
            isOpen={props.isPanelOpen}
            onDismiss={handleClose}
            isBlocking={false}
            closeButtonAriaLabel="Close"
            onRenderFooterContent={onRenderFooterContent}
            isFooterAtBottom={true}
            isLightDismiss
            layerProps={{eventBubblingEnabled: true}}
        >
            <div className={styles.panelContent} >
                <div className={styles.divBlank}>
                    { baseInfoIsLoading ? (<LoadingState />): (insightBaseInfo == undefined  ? (
                        <EmpytCostView />
                    ) : (
                            <div style={{marginTop:20, marginBottom:10}}>
                                <table>
                                    <thead>
                                        <tr>
                                            <td style={{width:200}}><b style={{fontWeight:600}}>Status</b></td>
                                            <td style={{width:200}}><b style={{fontSize:14, fontWeight:600}}>Anomaly Start Date</b></td>
                                        </tr>
                                        <tr>
                                            <td style={{width:200}}>{insightBaseInfo.status}</td>
                                            <td style={{width:200}}><span style={{fontSize:14}}>{moment(insightBaseInfo.insightStartDate).format("YYYY-MM-DD")}</span></td>
                                        </tr>
                                    </thead>
                                </table>
                                <table style={{paddingTop: 10, paddingBottom: 10}}>
                                    <thead>
                                        <tr>
                                            <td style={{width:200}}><b style={{fontWeight:600}}>Service Name</b></td>
                                            <td style={{width:200}}><b style={{fontSize:14, fontWeight:600}}>Impacted Resource</b></td>
                                        </tr>
                                        <tr>
                                            <td style={{width:200}}><span style={{fontSize:13}}>{serviceIdMap.get(insightBaseInfo.serviceId)?.n || insightBaseInfo.serviceId}</span></td>
                                            <td style={{width:200}}><span style={{fontSize:14}}>{insightBaseInfo.impactedResource}</span></td>
                                        </tr>
                                    </thead>
                                </table>
                                <table style={{paddingTop: 10, paddingBottom: 20}}>
                                    <thead>
                                        <tr>
                                            <td><b style={{fontWeight:600}}> WorkloadName</b></td>
                                        </tr>
                                        <tr>
                                            <td><span style={{fontSize:13, wordBreak:"break-all"}}>{getWorkLoadNames(insightBaseInfo.baseInfo[(Object.keys(insightBaseInfo.baseInfo)[0] || 'unknown')] || 'unknown', Object.keys(insightBaseInfo.baseInfo)[0] || 'unknown', serviceIdMap, appIdNameMap)}</span></td>
                                        </tr>
                                    </thead>
                                </table>
                            </div> 
                        ))}


                    { usageTrendIsLoading ? (<LoadingState />) : (insgithUsageTrend == undefined || insgithUsageTrend.usage.length == 0 ? (
                        <></>
                    ) : (
                        <IAnomalyDetailsTrend insightUsage={insgithUsageTrend}/>
                    ))}

                    { rcaIsLoading ? (<LoadingState />): (insightRCA == undefined || insightRCA.length == 0 ? (
                        <></>
                    ) : (
                        <IRCATable insightRCA={insightRCA}/>
                    ))}

                    <SectionHeader title="Recommendation"/>
                    { recommendationIsLoading ? (<LoadingState />): (insightRecommendation == undefined ? (
                        <></>
                    ) : (
                        <div style={{wordBreak:"break-all"}}> 
                            <p>{insightRecommendation.recommendationText}</p>
                            <div><a href={insightRecommendation.recommendationURL} target="_blank" rel="noopener noreferrer">{insightRecommendation.recommendationURL}</a></div>
                        </div>
                    ))}
                    
                </div>                      
            </div>

            <div className={styles.panelContent}>
                <div className={styles.divBlank}></div>
            </div>
        </Panel>
    );
};

export default InsightSidePanel;