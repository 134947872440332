import React from 'react';
import { getCellOption, getFooterOption, getHeaderOption } from './AnomalyPivotTableUtils';
import StickyTable from '../../common/table/StickyTable';
import commonStyles from './AnomalyPivotTable.less';
import { AnomalyPivotTableColumnNames, IInsightUsageBO } from '../../../models/InsightsPlatformModels';
import { useInsightAnomalyPivotTable } from './AnomalyPivotTableUtils';

interface IAnomalyPivotTableProps {
    defaultSorting?: string;
    totalCost : number;
    data:  IInsightUsageBO[];
    showInsightCard: (id: string) => void;
}

export const AnomalyPivotTableStyles = {
    container: commonStyles.tableContainer,
    bodyContainer: commonStyles.tableSectionContainer,
    headerContainer: commonStyles.tableSectionContainer,
    footerContainer: commonStyles.tableSectionContainer,
    stickyFooterContainer: commonStyles.stickyFooterContainer,
};

export const AnomalyPivotTableOffset = { header: { offsetTop: 0 }, footer: { offsetBottom: 0 } };

const AnomalyPivotTable: React.FC<IAnomalyPivotTableProps> = (props) => {
    const columns = AnomalyPivotTableColumnNames;

    const tableInstance = useInsightAnomalyPivotTable(
        columns,
        props.data,
        props.totalCost,
        props.showInsightCard
    );

    return (
        <div className={commonStyles.detailTableView} style={{ margin: '44px 0 0' }}>
            <StickyTable
                styles={AnomalyPivotTableStyles}
                cellOption={getCellOption}
                headerOption={getHeaderOption}
                footerOption={getFooterOption}
                loadMore={false}
                emptyText="The selected services don't have data"
                table={tableInstance}
                stickyTarget="#pageContainer"
                stickyPositon={AnomalyPivotTableOffset}
            />
        </div>
    );
};

AnomalyPivotTable.defaultProps = {
    defaultSorting: "CostIncrease",
}

export default AnomalyPivotTable;
