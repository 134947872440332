import { useSelector } from "react-redux";
import { parseFiltersToJson } from "../models/FilterView";
import { InsightPlatformService } from "../services/InsightPlatformService";
import { useCustomQuery } from "./useCustomQuery";
import { useDateRange } from "./useDateSelector";
import { useCategoryFilters } from "./useFilters";
import { IAppState } from "../store";
import { ServiceTreeItem } from "../models/serviceTree";
import moment from "moment";

export function useGetInsightsPortalData() {
    const { startDate, endDate } = useDateRange();
    const { filters } = useCategoryFilters();
    const serviceIdMap = useSelector<IAppState, Map<string, ServiceTreeItem>>((state) => state.serviceTree.indexMap);
    return useCustomQuery(
        InsightPlatformService.getInsightsPortalData,
        parseFiltersToJson(filters.filters, serviceIdMap),
        // Hard code start date to display the whole list of insights
        moment(new Date('2024-01-01')),
        endDate,
    );
}

export function useGetInsightBaseInfo(insightId: string) {
    return useCustomQuery(
        InsightPlatformService.getBaseInfoByInsightId,
        insightId,
    );
}

export function useGetInsightReCommendation(insightId: string) {
    return useCustomQuery(
        InsightPlatformService.getRecommendationByInsightId,
        insightId,
    );
}

export function useGetInsightRCA(insightId: string) {
    return useCustomQuery(
        InsightPlatformService.getRCAByInsightId,
        insightId,
    );
}

export function useGetInsightUsageTrend(insightId: string) {
    return useCustomQuery(
        InsightPlatformService.getUsageTrendByInsightId,
        insightId,
    );
}

export function useGetInsightsOverviewData() {
    const { startDate, endDate } = useDateRange();
    const { filters } = useCategoryFilters();
    const serviceIdMap = useSelector<IAppState, Map<string, ServiceTreeItem>>((state) => state.serviceTree.indexMap);
    return useCustomQuery(
        InsightPlatformService.getInsightsOverviewData,
        parseFiltersToJson(filters.filters, serviceIdMap),
        // Hard code start date to display the whole list of insights
        moment(new Date('2024-01-01')),
        endDate,
    );
}