import React, { useState } from "react";
import { useBoolean } from "@fluentui/react-hooks";
import styles from "./BudgetBanner.less";
import { Stack, Icon, IStackTokens, IStackStyles, IIconProps, IconButton } from "@fluentui/react";
import { useMsal } from "@azure/msal-react";
import html2canvas from "html2canvas";
import { useFlights } from "../../hooks/useSettings";
import { trackEventCallback } from "../../utils/AppInsights";
import { EventTypeEnum, OpenFeedbackPanelEvent } from "../../models/Event";
import useEventBus from "../../hooks/useEventBus";
import FeedbackPanel from "../feedback/FeedbackPanel";
import { EventType, LogComponent, LogElement, LogTarget } from "../../models/LogModel";

const innerStackTokens: IStackTokens = { childrenGap: 5 };

const innerStackStyles: IStackStyles = {
    root: {
        alignItems: "stretch",
        height: "100%",
    }
}

const feedbackIconProps: IIconProps = { iconName: "Feedback" };


const TrackerBanner: React.FC = () => {
    const { instance } = useMsal();
    const account = instance.getActiveAccount();

    //User Feedback
    const { data: flights } = useFlights();
    const [isFeedbackPanelOpen, { setTrue: openFeedbackPanel, setFalse: dismissFeedbackPanel }] = useBoolean(false);
    const [currentURL, setCurrentURL] = useState<string>('');
    const [currentScreenshotURL, setCurrentScreenshotURL] = useState<string>('');

    const captureScreenshot = async () => {
        trackEventCallback(LogComponent.PivotHeadPane, LogElement.SendFeedback, "Send Feedback", LogTarget.Button, undefined, EventType.Click);

        const url = window.location.href;
        setCurrentURL(url);

        await openFeedbackPanel();

        const targetElement = document.documentElement;
        setTimeout(() => {
            html2canvas(targetElement).then((canvas) => {
                const screenshotDataUrl = canvas.toDataURL('image/png');
                setCurrentScreenshotURL(screenshotDataUrl);
            });
        }, 100);
    };

    const handleDismissFeedbackPanel = () => {
        setCurrentScreenshotURL('');
        dismissFeedbackPanel();
    };

    useEventBus<OpenFeedbackPanelEvent>(EventTypeEnum.OpenFeedbackPanel, openFeedbackPanel, []);

    return (
        <Stack horizontal horizontalAlign="space-between" className={styles.banner}>
            <Stack.Item className={styles.item}>
                <Icon iconName="LineChart" className={styles.logo} />
                <h2>Budget Management</h2>
            </Stack.Item>
            <Stack horizontal tokens={innerStackTokens} styles={innerStackStyles}>
                {
                    flights?.enableUserFeedback &&
                    <IconButton title="Send feedback" className={styles.iconButton} iconProps={feedbackIconProps} onClick={captureScreenshot} />
                }
                <FeedbackPanel
                    isPanelOpen={isFeedbackPanelOpen}
                    onDismiss={handleDismissFeedbackPanel}
                    userName={account?.username}
                    currentURL={currentURL}
                    screenshotInputURL={currentScreenshotURL}
                    FeedbackTab={"Efficiency Tracker"}
                />
                <div className={styles.centerDiv}>{"Hello," + account?.username}</div>
            </Stack>
        </Stack>
    );
};

export default TrackerBanner;