import { LogComponent, LogElement } from "../../../models/LogModel";
import { SubstrateRings, SubstrateV2WikiLink } from "../../../models/constants/PcmV2Constants";

import { EmpytCostView } from "../common/EmptyCostView";
import PageHeader from "../../common/PageHeader";
import { PcmV2SceneTypeEnum } from "../../../models/PcmV2Models";
import React from "react";
import SubstrateCarbon from "./SubstrateCarbon";
import { SubstrateCategoryCostTrends } from "../common/SubstrateCategoryCostTrends";
import SubstrateCostCards from "./SubstrateCostCards";
import { SubstrateCostProportion } from "./SubstrateCostProportion";
import { SubstrateCostTable } from "../common/table/SubstrateCostTable";
import { SubstrateMonthlyTrends } from "./SubstrateMonthlyTrends";
import { SubstrateRingCostTrends } from "./SubstrateRingCostTrends";
import { SubstrateTotalCostTrends } from "../common/SubstrateTotalCostTrends";
import { WarningBanner } from "../../banner/WarningBanner";
import commonStyles from "../../common/styles/Common.less";
import styles from "../Substrate.less";
import { useFlights } from "../../../hooks/useSettings";
import { useGetBannersByTabAndDateQuery } from "../../../hooks/useBannerQuery";
import { useGetPcmV2Cost } from "../../../hooks/useSubstrateV2Query";
import { useRings } from "../../../hooks/useRings";

export const SubstrateOverview: React.FC = () => {
    const { data: pcmV2Cost } = useGetPcmV2Cost();
    const { data: flights } = useFlights();
    const { data: banners } = useGetBannersByTabAndDateQuery(PcmV2SceneTypeEnum.Overview);
    const [rings] = useRings();

    return (
        <div className={styles.pageWrapper}>
            <PageHeader
                className={styles.pageHeader}
                title="Substrate"
                description="Welcome to the Substrate COGS dashboard!"
                link={SubstrateV2WikiLink}
                linkText="Learn More"
                tag="PREVIEW"
                linkLogComponent={LogComponent.SubstrateChartingPane}
                linkLogElement={LogElement.LearnMoreSubstrate}
            />
            {!!banners?.length && (
                <div className={styles.bannerWrapper}>
                    {banners.map((item) => (
                        <WarningBanner key={item.id} bannerItem={item} />
                    ))}
                </div>
            )}
            {pcmV2Cost && pcmV2Cost.cost === 0 ? (
                <EmpytCostView />
            ) : (
                <>
                    <div className={`${commonStyles.gridWrapper} ${commonStyles.gridColumn2}`}>
                        <SubstrateCostCards />
                        <SubstrateCostProportion />
                        <SubstrateTotalCostTrends scene={PcmV2SceneTypeEnum.Overview} />
                        <SubstrateCategoryCostTrends scene={PcmV2SceneTypeEnum.Overview} />
                        <SubstrateMonthlyTrends />
                        <SubstrateRingCostTrends />
                        {/* {!SubstrateUtils.hasServiceLevelAndBelowFilter(categoryFilters.filters) && (
                            <>
                                <SubstrateCostRanking scene={PcmV2SceneTypeEnum.Overview} />
                                <SubstratePricing />
                            </>
                        )} */}
                    </div>
                    <SubstrateCostTable scene={PcmV2SceneTypeEnum.Overview} />
                </>
            )}
        </div>
    );
};
