import { ActionButton, CommandBar, DetailsList, DetailsListLayoutMode, IColumn, ICommandBarItemProps, Modal, PrimaryButton, Separator, Stack, TextField } from '@fluentui/react';
import { CategoryDivision, CategoryDivisionDisplayName } from '../../models/CategoryDivision';
import { FinOpsReviewStatus, IFinOpsBudget, IFinOpsBudgetFilters } from '../../models/FinOpsBudgets';
import React, { useEffect, useState } from 'react';
import { useGetAllHistoricalBudgetVersion, useGetAllPendingBudgetAsks, useReviewFinOpsBudgets } from '../../hooks/useFinOpsBudget';

import { BudgetLayout } from './BudgetLayout';
import FiltersDropDown from '../common/FiltersBanner/FiltersDropDown/FiltersDropDown';
import WorkloadFilter from '../common/FiltersBanner/WorkloadFilter/WorkloadFilter';
import moment from 'moment';
import styles from './ReviewBudget.less';
import { useCallback } from 'react';
import { useCategoryFilters } from '../../hooks/useFilters';

const priorityMap: Partial<Record<CategoryDivision, number>> = {
    [CategoryDivision.FiscalYear]: 5,
    [CategoryDivision.Owner]: 4,
    [CategoryDivision.Category]: 3,
    [CategoryDivision.Type]: 2,
    [CategoryDivision.ServiceId]: 1,
};

function getPriority(key: CategoryDivision): number {
    return priorityMap[key] ?? 0;
}

const SupportedFilters = [
    CategoryDivision.FiscalYear,
    CategoryDivision.ServiceId,
    CategoryDivision.Category,
    CategoryDivision.Type,
    CategoryDivision.Owner,
];

function isSearchableFilter(
    category: CategoryDivision,
) {
    switch (category) {
        case CategoryDivision.FiscalYear:
        case CategoryDivision.ServiceId:
        case CategoryDivision.Category:
        case CategoryDivision.Type:
        case CategoryDivision.Owner:
            return true;
        default:
            return false;
    }
}

const intlFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
    minimumFractionDigits: 0
});

const ReviewBudget: React.FC = () => {
    const [filters, setFilters] = useState<IFinOpsBudgetFilters>({});
    const { data: fetchedItems = [], error, isLoading, refetch: reFetchedItems, isRefetching } = useGetAllPendingBudgetAsks(filters);
    const reviewMutation = useReviewFinOpsBudgets();
    const [items, setItems] = useState<IFinOpsBudget[]>([]);
    const [filterItems, setFilterItems] = useState<IFinOpsBudget[]>([]);
    const [showItems, setShowItems] = useState<IFinOpsBudget[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentComment, setCurrentComment] = useState('');
    const [currentItemId, setCurrentItemId] = useState<number | null>(null);
    const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false);
    const [currentHistoricalVersionFilters, setCurrentHistoricalVersionFilters] = useState<Partial<IFinOpsBudget> | null>(null);
    //Global Filters
    const [selectedFilters, setSelectedFilters] = useState<CategoryDivision[]>(SupportedFilters);
    const { filters: globalFilters, clearAllFiltersOnly } = useCategoryFilters();

    const { data: historyItems = [], refetch: fetchHistoryItems } = useGetAllHistoricalBudgetVersion(filters);

    const columns: IColumn[] = [
        { key: 'fiscalYear', name: 'FiscalYear', fieldName: 'fiscalYear', minWidth: 25, maxWidth: 200, isResizable: false, className: styles.PrimaryColumn },
        { key: 'serviceId', name: 'ServiceId', fieldName: 'serviceId', minWidth: 25, maxWidth: 200, isResizable: false, className: styles.PrimaryColumn },
        { key: 'category', name: 'Category', fieldName: 'category', minWidth: 25, maxWidth: 200, isResizable: false, className: styles.PrimaryColumn },
        { key: 'type', name: 'Type', fieldName: 'type', minWidth: 25, maxWidth: 200, isResizable: false, className: styles.PrimaryColumn },
        { key: 'increaseRate', name: 'IncreaseRate', fieldName: 'increaseRate', minWidth: 25, maxWidth: 200, isResizable: false, className: styles.NormalColumn, onRender: (item: IFinOpsBudget) => (item.increaseRate + '%') },
        { key: 'costBudget', name: 'CostBudget', fieldName: 'costBudget', minWidth: 25, maxWidth: 200, isResizable: false, className: styles.NormalColumn, onRender: (item: IFinOpsBudget) => (intlFormatter.format(item.costBudget)) },
        { key: 'quantityBudget', name: 'QuantityBudget', fieldName: 'quantityBudget', minWidth: 25, maxWidth: 200, isResizable: false, className: styles.NormalColumn },
        { key: 'submitter', name: 'Submitter', fieldName: 'submitter', minWidth: 25, maxWidth: 200, isResizable: false, className: styles.NormalColumn },
        { key: 'justification', name: 'Justification', fieldName: 'justification', minWidth: 25, maxWidth: 200, isResizable: false, className: styles.NormalColumn },
        {
            key: 'history',
            name: 'View History Version',
            fieldName: 'history',
            minWidth: 200,
            maxWidth: 300,
            isResizable: false,
            onRender: (item: IFinOpsBudget) => (
                <PrimaryButton text="View History Version" onClick={() => openHistoryModal(item)} />
            ),
            className: styles.NormalColumn
        },
        {
            key: 'comment',
            name: 'Comment',
            fieldName: 'comment',
            minWidth: 200,
            maxWidth: 300,
            isResizable: false,
            onRender: (item: IFinOpsBudget) => (
                <TextField
                    value={item.comment}
                    onClick={() => openCommentModal(item.id, item.comment || '')}
                    readOnly
                />
            ),
            className: styles.NormalColumn
        },
        {
            key: 'review',
            name: 'Approve/Reject',
            minWidth: 200,
            maxWidth: 300,
            isResizable: false,
            onRender: (item: IFinOpsBudget) => (
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <PrimaryButton text="Approve" onClick={() => handleApprove(item, FinOpsReviewStatus.Approved)} />
                    <PrimaryButton text="Reject" onClick={() => handleReject(item, FinOpsReviewStatus.Denied)} />
                </Stack>
            ),
            className: styles.NormalColumn
        },
    ];

    const historyColumns: IColumn[] = [
        { key: 'fiscalYear', name: 'FiscalYear', fieldName: 'fiscalYear', minWidth: 100, maxWidth: 200, isResizable: true },
        { key: 'serviceId', name: 'ServiceId', fieldName: 'serviceId', minWidth: 100, maxWidth: 200, isResizable: true },
        { key: 'increaseRate', name: 'IncreaseRate', fieldName: 'increaseRate', minWidth: 100, maxWidth: 200, isResizable: true, onRender: (item: IFinOpsBudget) => (item.increaseRate + '%') },
        { key: 'costBudget', name: 'CostBudget', fieldName: 'costBudget', minWidth: 100, maxWidth: 200, isResizable: true, onRender: (item: IFinOpsBudget) => (intlFormatter.format(item.costBudget)) },
        { key: 'quantityBudget', name: 'QuantityBudget', fieldName: 'quantityBudget', minWidth: 100, maxWidth: 200, isResizable: true },
        { key: 'submitter', name: 'Submitter', fieldName: 'submitter', minWidth: 100, maxWidth: 200, isResizable: true },
        { key: 'version', name: 'Version', fieldName: 'version', minWidth: 100, maxWidth: 200, isResizable: true, onRender: (item: IFinOpsBudget) => moment(item.version).format('YYYY-MM-DD HH:mm:ss') },
        { key: 'approver', name: 'Approver', fieldName: 'approver', minWidth: 100, maxWidth: 200, isResizable: true },
        { key: 'approved', name: 'Approved', fieldName: 'approved', minWidth: 100, maxWidth: 200, isResizable: true, onRender: (item: IFinOpsBudget) => item.approved === 2 ? 'Approved' : 'Rejected' },
        { key: 'approvedTime', name: 'ApprovedTime', fieldName: 'approvedTime', minWidth: 100, maxWidth: 200, isResizable: true, onRender: (item: IFinOpsBudget) => moment(item.approvedTime).format('YYYY-MM-DD HH:mm:ss') },
        { key: 'justification', name: 'Justification', fieldName: 'justification', minWidth: 100, maxWidth: 200, isResizable: true },
        { key: 'comment', name: 'Comment', fieldName: 'comment', minWidth: 100, maxWidth: 200, isResizable: true },
    ];

    const handleApprove = async (item: IFinOpsBudget, ReviewRes: number) => {
        try {
            item.approved = ReviewRes;
            await reviewMutation.mutateAsync({ ...item });
            alert('Approval successful!');
            setFilterItems(filterItems.filter(i => i.id !== item.id)); // Refresh the table after approval
        } catch (error) {
            console.error('Error approving budget:', error);
            alert('Error approving budget: ' + error);
        }
    };

    const handleReject = async (item: IFinOpsBudget, ReviewRes: number) => {
        if (!currentComment) { // Check if a comment has been provided
            openCommentModal(item.id, ''); // Open the comment modal if no comment is provided
            alert('Please provide a comment before rejecting.');
            return; // Exit the function to wait for the comment
        }
        
        try {
            item.approved = ReviewRes;
            await reviewMutation.mutateAsync({ ...item });
            alert('Rejection successful!');
            setFilterItems(filterItems.filter(i => i.id !== item.id)); // Refresh the table after reject
        } catch (error) {
            console.error('Error rejecting budget:', error);
            alert('Error rejecting budget: ' + error);
        }
    };

    const openCommentModal = (id: number, comment: string) => {
        setCurrentComment(comment);
        setCurrentItemId(id);
        setIsModalOpen(true);
        console.log(id);
    };

    const closeCommentModal = () => {
        setIsModalOpen(false);
        console.log(currentItemId);
        if (currentItemId !== null) {
            const updatedItems = items.map(item =>
                item.id === currentItemId ? { ...item, comment: currentComment } : item
            );
            setItems(updatedItems);
        }
    };

    const openHistoryModal = (item: IFinOpsBudget) => {
        fetchHistoryItems();
        setCurrentHistoricalVersionFilters({
            fiscalYear: item.fiscalYear,
            serviceId: item.serviceId,
            category: item.category,
            type: item.type,
            submitter: item.submitter,
        });
        setIsHistoryModalOpen(true);
    };
    
    const closeHistoryModal = () => {
        setIsHistoryModalOpen(false);
        setCurrentHistoricalVersionFilters(null);
    };
    
    const filteredHistoryItems = historyItems.filter(historyItem => 
        historyItem.fiscalYear === currentHistoricalVersionFilters?.fiscalYear &&
        historyItem.serviceId === currentHistoricalVersionFilters?.serviceId &&
        historyItem.category === currentHistoricalVersionFilters?.category &&
        historyItem.type === currentHistoricalVersionFilters?.type &&
        historyItem.submitter === currentHistoricalVersionFilters?.submitter &&
        historyItem.approved !== null && historyItem.approved !== 0
    );

    function getFilterList(
        category: CategoryDivision,
    ) {
        switch (category) {
            case CategoryDivision.FiscalYear:
                return Array.from(
                    new Set(items.map(item => item.fiscalYear.trim()))
                ).sort();
            case CategoryDivision.ServiceId:
                return Array.from(
                    new Set(items.map(item => item.serviceId.trim()))
                ).sort();
            case CategoryDivision.Category:
                return Array.from(
                    new Set(items.map(item => item.category.trim()))
                ).sort();
            case CategoryDivision.Type:
                return Array.from(
                    new Set(items.map(item => item.type.trim()))
                ).sort();
            case CategoryDivision.Owner:
                return Array.from(
                    new Set(items.map(item => item.submitter?.trim() || ''))
                ).sort();
            default:
                return [];
        }
    }

    const selectedfilterItems = selectedFilters.map((filter) => ({
        key: `${filter}Filter`, // unique key
        onRender: () => (
            <div className={styles.Filter}>
                <WorkloadFilter
                    hidden={!selectedFilters.includes(filter)}
                    category={filter}
                    optionList={getFilterList(filter)}
                    displayName={CategoryDivisionDisplayName[filter]}
                    isSearchable={isSearchableFilter(filter)}
                />
            </div>
        ),
    }));

    //Clear all Global Filters
    const onClearAll = useCallback(() => {
        clearAllFiltersOnly()
    }, [clearAllFiltersOnly]);

    const filterBarItem: ICommandBarItemProps[] = [
        {
            key: 'FilterManage',
            onRender: () => (
                <div className={styles.Filter}>
                    <FiltersDropDown
                        selectedKeys={selectedFilters}
                        onChange={(newSelectedFilters: CategoryDivision[]) => {
                            const sortedFilters = newSelectedFilters.sort((a: CategoryDivision, b: CategoryDivision) => { //order first
                                return getPriority(a) < getPriority(b) ? 1 : -1;
                            });

                            setSelectedFilters(sortedFilters); //set oredered filter
                        }}
                        supportedFilters={SupportedFilters}
                    />
                    <ActionButton key="clear" iconProps={{ iconName: "ClearFilter" }} onClick={onClearAll} style={{ padding: 0 }}>
                        Reset all
                    </ActionButton>
                </div>
            ),
        },
        ...selectedfilterItems,
    ];

    //Get all used CategoryDivision
    function getSelectedFiltersFromGlobalFilter() {
        const filters = globalFilters.filters;
        const tempSelectedFilters = new Set<CategoryDivision>([]);
        selectedFilters.forEach((item) => { // Insert current filter first
            tempSelectedFilters.add(item);
        });

        SupportedFilters.forEach((filterKey) => { // Add global filter then
            if ((filters[filterKey]?.length || 0) > 0) {
                tempSelectedFilters.add(filterKey);
            }
        });

        return tempSelectedFilters;
    }

    /***** All Status Change ********************************/
    useEffect(() => {
        if (isLoading || isRefetching) {
            reFetchedItems();
            setItems([]);
            setFilterItems([]);
            return;
        }
        if (!fetchedItems) return;

        setItems(fetchedItems);
        setFilterItems(fetchedItems);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading, isRefetching, fetchedItems]);

    useEffect(() => {
        const filters = globalFilters.filters;
        const fiscalYearList = filters.FiscalYear;
        const serviceIdList = filters.ServiceId;
        const categoryList = filters.Category;
        const typeList = filters.Type;
        const submitterList = filters.Submitter;

        const tmpItems = [...items];

        const tmpFilterItems = tmpItems.filter(item => {
            const fiscalYearFilter = fiscalYearList?.length ? fiscalYearList.includes(item.fiscalYear) : true;
            const serviceIdFilter = serviceIdList?.length ? serviceIdList.includes(item.serviceId) : true;
            const categoryFilter = categoryList?.length ? categoryList.includes(item.category) : true;
            const typeFilter = typeList?.length ? typeList.includes(item.type) : true;
            const submitterFilter = submitterList?.length ? submitterList.includes(item.submitter ?? "") : true;
            return fiscalYearFilter && serviceIdFilter && categoryFilter && typeFilter && submitterFilter;
        });

        setSelectedFilters([...getSelectedFiltersFromGlobalFilter()]);
        setFilterItems(tmpFilterItems);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [globalFilters.filters, items]);

    useEffect(() => {
        setShowItems(filterItems);
    }, [filterItems]);

    if (error) {
        return <div>Error fetching pending review budget asks: {error.message}</div>;
    }

    return (
        <BudgetLayout>
            <div className="container">
                <div className={styles.header}>
                    <h1>Review Budget</h1>
                </div>
                <Separator styles={{ root: styles.Separator }} />
                <CommandBar
                    styles={{
                        root: {
                            height: '32px',
                        },
                    }}
                    key={"CommandBar_filter"}
                    items={filterBarItem}
                />
                <DetailsList
                    items={showItems}
                    columns={columns}
                    setKey="set"
                    layoutMode={DetailsListLayoutMode.fixedColumns}
                    selectionPreservedOnEmptyClick={true}
                    ariaLabelForSelectionColumn="Toggle selection"
                    checkButtonAriaLabel="select row"
                />
                <Modal
                    isOpen={isModalOpen}
                    onDismiss={closeCommentModal}
                    isBlocking={false}
                    containerClassName={styles.modal}
                >
                    <div>
                        <div className={styles.modalHeader}>
                            <h2>Add Comment</h2>
                        </div>
                        <TextField
                            label="Comment"
                            value={currentComment}
                            onChange={(e, newValue) => setCurrentComment(newValue || '')}
                            className={styles.popUpWindow}
                        />
                        <div className={styles.modalFooter}>
                            <Stack horizontal tokens={{ childrenGap: 10 }} horizontalAlign="end">
                                <PrimaryButton text="Save" onClick={closeCommentModal} className={styles.button} />
                                <PrimaryButton text="Cancel" onClick={() => setIsModalOpen(false)} className={styles.button} />
                            </Stack>
                        </div>
                    </div>
                </Modal>
                <Modal
                    isOpen={isHistoryModalOpen}
                    onDismiss={closeHistoryModal}
                    isBlocking={false}
                    containerClassName={styles.modal}
                >
                    <div>
                        <div className={styles.modalHeader}>
                            <h2>Historical Budget Versions</h2>
                        </div>
                        <DetailsList
                            items={filteredHistoryItems}
                            columns={historyColumns}
                            setKey="set"
                            layoutMode={DetailsListLayoutMode.fixedColumns}
                        />
                        <div className="modalFooter">
                            <Stack horizontal tokens={{ childrenGap: 10 }} horizontalAlign="end">
                                <PrimaryButton text="Close" onClick={closeHistoryModal} className={styles.button} />
                            </Stack>
                        </div>
                    </div>
                </Modal>
            </div>
        </BudgetLayout>
    );
};

export default ReviewBudget;