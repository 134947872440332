import EmptyState from "../../common/state/EmptyState";
import React from "react";
import { Stack } from "@fluentui/react";
import commonStyles from "../../common/styles/Common.less";
import styles from "./ComingSoonView.less";
import { InsightPlatformWikiLink } from "../../../models/constants/InsightPlatformConstants";

export const ComingSoonView: React.FunctionComponent = () => {
    return (
        <Stack className={styles.comingSoonView} horizontalAlign="center">
            <EmptyState />
            <div className={styles.description}>Coming Soon!</div>
            <a href={InsightPlatformWikiLink} className={commonStyles.link} target="_blank" rel="noreferrer">
                {"Insight Platform Wiki"}
            </a>
        </Stack>
    );
};
