import { Icon, IIconProps, Pivot, PivotItem } from "@fluentui/react";
import React from "react";
import { InsightPlatformWikiLink } from "../../../models/constants/InsightPlatformConstants";
import { useParams } from "react-router-dom";
import { useGotoTab } from "../../../hooks/useGotoPage";
import { Pages, SubPages } from "../../../models/Nav";
import { trackEventCallback } from "../../../utils/AppInsights";
import { LogComponent, LogElement, LogTarget } from "../../../models/LogModel";
import PageHeader from "../../common/PageHeader";
import commonStyles from "../../substrateV2/Substrate.less";
import { EmpytCostView } from "../../substrateV2/common/EmptyCostView";
import styles from "../../substrateV2/transaction/SubstrateTransaction.less";
import { InsightPortalByAnomalies } from "./InsightPortalByAnomalies";
import { currencyFormatter } from "../../../utils/currency";
import { useGetInsightsPortalData } from "../../../hooks/useInsightPlatformQuery";
import { IInsightUsageBO, PlatformTypeEnum } from "../../../models/InsightsPlatformModels";
import { useFlights } from "../../../hooks/useSettings";
import { ComingSoonView } from "../../substrateV2/common/ComingSoonView";
import LoadingState from "../../ResponseBoundary/LoadingState";

export const InsightsPortal: React.FC = () => {

    const { data: flights } = useFlights();

    const { data: insightPortalCost, isLoading } = useGetInsightsPortalData();

    const curTab = useParams().tab || "Anomalies";

    const gotoTab = useGotoTab();
    const handleLinkClick = (item?: PivotItem) => {
        if (item?.props.itemKey) {
            gotoTab(`${Pages.InsightPortal}/${SubPages.InsightPortalOverview}`, item?.props.itemKey, `${Pages.InsightPortal}/${SubPages.InsightPortalOverview}`);
            trackEventCallback(LogComponent.InsightPortal, item.props.itemKey === "Anomalies" ? LogElement.InsightPortalAnomaly : (item.props.itemKey === "Recommendation" ? LogElement.InsightPortalRecommendation : LogElement.InsightPortalRealizedSaings), item.props.itemKey, LogTarget.Tab);
        }
    };

    let anomalyData = undefined;
    let anomalyTotalCost = 0;
    let anomalyUsageSummaryData = {} as Record<PlatformTypeEnum, number>;
    let anomalyDetailList:IInsightUsageBO[] = [];

    let recommendationData = undefined;
    let recommendationTotalCost = 0;
    let recommendationUsageSummaryData = {} as Record<PlatformTypeEnum, number>;
    let recommendationDetailList:IInsightUsageBO[] = [];

    let realizedData = undefined;
    let realizedTotalCost = 0;
    let realizedUsageSummaryData = {} as Record<PlatformTypeEnum, number>;
    let realizedDetailList:IInsightUsageBO[] = [];

    let anomalyHeader = '';
    let recommendationHeader = '';
    let realizedHeader = '';

    if (!isLoading && insightPortalCost != undefined) 
    {
        anomalyData = insightPortalCost!.Anomaly;
        anomalyTotalCost = anomalyData?.totalSaving | 0;
        anomalyUsageSummaryData = anomalyData?.usageSummary || [];
        anomalyDetailList = anomalyData?.detailList || [];

        recommendationData = insightPortalCost!.Recommendation;
        recommendationTotalCost = recommendationData?.totalSaving | 0;
        recommendationUsageSummaryData = recommendationData?.usageSummary || [];
        recommendationDetailList = recommendationData?.detailList || [];

        realizedData = insightPortalCost!.Realized;
        realizedTotalCost = realizedData?.totalSaving | 0;
        realizedUsageSummaryData = realizedData?.usageSummary || [];
        realizedDetailList = realizedData?.detailList || [];

        // anomalyHeader = `Anomalies: ${currencyFormatter(anomalyTotalCost || 0)}`;
        // recommendationHeader = `Potential Savings: ${currencyFormatter(recommendationTotalCost || 0)}`;
        // realizedHeader = `Realized Savings: ${currencyFormatter(realizedTotalCost || 0)}`;

        anomalyHeader = `Anomalies: ${currencyFormatter(anomalyTotalCost || 0)}`;
        recommendationHeader = `Potential Savings: Coming Soon`;
        realizedHeader = `Realized Savings: Coming Soon`;
    }

    return (
        <>
            { flights?.enableInsightPlatformMVP && (
            <div className={commonStyles.pageWrapper}>
                <PageHeader
                    className={commonStyles.pageHeader}
                    title="Jaws Actionable Insights"
                    description="Welcome to the Actionable Insights Portal! You can view and take action on your anomalies and saving opportunities. View our Wiki to learn more about Insights, view tutorials, and get answers to FAQ."
                    link={InsightPlatformWikiLink}
                    linkText="Actionable Insights in Jaws - Overview"
                    tag="PREVIEW"
                    linkLogComponent={LogComponent.TransChartingPane}
                    linkLogElement={LogElement.LearnMoreTrans}
                />
                
                { isLoading? (<LoadingState />):  (insightPortalCost == undefined ? (
                    <EmpytCostView />
                ) : (
                    <Pivot selectedKey={curTab} onLinkClick={handleLinkClick} focusZoneProps={{ className: styles.pivotList }} overflowBehavior="menu">
                        <PivotItem headerText={anomalyHeader} itemKey="Anomalies">
                        { anomalyDetailList.length == 0 ? (
                            <EmpytCostView />) : (
                            <InsightPortalByAnomalies anomalyUsageSummaryData={anomalyUsageSummaryData} anomalyDetailList={anomalyDetailList} anomalyTotalCost={anomalyTotalCost}/>
                        )}
                        </PivotItem>
                        <PivotItem headerText={recommendationHeader} itemKey="Recommendation">
                        { recommendationDetailList.length == 0 ? (
                            <ComingSoonView />) : (
                            <InsightPortalByAnomalies anomalyUsageSummaryData={anomalyUsageSummaryData} anomalyDetailList={anomalyDetailList} anomalyTotalCost={anomalyTotalCost}/>
                        )}
                        </PivotItem>
                        <PivotItem headerText={realizedHeader} itemKey="Realized">
                            <ComingSoonView />
                        </PivotItem>
                    </Pivot>
                ))}
            </div> 
            )}
        </>
    );
};
