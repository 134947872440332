import moment from "moment";
import { IFinOpsBudget } from "../models/FinOpsBudgets";

const Categories = [
    'Compute:AIRS:Public Azure:Cores',
    'Compute:AIRS:Cosmic:Cores',
    'Network:MCIO Network:LongHaul T0:Mbps',
    'Network:MCIO Network:LongHaul T2:Mbps',
    'Network:MCIO Network:Egress:Mbps',
    'Storage:AIRS:Transactions:10K',
    'Storage:AIRS:Storage - Other:GB',
    'Storage:AIRS:Bandwidth:GB',
    'Storage:AIRS:Managed SSD Disks:GB',
    'Storage:AIRS:Managed HDD Disks:GB',
    'Storage:AIRS:Other:',
    'Others:AIRS:Others:',
    'Monitoring:AIRS:SQL:',
    'Monitoring:AIRS:Redis:',
    'Data Operations:AIRS:ADF:',
    'Monitoring:AIRS:Cosmos DB:RU/s',
    'Monitoring:AIRS:Cosmos DB:GB',
    'Monitoring:AIRS:Cosmos DB:',
    'Monitoring:AIRS:Azure Front Door:',
    'Monitoring:AIRS:Azure Front Door:GB',
    'Monitoring:AIRS:Azure Front Door:10K',
    'Monitoring:AIRS:Azure Log Analytics:GB',
    'Monitoring:AIRS:Azure Log Analytics:',
    'Monitoring:AIRS:Synapse Analytics:',
    'Monitoring:AIRS:Synapse Analytics:vCore',
    'Monitoring:AIRS:Synapse Analytics:GB',
    'Monitoring:AIRS:Synapse Analytics:DWUs',
    'Network:AIRS:Azure Bandwidth:GB',
    'Monitoring:AIRS:Azure Monitor:',
    'Monitoring:AIRS:Azure Monitor:1 GB',
    'Monitoring:AIRS:Azure Monitor:10M',
    'Monitoring:AIRS:Azure Monitor:1GB/Day',
    'Monitoring:AIRS:Azure Monitor:1M',
    'Passive Monitoring:AIRS:GenevaMdm:WeightedTimeSeriesCount',
    'Passive Monitoring:AIRS:GenevaMdm:EventRate/Min',
    'Passive Monitoring:Non-AIRS:GenevaMdsIndirect:GB',
    'Passive Monitoring:AIRS:GenevaMds:GB',
    'Passive Monitoring:AIRS:Kusto:GB',
    'Passive Monitoring:AIRS:Kusto:CPU',
];

function blankBudget(
    fiscalYear: string,
    serviceId: string,
    category: string,
    type: string,
    unit: string): IFinOpsBudget {
    return {
        id: 0,
        fiscalYear: fiscalYear,
        serviceId: serviceId,
        category: category,
        type: type,
        lastYearCost: 0,
        lastYearQuantity: 0,
        unit: unit,
        increaseRate: 4,
        quantityBudget: 0,
        costBudget: 0,
    };
}

function getCategory(category: string, type: string) {
    switch (category) {
        case 'Network':
            if (type === 'Azure Bandwidth') return 'AIRS';
            else return 'MCIO Network';
        case 'Passive Monitoring':
            if (type === 'GenevaMdm') return 'Non-AIRS';
            else return 'AIRS';
        default:
            return 'AIRS';
    }
}

export function suppleBudgets(
    fiscalYear: string,
    serviceId: string,
    budgets: IFinOpsBudget[]) {
    const set = new Set(Categories);
    budgets.forEach(budget => set.delete(`${budget.category}:${getCategory(budget.category, budget.type)}:${budget.type}:${budget.unit}`));
    return [
        ...budgets,
        ...Array.from(set).map(item => {
            const [category, _, type, unit] = item.split(':');
            return blankBudget(fiscalYear, serviceId, category, type, unit);
        })
    ]
}

export function clearEmptyBudgets(budgets?: IFinOpsBudget[]) {
    if (!budgets) return budgets;
    return budgets.filter(budget => budget.lastYearCost !== 0 || budget.lastYearQuantity !== 0 || budget.costBudget !== 0);
}

export function getLastFiscalYear(fiscalYear: string) {
    if (fiscalYear.length < 4) return fiscalYear;
    return `FY${parseInt(fiscalYear.substring(2)) - 1}`;
}

export function getBudgetFiscalYear() {
    const now = moment();
    const month = now.month();
    return 'FY' + (parseInt(now.format('YY')) + 1 + (month > 6 ? 1 : 0));
}