import { INavLink, INavLinkGroup, INavStyles, Icon, Nav, Stack } from "@fluentui/react";
import { LogComponent, LogTarget } from "../../models/LogModel";
import React, { useCallback, useMemo, useState } from "react";

import { Flight } from "../../models/Flights";
import { Pages } from "../../models/Nav";
import { getTabCache } from "../../utils/pageUtils";
import styles from "./BudgetNavigation.less";
import { trackEventCallback } from "../../utils/AppInsights";
import { useFlights } from "../../hooks/useSettings";
import { useGotoPage } from "../../hooks/useGotoPage";
import { useLocation } from "react-router-dom";

const getbudgetLinks = function(flights?: Flight): INavLinkGroup[] {
    const links = [
            {
                name: "Submit budget",
                icon: "money",
                key: `${Pages.Budget}/${Pages.SubmitBudget}`,
                url: `${Pages.Budget}/${Pages.SubmitBudget}`,
            },
            {
                name: "Budget Report",
                icon: "AnalyticsReport",
                key: `${Pages.Budget}/${Pages.Report}`,
                url: `${Pages.Budget}/${Pages.Report}`,
            },
        ];

    if (flights?.enableFinOpsReviewPage) {
        links.push({
            name: "Review budget",
            icon: "ReviewSolid",
            key: `${Pages.Budget}/${Pages.ReviewBudget}`,
            url: `${Pages.Budget}/${Pages.ReviewBudget}`,
        });
    }

    return [
        {
            name: "FinOps Budget Links",
            links
        },
    ];
}

const NavItemStyles: Partial<INavStyles> = {
    root: {
        width: "100%",
        boxSizing: "border-box",
        border: "1px solid #eee",
        overflowY: "auto",
    },
    groupContent: {
        marginBottom: 12,
    },
    linkText: {
        fontFamily: "Segoe UI",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "20px",
    },
    link: {
        paddingLeft: 16,
        selectors: {
            "&:after": {
                "border-left": "4px solid",
                "border-left-color": "#0078D4",
            },
        },
        ".ms-Icon": {
            "font-size": "14px",
            color: "#323130",
            width: 16,
        },
        ".ms-Button-flexContainer": {
            "margin-left": "12px",
        },
    },
};

const CollapseNavItemStyles: Partial<INavStyles> = {
    root: {
        width: "100%",
        boxSizing: "border-box",
        border: "1px solid #eee",
        overflowY: "auto",
    },
    groupContent: {
        marginBottom: 12,
    },
    linkText: { display: 'none' }, // hide text
    link: {
        selectors: {
            "&:after": {
                "border-left": "4px solid",
                "border-left-color": "#0078D4",
            },
        },
        ".ms-Icon": {
            "font-size": "14px",
            color: "#323130",
            width: 16,
        },
        ".ms-Button-flexContainer": {
            "margin-left": "1px",
            "padding-left": "6px",
        },
    },
};

const BudgetNavigation: React.FC = () => {
    const location = useLocation();
    const gotoPage = useGotoPage();

    const [ collapse, setCollapse ] = useState<boolean>(false);
    const { data: flights } = useFlights();
    const finOpsBudgetLinks = useMemo(() => getbudgetLinks(flights), [flights]);

    const handleGlobalNavClick = () => {
        setCollapse(!collapse);
    };

    const handleClick = useCallback(
        (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
            if (item?.target !== "_blank") {
                ev?.preventDefault();
                if (item?.url) {
                    const tab = getTabCache(item.tabKey || item.url);
                    gotoPage(item.url + (tab ? `/${tab}` : ""));
                }
            }
            trackEventCallback(LogComponent.PivotNavPane, item?.logElement, item?.title || "", LogTarget.Nav);
        },
        [gotoPage]
    );

    return (
        <Stack className={collapse ? styles.navigationCollapse : styles.navigation} disableShrink>
            <Icon iconName="GlobalNavButton" className={styles.macLeftNav} onClick={handleGlobalNavClick} style={{ cursor: 'pointer' }} title={collapse ? "Open sidebar" : "Collapse sidebar"}></Icon>
            {collapse && (<>
                <Nav selectedKey={location.pathname} styles={CollapseNavItemStyles} groups={finOpsBudgetLinks} onLinkClick={handleClick} />
                <Stack.Item shrink={0}><div className={styles.separator} /></Stack.Item>
            </>)}
            {!collapse && (<>
                <Stack.Item shrink={0} className={styles.sectionHeader}>Budget Management</Stack.Item>
                <Nav selectedKey={location.pathname} styles={NavItemStyles} groups={finOpsBudgetLinks} onLinkClick={handleClick} />
                <Stack.Item shrink={0}><div className={styles.separator} /></Stack.Item>
            </>)}
        </Stack>
    );
};

export default BudgetNavigation;
