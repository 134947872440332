import { Cell, Column, HeaderGroup, TableHeaderProps, TableInstance, TableOptions, TableState, UseSortByOptions, UseSortByState, useSortBy, useTable } from "react-table";
import { CosmicServiceDetailTableDisplayNames, CosmicServiceDetailsTableDefaultPageSize, CosmicServiceDetailsTableNumericalColumns } from "./CosmicServiceDetailTableConstants";
import { ICosmicServiceDetailReport, ICosmicServiceDetails } from "../../models/CosmicModels";
import { IStickyTableCellOption, IStickyTableFooterOption, IStickyTableHeaderOption } from "../common/table/StickyTable";
import { useCallback, useEffect, useMemo, useState } from "react";

import { IColumnSelectorItem } from "../common/table/ColumnSelector";
import React from "react";
import { formatNumber } from "../../utils/currency";
import styles from './PartnerServiceDetailTable.less';
import { useCategoryFilters } from "../../hooks/useFilters";
import { useDateRange } from "../../hooks/useDateSelector";
import { useGetCosmicServiceDetails } from "../../hooks/useCosmicQuery";

export const getHeaderOption = (header: HeaderGroup): IStickyTableHeaderOption => {
    const cellProps: TableHeaderProps = { className: '', key: header.id };

    if (CosmicServiceDetailsTableNumericalColumns.has(header.id)) {
        cellProps.className += ' ' + styles.textAlignRight;
    }
    else {
        cellProps.className += ' ' + styles.textAlignLeft;
    }

    return { props: cellProps };
};

export const getFooterOption = (footer: HeaderGroup): IStickyTableFooterOption => {
    return {
        props: {
            key: footer.id,
            className: CosmicServiceDetailsTableNumericalColumns.has(footer.id) ? styles.textAlignRight : '',
        },
    };
};

export const getCellOption = (cell: Cell): IStickyTableCellOption => {
    const cellProps: React.HTMLProps<HTMLTableCellElement> = { className: '', key: cell.column.id, style: {} };

    if (CosmicServiceDetailsTableNumericalColumns.has(cell.column.id)) {
        cellProps.className += ' ' + styles.textAlignRight;
    } else {
        cellProps.className += ' ' + styles.textAlignLeft;
    }

    return { props: cellProps };
};


type IUseDetailTableSortSetter = (key?: string, desc?: boolean) => void;


export const useCosmicDetailTable = (
    columnKeys: (keyof ICosmicServiceDetails)[],
    detailData: ICosmicServiceDetailReport<ICosmicServiceDetails> | undefined,
    setSort: IUseDetailTableSortSetter,
    initialDesc: boolean,
    initialSort?: string,
    defaultHiddenColumns?: string[],
) => {
    const { total, data } = detailData || {};

    const renderCell = (cell: Cell) => {
        if (cell.column.id.includes('Utilization')) {
            return (
                <div className={styles.cosmicServiceMetricCellContent} >
                    {formatNumber(cell.value * 100, 2)}
                    {'%'}
                </div>
            )
        }
        else if (cell.column.id.includes('Rightsizing')) {
            return (
                <div className={styles.cosmicServiceMetricCellContent} >
                    {formatNumber(cell.value, 2)}
                    {'%'}
                </div>
            )
        }
        else if (cell.column.id.includes('Usage') || cell.column.id.includes('AppCores') || cell.column.id.includes('Request') || cell.column.id.includes('Limit') || cell.column.id.includes('MemoryWorkingSetMB')) {
            return formatNumber(cell.value, 2) || '-';
        }
        else if (cell.column.id.includes('Count')) {
            return formatNumber(cell.value, 0) || '-';
        }
        else if (cell.column.id.includes('Date')) {
            return new Date(cell.value).toLocaleDateString() || '-';
        }
        return cell.value || '-';
    };

    const columns: Column<ICosmicServiceDetails>[] = useMemo(() => {
        return columnKeys.map((id, index) => {
            let footer: string;

            if ((id as string).includes('Usage') || (id as string).includes('appCores') || (id as string).includes('Request') || (id as string).includes('Limit') || (id as string).includes('memoryWorkingSetMB')) {
                footer = formatNumber((total && total[id] as any as number) || 0, 2);
            } else if ((id as string).includes('Count')) {
                footer = formatNumber((total && total[id] as any as number) || 0, 0);
            }
            else {
                footer = index == 0 ? 'Total' : '';
            }

            return {
                id: id.charAt(0).toUpperCase() + id.slice(1),
                accessor: id,
                Header: CosmicServiceDetailTableDisplayNames[id.charAt(0).toUpperCase() + id.slice(1)] || id.charAt(0).toUpperCase() + id.slice(1),
                Footer: footer,
                Cell: renderCell,
                sortDescFirst: true,
                disableSortBy: false,
            };
        });
    }, [columnKeys, total]);

    const getRowId: (data: ICosmicServiceDetails) => string = useCallback((data: ICosmicServiceDetails) => {
        return data.date + '_' + data.serviceId + '_' + data.serviceName + '_' + data.serviceGroupName + '_' + data.teamGroupName + '_' + data.namespace + '_' + data.region + '_' + data.ring + '_' + data.nodeOS + '_' + data.workload;
    }, []);

    const tableInstance = useTable<ICosmicServiceDetails>(
        {
            columns,
            data: data || [],
            manualSortBy: true,
            autoResetHiddenColumns: false,
            getRowId,
            initialState: {
                sortBy: initialSort ? [
                    {
                        id: initialSort,
                        desc: initialDesc,
                    }
                ] : [],
                hiddenColumns: defaultHiddenColumns
            }
        } as TableOptions<ICosmicServiceDetails> & UseSortByOptions<ICosmicServiceDetails>,
        useSortBy
    ) as TableInstance<ICosmicServiceDetails> & { state: TableState<ICosmicServiceDetails> & UseSortByState<ICosmicServiceDetails> };

    useEffect(() => {
        if (tableInstance.state.sortBy && tableInstance.state.sortBy.length) {
            setSort(tableInstance.state.sortBy[0].id, !!tableInstance.state.sortBy[0].desc);
        } else {
            setSort(undefined);
        }
    }, [tableInstance.state.sortBy]);

    return tableInstance;
};

export const useDetailTableColumnSelection = <D extends Record<any, any>>(tableInstance: TableInstance<D>) => {
    const { columns } = tableInstance;
    const [visibleColumns, setVisibleColumns] = useState<string[]>(columns
        .map(item => item.id as string)
        .filter((col) => {
            if (!tableInstance.initialState?.hiddenColumns) return true;

            return tableInstance.initialState.hiddenColumns.indexOf(col) < 0;
        }));

    const columnSelectorOptions = useMemo<IColumnSelectorItem[]>(() => {
        return columns.map((({ id = '' }, index) => ({ key: id, disabled: (id as string) === 'ServiceName', displayName: CosmicServiceDetailTableDisplayNames[id] })));
    }, [columns]);

    const changeVisibleColumns = (selectedColumns: string[]) => {
        setVisibleColumns(selectedColumns);
        tableInstance.setHiddenColumns((columns.filter(col => col.id && selectedColumns.indexOf(col.id) < 0)).map(item => item.id as string));
    };

    return {
        visibleColumns,
        columnSelectorOptions,
        changeVisibleColumns,
    }
};

export const useCosmicServiceDetailReportParams = (
    defaultSortKey?: string,
) => {
    const globalFilters = useCategoryFilters().filters.filters;
    const { singleDate: date } = useDateRange();
    const [limit, setLimit] = useState(CosmicServiceDetailsTableDefaultPageSize);

    const [sortByKey, setSortByKey] = useState<string | undefined>(defaultSortKey);
    const [sortDesc, setSortDesc] = useState(true);

    const setSort = useCallback((key?: string, desc?: boolean) => {
        setSortByKey(key);
        setSortDesc(!!desc);
    }, []);

    useEffect(() => {
        setLimit(CosmicServiceDetailsTableDefaultPageSize);
    }, [globalFilters, date, sortByKey, sortDesc]);

    return { sortByKey, defaultSortKey, sortDesc, limit, setLimit, setSort };
};

export const useCosmicServiceDetailReportQuery = (
    options: ReturnType<typeof useCosmicServiceDetailReportParams>,
    summary?: ICosmicServiceDetailReport<ICosmicServiceDetails>,
) => {
    const { sortByKey, defaultSortKey, sortDesc, limit, setLimit, setSort } = options;

    const query = useGetCosmicServiceDetails(limit, sortByKey, sortDesc);

    if (summary && query.data) {
        query.data.max = summary.max;
        query.data.min = summary.min;
        query.data.total = summary.total;
        query.data.rowCount = summary.rowCount;
    }

    const [isLoading, setLoading] = useState(query.isLoading);
    const [data, setData] = useState<ICosmicServiceDetailReport<ICosmicServiceDetails>>(query.data);

    useEffect(() => {
        if (!query.isLoading) {
            setData(query.data as ICosmicServiceDetailReport<ICosmicServiceDetails>);
        }
        setLoading(query.isLoading);
    }, [query.isLoading, query.data]);

    return {
        isLoading,
        isLoadingMore: isLoading && limit != CosmicServiceDetailsTableDefaultPageSize,
        hasMore: data && data.data ? (data.data.length < data.rowCount) : false,
        data,
        isInitialLoading: isLoading && limit == CosmicServiceDetailsTableDefaultPageSize,

        paging: {
            pageSize: CosmicServiceDetailsTableDefaultPageSize,
            loadMore: () => setLimit(limit + CosmicServiceDetailsTableDefaultPageSize),
        },
        sort: { setSort, initialSortKey: defaultSortKey, initialSortDesc: sortDesc },
    };
}
