// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".gwTTbhHElG_tbrGfUsAh {\n  max-width: 500px;\n  white-space: nowrap;\n  overflow-x: hidden;\n  text-overflow: ellipsis;\n  color: #0078D4;\n  font-size: 14px;\n  cursor: pointer;\n}\n.gwTTbhHElG_tbrGfUsAh:hover {\n  text-decoration: underline;\n}\n#NTTE0HNNJQ73DEhLNAgD {\n  visibility: hidden;\n}\n#qrBOCHWKZnq__IRdMH3n:hover ~ #NTTE0HNNJQ73DEhLNAgD {\n  visibility: visible;\n}\n.sSPpTJdJVfdhp1LEuIvL {\n  cursor: pointer;\n  font-size: 14px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/InsightPlatform/InsightPortal/AnomalyPivotTableUtils.less"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,mBAAA;EACA,kBAAA;EACA,uBAAA;EACA,cAAA;EACA,eAAA;EACA,eAAA;AACJ;AAAI;EACI,0BAAA;AAER;AAEA;EACI,kBAAA;AAAJ;AAEA;EACI,mBAAA;AAAJ;AAGA;EAEI,eAAA;EACA,eAAA;AAFJ","sourcesContent":[".nameColumn {\n    max-width: 500px;\n    white-space: nowrap;\n    overflow-x: hidden;\n    text-overflow: ellipsis;\n    color: #0078D4;\n    font-size: 14px;\n    cursor: pointer;\n    &:hover {\n        text-decoration: underline;\n    }\n}\n\n#onhover{\n    visibility: hidden;\n}\n#name:hover ~ #onhover{\n    visibility: visible;\n}\n\n.insightMetricCellContent\n{\n    cursor: pointer;\n    font-size: 14px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nameColumn": "gwTTbhHElG_tbrGfUsAh",
	"onhover": "NTTE0HNNJQ73DEhLNAgD",
	"name": "qrBOCHWKZnq__IRdMH3n",
	"insightMetricCellContent": "sSPpTJdJVfdhp1LEuIvL"
};
export default ___CSS_LOADER_EXPORT___;
