import React from "react";
import commonStyles from "../../substrateV2/Substrate.less";
import { DonutChart } from "../../common/DonutChart";
import CommonConstants from "../../../models/constants/CommonConstants";
import { PlatformTypeEnum, IInsightUsageBO } from "../../../models/InsightsPlatformModels";
import InsightPortalCostCard from "./InsightPortalCostCard";
import AnomalyPivotTable from "./AnomalyPivotTable";
import { useInsightId } from "./useInsightId";
import { useBoolean } from "@fluentui/react-hooks";
import InsightSidePanel from "./InsightSidePanel";
import { LogComponent, LogElement, LogTarget } from "../../../models/LogModel";
import { trackEventCallback } from "../../../utils/AppInsights";

export interface IInsightPortalByAnomaliesProps {
    anomalyUsageSummaryData: Record<PlatformTypeEnum, number>;
    anomalyDetailList: IInsightUsageBO[];
    anomalyTotalCost: number;
}

export const InsightPortalByAnomalies: React.FunctionComponent<IInsightPortalByAnomaliesProps> = (props) =>  {

    const [insightId, setInsightId] = useInsightId();

    const [isInsightsCardOpen, { setTrue: openInsightsCard, setFalse: dismissInsightsCard }] = useBoolean(false);

    const showInsightCards = React.useCallback((insightId: string) => {
    trackEventCallback(LogComponent.InsightTable, LogElement.InsightExpandSidePanel, `Expand Insight Side Panel: ${insightId}`, LogTarget.Title);    
    setInsightId(insightId);
    openInsightsCard();
    }, [setInsightId, openInsightsCard]);

    const handleDismissInsightCards = () => {
    dismissInsightsCard();
    setInsightId("");
}; 

    const anomalyCostCardData = React.useMemo(
        () =>
            Object.entries(props.anomalyUsageSummaryData).map(([key, value], index) => ({
                name: key || "",
                y: value || 0,
                color: CommonConstants.DefaultColors[index],
            }))
            .filter((item) => item.y > 0),
        [props.anomalyUsageSummaryData]
    );
    
    return (
        <div style={{marginTop:48, marginLeft:48, marginRight:48}}>
            <div className={`${commonStyles.gridWrapper} ${commonStyles.gridColumn2}`}>
                <InsightPortalCostCard data={props.anomalyUsageSummaryData} />
                <DonutChart
                    {...{
                        data: anomalyCostCardData,
                    }}
                />
                { insightId !== "" && <InsightSidePanel isPanelOpen={isInsightsCardOpen} onDismiss={function (): void {
                    handleDismissInsightCards();
                } } insightId={insightId}></InsightSidePanel>}
            </div>
            <AnomalyPivotTable data={props.anomalyDetailList} totalCost={props.anomalyTotalCost} showInsightCard={showInsightCards}/>
        </div>
    );
};