import { AnomalyTotalCostTypeEnum, useGetIOInsights } from '../../../hooks/useSubstrateV2Query';
import { ActionButton, DefaultButton, IIconProps, Icon, IconButton, MessageBar, MessageBarType, Panel, PanelType, Pivot, PivotItem, PrimaryButton } from "@fluentui/react";
import { Pages, SubPages } from '../../../models/Nav';
import React, {useEffect, useState} from 'react';
import moment, { Moment } from 'moment';

import { AbnormalIssue } from '../../../models/IOInsightsModels';
import { IOAnomalyCostInsightsTrend } from './InsightsPanelByDimension';
import { IOAnomalyCostTrend } from './InsightsPanelSummary';
import { PcmV2SceneTypeEnum } from '../../../models/PcmV2Models';
import commonStyles from "../../common/styles/Common.less";
import styles from "./InsightsDetails.less";
import { useGotoTab } from '../../../hooks/useGotoPage';
import { postFormDataMethod } from '../../../utils/apiServiceBase';
import { useMsal } from '@azure/msal-react';
import { useFlights } from '../../../hooks/useSettings';
import { trackEventCallback } from '../../../utils/AppInsights';
import { LogComponent, LogElement, LogTarget } from '../../../models/LogModel';
import { SubstrateApplicationBaseUrl } from '../../../services/SubstrateV2Service';

const thumbUpIconProps: IIconProps = { iconName: "Like" };
const thumbDownIconProps: IIconProps = { iconName: "Dislike" };
const closeIconProps: IIconProps = { iconName: "Cancel" };

interface IInsightsDetailsProps {
    isPanelOpen: boolean;
    onDismiss: () => void;
    externalId: string;
    serviceName: string;
    workloadName: string;
    readCost: number;
    writeCost: number;
    queryCost: number;
}

const panelWidthSetting: Record<string, string> = {
    ['Init']: '550px',
    ['OpenEdit']: '1000px',
};

let statusTag = '';
let startDate: Moment;
let endDate : Moment;
let effectedPCMMetrics = '';
let ring = '';

const InsightsDetails: React.FC<IInsightsDetailsProps> = (props) => {
    const {data: flights} = useFlights();
    const { instance } = useMsal();
    const account = instance.getActiveAccount();
    
    const query = useGetIOInsights(PcmV2SceneTypeEnum.Transaction);
    const { onDismiss } = props;

    const [panelWidth, setpanelWidth] = useState<string>(panelWidthSetting.Init);
    const [requestState, setRequestState] = useState<string>('Please wait...');
    const [showRequestModal, setShowRequestModal] = useState(false);

    const [isPositive, setIsPositive] = useState<boolean>(true);
    const [content, setContent] = useState<string>('');
    const [contentError, setContentError] = useState<boolean>(false);
    const [messageType, setMessageType] = useState<MessageBarType>(MessageBarType.info);

    const handleContentChange = (event: React.FormEvent<HTMLTextAreaElement>) => {
        const newValue = event.currentTarget.value; 

        setContent(newValue);
        setContentError(false);
    };

    useEffect(() => {
        setShowRequestModal(false);
        setRequestState('Please wait...');
        setMessageType(MessageBarType.info);
    }, [content]);

    const handleClose = () => {
        setpanelWidth(panelWidthSetting.Init);
        setIsClosed(true);
        setRequestState('Please wait...');
        setMessageType(MessageBarType.info);
        setShowRequestModal(false);
        onDismiss();
        setShowMore(false);
        setShowMoreSummary(false);
        setContent('');
        setContentError(false);
        setIsPositive(true);
        setShowInputContainer(false);
    }

    const handleCancel = () => {
        handleClose();
        setIsClosed(true);
        setRequestState('Please wait...');
        setMessageType(MessageBarType.info);
        setShowMore(false);
        setShowMoreSummary(false);
        setContent('');
        setContentError(false);
        setIsPositive(true);
        setShowInputContainer(false);
    };

    const handleThumbsUp = () => {
        setIsPositive(true);
        setShowInputContainer(false)
        const formData = new FormData();
        formData.append('ExternalID', String(props.externalId));
        formData.append('IsPositive', String(true));
        formData.append('UserEmail', String(account?.username));
        formData.append('ContactAllowed', String(false));
        formData.append('SubPage', String('IO Anomaly'));
        formData.append('Workload', String(props.serviceName + " - " + props.workloadName));
        formData.append('StartDate', String(moment(startDate).format("MM/DD/YYYY")));
        formData.append('EndDate', String(moment(endDate).format("MM/DD/YYYY")));
        formData.append('Ring', String(ring))

        postFormDataMethod(`${SubstrateApplicationBaseUrl}/insights/setiofeedback`, formData)
            .then((response) => {
                if (response.ok) {
                    setRequestState('Thanks! We will use your feedback to help improve JAWS insights.');
                    setMessageType(MessageBarType.success);
                } else {
                    setRequestState('Error when uploading feedback, please retry or contact us through email.');
                    setMessageType(MessageBarType.error);              
                }
            })
            .catch(() => {
                setRequestState('Error when uploading feedback, please retry or contact us through email.');
                setMessageType(MessageBarType.error);
            });
        setShowRequestModal(true);
        setIsClosed(false);
        trackEventCallback(LogComponent.IOInsights, LogElement.LikedInsight, "Liked Insight " + props.externalId, LogTarget.Button);
    };

    const handleSubmit = () => {
        if (showRequestModal === true) {
            alert('Please do not send the same feedback.');
            return;
        }
        setShowRequestModal(false);

        if (content === '') {
            return;
        }

        const contactAllowedCheckbox = document.getElementById("contactAllowedCheckbox") as HTMLInputElement;
        const contactAllowed = contactAllowedCheckbox?.checked;

        const formData = new FormData();
        formData.append('ExternalID', String(props.externalId));
        formData.append('FeedbackContent', content);
        formData.append('IsPositive', String(isPositive));
        formData.append('UserEmail', String(account?.username));
        formData.append('ContactAllowed', String(contactAllowed));
        formData.append('SubPage', String("IO Anomaly"));
        formData.append('Workload', String(props.serviceName + " - " + props.workloadName));
        formData.append('StartDate', String(moment(startDate).format("MM/DD/YYYY")));
        formData.append('EndDate', String(moment(endDate).format("MM/DD/YYYY")));
        formData.append('Ring', String(ring))

        postFormDataMethod(`${SubstrateApplicationBaseUrl}/insights/setiofeedback`, formData)
            .then((response) => {
                if (response.ok) {
                    setRequestState('Thanks! We will use your feedback to help improve JAWS insights.');
                    setMessageType(MessageBarType.success);
                } else {
                    setRequestState('Error when uploading feedback, please retry or contact us through email.');
                    setMessageType(MessageBarType.error);              
                }
            })
            .catch(() => {
                setRequestState('Error when uploading feedback, please retry or contact us through email.');
                setMessageType(MessageBarType.error);
            });
        setShowRequestModal(true);
        setIsClosed(false);
        setContent('');
        setContentError(false);
        setShowInputContainer(false);
        trackEventCallback(LogComponent.IOInsights, LogElement.DisLikedInsight, "Disliked Insight " + props.externalId, LogTarget.Button);
    };

    const onRenderFooterContent = (): JSX.Element => (
        <div>
            <PrimaryButton className={styles.panelButton} onClick={handleCancel}>Close</PrimaryButton>
        </div>
    )

    const handleLinkClick = (item?: PivotItem) => {
        trackEventCallback(LogComponent.IOInsights, item?.props.itemKey === "Insight Summary" ? LogElement.InsightSummary : LogElement.InsightDetails, item?.props.itemKey || "Insight Tab", LogTarget.Tab);
    };

    if (query.data != undefined)
    {
        for (const issue of query.data as AbnormalIssue[]) {
            if (issue.externalID == props.externalId){
                statusTag = issue.statusTag;
                startDate = issue.startTime;
                endDate = issue.endTime;
                ring = issue.ring;
                effectedPCMMetrics = issue.effectedPCMMetrics;
                break;
            }
        }
    }

    const [showMore, setShowMore] = useState(false);
    const [showMoreSummary, setShowMoreSummary] = useState(false);

    const [hideState, setHideState] = React.useState(false);
    const [hideDimState, setHideDimState] = React.useState(false);

    const costByMetric = new Map
    ([
        ['ItemReads', props.readCost],
        ['ItemWrites', props.writeCost],
        ['ItemQueries', props.queryCost]
    ]);

    const [isClosed, setIsClosed] = useState(true);
    const [showInputContainer, setShowInputContainer] = useState(false);

    return (
        <Panel
            type={PanelType.custom}
            className={styles.container}
            customWidth={panelWidth}
            headerText="Insight Details"
            headerClassName={styles.title}
            isOpen={props.isPanelOpen}
            onDismiss={handleClose}
            isBlocking={false}
            closeButtonAriaLabel="Close"
            onRenderFooterContent={onRenderFooterContent}
            isFooterAtBottom={true}
            isLightDismiss
            layerProps={{eventBubblingEnabled: true}}
        >
            <div className={styles.panelContent} >
                <div className={styles.divBlank}>
                    <div>
                        <Pivot onLinkClick={handleLinkClick} focusZoneProps={{ className: styles.pivotList }} overflowBehavior="menu">
                            <PivotItem headerText="Summary" itemKey="Insight Summary">
                                <div style={{marginTop:20, marginBottom:15}}>
                                    <table>
                                        <thead>
                                            <tr>
                                                <td><b style={{fontWeight:600}}>Status</b></td>
                                                <td><b style={{marginLeft:150, fontSize:14, fontWeight:600}}>Date</b></td>
                                            </tr>
                                            <tr>
                                                <td>{statusTag}</td>
                                                <td><span style={{marginLeft:150, fontSize:14}}>{moment(startDate).format("MM/DD/YYYY")} - {moment(endDate).format("MM/DD/YYYY")}</span></td>
                                            </tr>
                                        </thead>
                                    </table>
                                    <table style={{paddingTop: 20, paddingBottom: 20}}>
                                        <thead>
                                            <tr>
                                                <td><b style={{fontWeight:600}}>Service</b></td>
                                            </tr>
                                            <tr>
                                                <td><span style={{fontSize:13}}>{props.serviceName} - {props.workloadName}</span></td>
                                            </tr>
                                        </thead>
                                    </table>
                                </div>
                                <IOAnomalyCostTrend scene={PcmV2SceneTypeEnum.Transaction} id={props.externalId} startDate={moment(startDate).format("MM/DD/YYYY")} readCost={costByMetric.get('ItemReads') || 0} writeCost={costByMetric.get('ItemWrites') || 0} queryCost={costByMetric.get('ItemQueries') || 0} effectedMetrics={effectedPCMMetrics} showAll = {showMoreSummary} updateHideState = {setHideState} />
                                {hideState && <div className={commonStyles.link} style={{fontSize:16, float:'right'}} onClick={() => setShowMoreSummary(!showMoreSummary)}>
                                    {showMoreSummary ? "Show less" : "Show more"}
                                </div>}
                            </PivotItem>
                            <PivotItem headerText="Insight Details" itemKey="Insight Details">
                            <div style={{marginTop:20, marginBottom:15}}>
                                    <table>
                                        <thead>
                                            <tr>
                                                <td><b style={{fontWeight:600}}>Status</b></td>
                                                <td><b style={{marginLeft:150, fontSize:14, fontWeight:600}}>Date</b></td>
                                            </tr>
                                            <tr>
                                                <td>{statusTag}</td>
                                                <td><span style={{marginLeft:150, fontSize:14}}>{moment(startDate).format("MM/DD/YYYY")} - {moment(endDate).format("MM/DD/YYYY")}</span></td>
                                            </tr>
                                        </thead>
                                    </table>
                                    <table style={{paddingTop: 20, paddingBottom: 20}}>
                                        <thead>
                                            <tr>
                                                <td><b style={{fontWeight:600}}>Service</b></td>
                                            </tr>
                                            <tr>
                                                <td><span style={{fontSize:13}}>{props.serviceName} - {props.workloadName}</span></td>
                                            </tr>
                                        </thead>
                                    </table>
                                </div>
                                <IOAnomalyCostInsightsTrend scene={PcmV2SceneTypeEnum.Transaction} id={props.externalId} serviceName={props.serviceName} workloadName={props.workloadName} startDate={moment(startDate).format("MM/DD/YYYY")} showAll = {showMore} updateHideDimState = {setHideDimState}/>
                                {hideDimState &&
                                    <div className={commonStyles.link} style={{fontSize:16, float:'right'}} onClick={() => setShowMore(!showMore)}>
                                    {showMore ? "Show less" : "Show more"}
                                </div>}
                            </PivotItem>
                        </Pivot>
                    </div>
                </div>
                {flights?.enableIOAnomalyFeedback &&
                    <div>
                        Is this insight helpful? 
                        <IconButton title="Like" iconProps={thumbUpIconProps} onClick={() => handleThumbsUp()}/> 
                        <IconButton title="Dislike" iconProps={thumbDownIconProps} onClick={() => {setIsPositive(false);setShowInputContainer(true);setIsClosed(true)}}/> 
                        {
                            !isClosed && 
                            <MessageBar messageBarType={messageType} onDismiss={() => setIsClosed(true)}>
                            {requestState}
                            </MessageBar>
                        }
                    </div>
                }
                {showInputContainer && 
                    <form>
                        <div className={styles.inputContainer} style={{marginTop:25}}>
                            <div>
                                <label className={`${styles.labelWithAfter} ${contentError ? styles.error : ''}`}>Thanks for letting us know. How can we make it more useful?</label>
                                <ActionButton style={{marginLeft:7}} iconProps={closeIconProps} onClick={() => setShowInputContainer(false)}>Close</ActionButton>
                            </div>
                            <textarea
                                value={content}
                                onChange={handleContentChange}
                                placeholder="Please be as specific as you can. To protect your privacy, please don't include personal information."
                                className={styles.inputField}
                                style={{height:50, width:400}}
                                required />
                        </div>
                        <div className={styles.panelContent}>
                            <div className={styles.divBlank}></div>
                            <div className={styles.checkboxContainer}>
                                <input
                                    type="checkbox"
                                    id="contactAllowedCheckbox"
                                    defaultChecked={true} />
                                <label htmlFor="contactAllowedCheckbox">You can contact me about this feedback</label>
                            </div>
                        </div>
                        <div style={{height:20, width:200, padding:10, marginBottom:25, backgroundColor:"#F3F2F1"}}>{account?.username}</div>
                        <div>
                            <DefaultButton type='submit' style={{padding:6}} onClick={() => handleSubmit()}><Icon title="Send" iconName='Send' style={{paddingRight:6}}/>Submit</DefaultButton><span style={{margin: 6}}>When you select Submit, your feedback helps us improve JAWS.</span>
                        </div>
                    </form>
                }                    
            </div>

            <div className={styles.panelContent}>
                <div className={styles.divBlank}></div>
            </div>
        </Panel>
    );
};

export default InsightsDetails;