import { Caption1, Card, CardHeader, Text } from "@fluentui/react-components";
import { CosmicSourceTypeEnum, CosmicSourceTypeName } from '../../models/CosmicModels';
import PageHeader, { IPageHeaderProps } from '../common/PageHeader';
import { PeopleQueueFilled, PeopleTeamToolboxFilled } from "@fluentui/react-icons";
import PartnerView from "./PartnerView";
import React from 'react';
import styles from './CosmicView.less';
import { useGetBannersByTabAndDateQuery } from '../../hooks/useBannerQuery';
import { useNavigate } from 'react-router-dom';

const CosmicViewHeadersProps: Record<CosmicSourceTypeEnum, IPageHeaderProps> = {
    [CosmicSourceTypeEnum.All]: {
        title: "Cosmic L&P",
        description: "Please select the view you would like to see.",
        link: "",
        linkText: "",
    },
    [CosmicSourceTypeEnum.PartnerView]: {
        title: "Cosmic - Partner View",
        notification: "",
        description: "Below you will find details regarding Cosmic app service cores and utilization for a specified date range.",
        link: "https://o365exchange.visualstudio.com/O365%20Core/_wiki/wikis/O365%20Core.wiki/386505/Cosmic-Jaws-v2",
        linkText: "Learn More",
        tag: "PREVIEW",
    }
};

interface ICosmicViewProps {
    type: CosmicSourceTypeEnum
}

const CosmicView: React.FC<ICosmicViewProps> = (props) => {
    const { data: allBanners } = useGetBannersByTabAndDateQuery(CosmicSourceTypeName[props.type].toString());
    const navigate = useNavigate();

    return (
        <div className={styles.cosmicView}>
            <PageHeader {...CosmicViewHeadersProps[props.type]} className={styles.header} />
            <div>
                {
                    props.type === CosmicSourceTypeEnum.All &&
                    <div className={styles.cardContainer}>
                        <Card appearance="filled" className={styles.card} onClick={() => { navigate("/COSMIC/Partner") }}>
                            <CardHeader
                                header={<Text weight="semibold">Partner View</Text>}
                                description={<Caption1 className={styles.caption}>COSMIC</Caption1>}
                                image={<PeopleQueueFilled className={styles.icon} />}
                            />
                            <p className={styles.text}>
                                Details relevant to the Partner statistics and information of Cosmic.
                            </p>
                        </Card>
                    </div>
                }
            </div>
            <div>
                {
                    props.type === CosmicSourceTypeEnum.PartnerView && < PartnerView type={props.type} />
                }
            </div>

        </div>
    );
};

export default CosmicView