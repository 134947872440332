import { IFilterView, IPartialFilterViewContent, ISharedView } from "../models/FilterView";
import { ServiceTreeItem, ServiceTreeLevel } from "../models/serviceTree";

import { CategoryDivision } from "../models/CategoryDivision";
import { entries } from "lodash";
import { getCategoryByServiceTreeLevel } from "../reducers/serviceTreeReducer";

export function removeFilter(filter: Partial<Record<CategoryDivision, string[]>>, category: CategoryDivision, value: string): Partial<Record<CategoryDivision, string[]>> {
    const newFilter = { ...filter };
    if (newFilter[category]) {
        newFilter[category] = newFilter[category]?.filter(v => v !== value);

        if (newFilter[category]?.length === 0) {
            delete newFilter[category];
        }
    }
    return newFilter;
}

function getServiceCountBelow(serviceNode?: ServiceTreeItem): number {
    if (!serviceNode) { return 0; }
    if (serviceNode.l === ServiceTreeLevel.Service) {
        return 1;
    }
    return serviceNode.c?.reduce((prev, cur) => prev + getServiceCountBelow(cur), 0) || 0;
}

function getServiceCounts(serviceIdMap: Map<string, ServiceTreeItem>, serviceNodes?: string[]): number {
    return serviceNodes?.reduce((prev, cur) => prev + getServiceCountBelow(serviceIdMap.get(cur)), 0) || 0;
}

export function getServiceCount(filters: Partial<Record<CategoryDivision, string[]>>, serviceIdMap: Map<string, ServiceTreeItem>) {
    return getServiceCounts(serviceIdMap, filters.Division)
        + getServiceCounts(serviceIdMap, filters.Organization)
        + getServiceCounts(serviceIdMap, filters.TeamGroup)
        + getServiceCounts(serviceIdMap, filters.ServiceGroup)
        + getServiceCounts(serviceIdMap, filters.Service);
}

export function getCategoryFromName(serviceNodeMapByName: Map<string, ServiceTreeItem>, key: string) {
    const serviceNode = serviceNodeMapByName.get(key);
    if (!serviceNode) { return CategoryDivision.Service; }
    switch (serviceNode.l) {
        case ServiceTreeLevel.Division:
            return CategoryDivision.Division;
        case ServiceTreeLevel.Organization:
            return CategoryDivision.Organization;
        case ServiceTreeLevel.TeamGroup:
            return CategoryDivision.TeamGroup;
        case ServiceTreeLevel.ServiceGroup:
            return CategoryDivision.ServiceGroup;
        case ServiceTreeLevel.Service:
            return CategoryDivision.Service;
    }
}

export function getFilterList(filters: Partial<Record<CategoryDivision, string[]>>, serviceIdMap: Map<string, ServiceTreeItem>) {
    let serviceTreeItem: ServiceTreeItem | undefined;

    if (filters.Service?.length) {
        serviceTreeItem = serviceIdMap.get(filters.Service[0]);
    } else if (filters.ServiceGroup?.length) {
        serviceTreeItem = serviceIdMap.get(filters.ServiceGroup[0]);
    } else if (filters.TeamGroup?.length) {
        serviceTreeItem = serviceIdMap.get(filters.TeamGroup[0]);
    } else if (filters.Organization?.length) {
        serviceTreeItem = serviceIdMap.get(filters.Organization[0]);
    } else if (filters.Division?.length) {
        serviceTreeItem = serviceIdMap.get(filters.Division[0]);
    }

    const result: ServiceTreeItem[] = [];
    while (serviceTreeItem) {
        result.push(serviceTreeItem);
        serviceTreeItem = serviceTreeItem.p;
    }

    return result.reverse();
}

export function makeGlobalFiltersFromView(view: IFilterView | ISharedView) : Partial<Record<CategoryDivision, string[]>> {
    const result: Partial<Record<CategoryDivision, string[]>> = {};
    entries(view.filterContent).forEach((item) => {
        const specificFilters = item[1] as string[];
        if (specificFilters && specificFilters.length > 0) {
            for (const value of specificFilters) {
                const category = getFilterCategory(item[0], value);
                if (category) {
                    if (!result[category]) {
                        result[category] = [];
                    }

                    result[category]?.push(value);
                }
            }
        }
    });

    return result;
}

function getFilterCategory(key: string, value: string) {
    const category = getFilterCategoryByViewItemKey(key);

    if (!category) {
        return null;
    }

    if (category === CategoryDivision.GriffinApp && value.match(/\(.*\)$/)) {
        return CategoryDivision.GriffinAppV2;
    }

    return category;
}

function getFilterCategoryByViewItemKey(key: string) : CategoryDivision | undefined {
    switch (key as keyof IPartialFilterViewContent) {
        case "DivisionId":
        case "DivisionName":
            return CategoryDivision.Division;
        case "OrganizationId":
        case "OrganizationName":
            return CategoryDivision.Organization;
        case "ServiceGroupId":
        case "ServiceGroupName":
            return CategoryDivision.ServiceGroup;
        case "TeamGroupId":
        case "TeamGroupName":
            return CategoryDivision.TeamGroup;
        case "ServiceId":
        case "ServiceName":
            return CategoryDivision.Service;
        case "ServiceOwner":
            return CategoryDivision.Owner;
        case "VDir":
            return CategoryDivision.VDir;
        case "Process":
            return CategoryDivision.Process;
        case "ScenarioTag":
            return CategoryDivision.ScenarioTag;
        case "GriffinApplicationName":
        case "GriffinApplicationId":
            return CategoryDivision.GriffinApp;
        case "PredefinedViewName":
            return CategoryDivision.PredefinedViewName;
        case "Subscription":
            return CategoryDivision.Subscription;
        case "ClusterId":
            return CategoryDivision.Cluster;
        case "StoreClient":
            return CategoryDivision.StoreClient;
        case "StoreClientComponent":
            return CategoryDivision.StoreClientComponent;
        case "SSDDataSetName":
            return CategoryDivision.SSDDataSetName;
        case "GriffinProcessor":
            return CategoryDivision.GriffinProcessor;
        case "GriffinApplicationV2": {
            return CategoryDivision.GriffinAppV2;
        }
        case "GriffinProcessorV2": {
            return CategoryDivision.GriffinProcessorV2;
        }
        case "ScenarioTagV2": {
            return CategoryDivision.ScenarioTagV2;
        }
        case "StoreClientV2": {
            return CategoryDivision.StoreClientV2;
        }
        case "StoreClientComponentV2": {
            return CategoryDivision.StoreClientComponentV2;
        }
        case "ProcessV2": {
            return CategoryDivision.ProcessV2;
        }
        case "DataSetV2": {
            return CategoryDivision.DataSetV2;
        }
        case "VdirV2": {
            return CategoryDivision.VdirV2;
        }
        case "PlatformProcess":
            return CategoryDivision.PlatformProcess;
        case "PlatformApp":
            return CategoryDivision.PlatformApp;
        case "PlatformSubApp":
            return CategoryDivision.PlatformSubApp;
        case "PlatformClient":
            return CategoryDivision.PlatformClient;
        case "PlatformClientComponent":
            return CategoryDivision.PlatformClientComponent;
    }
}

export function getViewItemKeyFromFilterCategory(category: CategoryDivision) : keyof IPartialFilterViewContent {
    switch (category) {
        case CategoryDivision.Division:
            return "DivisionId";
        case CategoryDivision.Organization:
            return "OrganizationId";
        case CategoryDivision.ServiceGroup:
            return "ServiceGroupId";
        case CategoryDivision.TeamGroup:
            return "TeamGroupId";
        case CategoryDivision.Service:
            return "ServiceId";
        case CategoryDivision.Owner:
            return "ServiceOwner";
        case CategoryDivision.BusinessOwner:
            return "BusinessOwner";
        case CategoryDivision.VDir:
            return "VDir";
        case CategoryDivision.Process:
            return "Process";
        case CategoryDivision.ScenarioTag:
            return "ScenarioTag";
        case CategoryDivision.GriffinApp:
            return "GriffinApplicationName";
        case CategoryDivision.PredefinedViewName:
            return "PredefinedViewName";
        case CategoryDivision.Subscription:
            return "Subscription";
        case CategoryDivision.Cluster:
            return "ClusterId";
        case CategoryDivision.GriffinProcessor:
            return "GriffinProcessor";
        case CategoryDivision.StoreClient:
            return "StoreClient";
        case CategoryDivision.StoreClientComponent:
            return "StoreClientComponent";
        case CategoryDivision.SSDDataSetName:
            return "SSDDataSetName";
        case CategoryDivision.StoreClientComponentV2:
            return "StoreClientComponentV2";
        case CategoryDivision.StoreClientV2:
            return "StoreClientV2";
        case CategoryDivision.GriffinAppV2:
            return "GriffinApplicationV2";
        case CategoryDivision.GriffinProcessorV2:
            return "GriffinProcessorV2";
        case CategoryDivision.ScenarioTagV2:
            return "ScenarioTagV2";
        case CategoryDivision.VdirV2:
            return "VdirV2";
        case CategoryDivision.ProcessV2:
            return "ProcessV2";
        case CategoryDivision.DataSetV2:
            return "DataSetV2";
        case CategoryDivision.PlatformProcess:
            return "PlatformProcess";
        case CategoryDivision.PlatformApp:
            return "PlatformApp";
        case CategoryDivision.PlatformSubApp:
            return "PlatformSubApp";
        case CategoryDivision.PlatformClient:
            return "PlatformClient";
        case CategoryDivision.PlatformClientComponent:
            return "PlatformClientComponent";
        case CategoryDivision.Category:
            return "Category";
        case CategoryDivision.Workload:
            return "Workload";
        case CategoryDivision.Confidence:
            return "Confidence";
        case CategoryDivision.ExecutionStatus:
            return "ExecutionStatus";
        case CategoryDivision.FiscalYear:
            return "FiscalYear";
        case CategoryDivision.ServiceId:
            return "ServiceId";
        case CategoryDivision.Type:
            return "Type";
        case CategoryDivision.Submitter:
            return "Submitter";
    }
}

export function getServiceItemListByKeys(filters: Partial<Record<CategoryDivision, string[]>>, items?: ServiceTreeItem[]): ServiceTreeItem[] {
    let result: ServiceTreeItem[] = [];
    items?.forEach(item => {
        const category = getCategoryByServiceTreeLevel(item.l);
        if (filters[category]?.includes(item.id)) {
            result.push(item);
        }
        result = result.concat(getServiceItemListByKeys(filters, item.c));
    });
    return result;
}

export function buildBreadcrumbFromServiceItem(serviceItem: ServiceTreeItem): Partial<Record<CategoryDivision, string[]>> {
    const result: Partial<Record<CategoryDivision, string[]>> = {};
    let serviceNode: ServiceTreeItem | undefined = serviceItem;
    while (serviceNode != null) {
        const category = getCategoryByServiceTreeLevel(serviceNode.l);
        result[category] = [serviceNode.n];
        serviceNode = serviceNode.p;
    }
    return result;
}