import { DefaultButton, FontIcon, MessageBar, MessageBarType } from '@fluentui/react';
import React, { useState } from 'react';
import styles from './unsubscribePage.less';
import { postFormDataMethod } from '../../utils/apiServiceBase';
import { SubstrateApplicationBaseUrl } from '../../services/SubstrateV2Service';

export const UnsubscribeCard: React.FC = () => {
    const [hideConfirmedState, setHideConfirmedState] = React.useState(true);
    const [errorStatus, setErrorStatus] = React.useState(false);
    
    const [content, setContent] = useState<string>('');
    const handleContentChange = (event: React.FormEvent<HTMLTextAreaElement>) => {
        const newValue = event.currentTarget.value; 

        setContent(newValue);
    };

    const handleUnsubscribe = () => {
        const formData = new FormData();
        formData.append('reason', content);

        postFormDataMethod(`${SubstrateApplicationBaseUrl}/report/unsubscribers`, formData)
            .then((response) => {
                if (response.ok) {
                    setHideConfirmedState(false);
                }
            })
            .catch(() => {
                setErrorStatus(true);
            });
    };

    return (
        <div className={styles.cardContainer}>
            <div className= {styles.cardWrapper}>
                <div style={{margin:"20px"}}>
                    <h1>Unsubscribe from Monthly Report</h1>
                    { hideConfirmedState && 
                        <>
                        <div>
                            <div><FontIcon iconName="WarningSolid" style={{color: "#F56A00"}} /> Are you sure?</div>
                            <p>You will no longer receive Monthly COGS Reports.</p>

                            <p>Unsubscribe reason (optional): </p>
                            <textarea
                                value={content}
                                onChange={handleContentChange}
                                placeholder="Please be as specific as you can."
                                className={styles.inputField}
                                style={{ height: 50, width: 400 }} />
                        </div>
                        <div className={styles.cardFooter}>
                            <DefaultButton className={styles.unsubscribeBtn} style={{ padding: 6 }} onClick={() => handleUnsubscribe()}>
                                Unsubscribe
                            </DefaultButton>
                            { errorStatus &&
                                <MessageBar messageBarType={MessageBarType.error}>
                                    Error occured, please retry or contact us through email.
                                </MessageBar>
                            }
                        </div>
                        </>
                    }
                    { !hideConfirmedState && 
                        <>
                        <div>
                            <div><FontIcon iconName="CompletedSolid" style={{color: "#107C10"}} /> Confirmed</div>
                            <p>You have successfully unsubscribed from Monthly COGS Reports. If you took this action by mistake or wish to re-subscribe to Monthly COGS report emails, please reach out to the Jaws(Sage) team at <a href="mailto:jawswork@microsoft.com">jawswork@microsoft.com</a></p>
                        </div>
                        </>
                    }
                </div>
            </div>
        </div>
    );
};