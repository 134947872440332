import { DefaultPcmV2Cost, PcmV2SceneCostMetric, PcmV2SubSceneCostMetric } from "../models/constants/PcmV2Constants";
import { PCMv2UnitPrice, PcmV2AggeratedLevel, PcmV2Response, PcmV2ResponseType, PcmV2SceneTypeEnum, PcmV2SubSceneTypeEnum, PcmV2WorkloadType, SubstrateCarbonEmissionData, SubstrateCarbonReponse } from "../models/PcmV2Models";
import { SubstrateCostTableColumnNames, SubstrateCostTableSceneColumnIds, SubstrateCostTableSubSceneColumnIds } from "../components/substrateV2/common/table/SubstrateDetailTableConfig";
import { getJson, postJson } from "../utils/apiServiceBase";
import { isStorageSubScene, parseScenarioTag, transformPcmV2Response } from "../utils/PcmV2Utils";

import { AbnormalIssue } from "../models/IOInsightsModels";
import { Flight } from "../models/Flights";
import { IFiltersPostJsonData } from "../models/FilterView";
import { MemoryRedundancySummary } from "../models/MemoryRedundancyModels";
import { Moment } from "moment";
import { QueryableClass } from "../hooks/useCustomQuery";
import { ServiceTreeItem } from "../models/serviceTree";
import { exportToCsv } from "../utils/common";
import qs from "query-string";

export const SubstrateApplicationBaseUrl = 'api/v2.0/substrateapplication';

// cache time: 5 mins
@QueryableClass({ cacheTime: 1800000, retry: 3 })
export class SubstrateV2Service {
    static async getPCMV2Cost(filters: IFiltersPostJsonData, startDate: Moment, endDate: Moment, rings: string[], enabled?: boolean) {
        if (!enabled) {
            return DefaultPcmV2Cost;
        }

        const url = `${SubstrateApplicationBaseUrl}/substrateoverview?${qs.stringify({
            startDate: startDate.format("YYYY-MM-DD"),
            endDate: endDate.format("YYYY-MM-DD"),
            rings,
        })}`;

        return transformPcmV2Response(PcmV2ResponseType.Entity, await postJson<PcmV2Response>(url, filters));
    }

    static async getPCMV2Unitprice() {
        const url = `${SubstrateApplicationBaseUrl}/unitprice`
        const response = await getJson<PCMv2UnitPrice>(url);

        return response;
    }

    static async getPCMV2CostByRing(filters: IFiltersPostJsonData, startDate: Moment, endDate: Moment, rings: string[]) {
        const url = `${SubstrateApplicationBaseUrl}/substratedetail/ring?${qs.stringify({
            startDate: startDate.format("YYYY-MM-DD"),
            endDate: endDate.format("YYYY-MM-DD"),
            rings,
        })}`;

        return transformPcmV2Response(PcmV2ResponseType.Dictionary, await postJson<Record<string, PcmV2Response[]>>(url, filters));
    }

    static async getPCMV2CostByCategory(filters: IFiltersPostJsonData, startDate: Moment, endDate: Moment, rings: string[]) {
        const url = `${SubstrateApplicationBaseUrl}/costtrendbycategory?${qs.stringify({
            startDate: startDate.format("YYYY-MM-DD"),
            endDate: endDate.format("YYYY-MM-DD"),
            rings,
        })}`;

        return transformPcmV2Response(PcmV2ResponseType.Dictionary, await postJson<Record<string, PcmV2Response[]>>(url, filters));
    }

    static async getPCMV2MonthlyCostByCategory(filters: IFiltersPostJsonData, startDate: Moment, endDate: Moment, rings: string[]) {
        const url = `${SubstrateApplicationBaseUrl}/costtrend/category/monthly?${qs.stringify({
            startDate: startDate.format("YYYY-MM-DD"),
            endDate: endDate.format("YYYY-MM-DD"),
            rings,
        })}`;

        return transformPcmV2Response(PcmV2ResponseType.Dictionary, await postJson<Record<string, PcmV2Response[]>>(url, filters));
    }

    static async getPCMV2CostByService(filters: IFiltersPostJsonData, startDate: Moment, endDate: Moment, rings: string[]) {
        const url = `${SubstrateApplicationBaseUrl}/substratedetail/service?${qs.stringify({
            startDate: startDate.format("YYYY-MM-DD"),
            endDate: endDate.format("YYYY-MM-DD"),
            rings,
        })}`;

        return transformPcmV2Response(PcmV2ResponseType.List, await postJson<PcmV2Response[]>(url, filters));
    }

    static async getTopPcmV2CostTrendByWorkload(filters: IFiltersPostJsonData, startDate: Moment, endDate: Moment, rings: string[], scene: PcmV2SceneTypeEnum, subScene?: PcmV2SubSceneTypeEnum) {
        const url = `${SubstrateApplicationBaseUrl}/topcosttrendbyworkload?${qs.stringify({
            startDate: startDate.format("YYYY-MM-DD"),
            endDate: endDate.format("YYYY-MM-DD"),
            rings,
            scene,
            subScene,
            top: 10,
        })}`;

        return transformPcmV2Response(PcmV2ResponseType.Dictionary, await postJson<Record<string, PcmV2Response[]>>(url, filters));
    }

    static async getTopPcmV2CostTrendByService(filters: IFiltersPostJsonData, startDate: Moment, endDate: Moment, rings: string[], scene: PcmV2SceneTypeEnum, subScene: PcmV2SubSceneTypeEnum) {
        const url = `${SubstrateApplicationBaseUrl}/topcosttrendbyservice?${qs.stringify({
            startDate: startDate.format("YYYY-MM-DD"),
            endDate: endDate.format("YYYY-MM-DD"),
            rings,
            scene,
            subScene,
            top: 10,
        })}`;

        return transformPcmV2Response(PcmV2ResponseType.Dictionary, await postJson<Record<string, PcmV2Response[]>>(url, filters));
    }

    static async getPCMV2CostByWorkload(filters: IFiltersPostJsonData, startDate: Moment, endDate: Moment, rings: string[], targetServiceId: string) {
        const url = `${SubstrateApplicationBaseUrl}/substratedetail/workload?${qs.stringify({
            startDate: startDate.format("YYYY-MM-DD"),
            endDate: endDate.format("YYYY-MM-DD"),
            rings,
            targetServiceId
        })}`;

        return transformPcmV2Response(PcmV2ResponseType.List, await postJson<PcmV2Response[]>(url, filters));
    }

    static async getPCMV2TransactionCostByService(filters: IFiltersPostJsonData, startDate: Moment, endDate: Moment, rings: string[]) {
        const url = `${SubstrateApplicationBaseUrl}/transactiondetail/services?${qs.stringify({
            startDate: startDate.format("YYYY-MM-DD"),
            endDate: endDate.format("YYYY-MM-DD"),
            rings,
        })}`;

        return transformPcmV2Response(PcmV2ResponseType.List, await postJson<PcmV2Response[]>(url, filters));
    }

    static async getTransactionScenarioTagCost(filters: IFiltersPostJsonData, startDate: Moment, endDate: Moment, rings: string[], targetServiceId: string) {
        const url = `${SubstrateApplicationBaseUrl}/transactiondetail/scenariotags?${qs.stringify({
            startDate: startDate.format("YYYY-MM-DD"),
            endDate: endDate.format("YYYY-MM-DD"),
            rings,
            targetServiceId
        })}`;

        const result = transformPcmV2Response(PcmV2ResponseType.List, await postJson<PcmV2Response[]>(url, filters));

        result.forEach(pcmV2 => {
            pcmV2.identity.aggregatedLevel = PcmV2AggeratedLevel.Workload;
            pcmV2.identity.workloadType = PcmV2WorkloadType.ScenarioTag;
        });
        return result;
    }

    static async getPCMV2NetworkCostByService(filters: IFiltersPostJsonData, startDate: Moment, endDate: Moment, rings: string[]) {
        const url = `${SubstrateApplicationBaseUrl}/networkdetail/services?${qs.stringify({
            startDate: startDate.format("YYYY-MM-DD"),
            endDate: endDate.format("YYYY-MM-DD"),
            rings,
        })}`;

        return transformPcmV2Response(PcmV2ResponseType.List, await postJson<PcmV2Response[]>(url, filters));
    }

    static async getNetworkScenarioTagCost(filters: IFiltersPostJsonData, startDate: Moment, endDate: Moment, rings: string[], targetServiceId: string) {
        const url = `${SubstrateApplicationBaseUrl}/networkdetail/scenariotags?${qs.stringify({
            startDate: startDate.format("YYYY-MM-DD"),
            endDate: endDate.format("YYYY-MM-DD"),
            rings,
            targetServiceId
        })}`;

        const result = transformPcmV2Response(PcmV2ResponseType.List, await postJson<PcmV2Response[]>(url, filters));

        result.forEach(pcmV2 => {
            pcmV2.identity.aggregatedLevel = PcmV2AggeratedLevel.Workload;
            pcmV2.identity.workloadType = PcmV2WorkloadType.ScenarioTag;
        });
        return result;
    }

    static async getAggregatedStorageCost(filters: IFiltersPostJsonData, startDate: Moment, endDate: Moment, rings: string[], scene: PcmV2SceneTypeEnum, subScene?: PcmV2SubSceneTypeEnum) {
        if (!isStorageSubScene(subScene)) {
            return [];
        }

        const url = `${SubstrateApplicationBaseUrl}/storage/cost/aggregated?${qs.stringify({
            startDate: startDate.format("YYYY-MM-DD"),
            endDate: endDate.format("YYYY-MM-DD"),
            rings,
            scene,
            subScene,
        })}`;


        return transformPcmV2Response(PcmV2ResponseType.List, await postJson<PcmV2Response[]>(url, filters));
    }

    static async getSubStorageCost(filters: IFiltersPostJsonData, startDate: Moment, endDate: Moment, rings: string[], scene: PcmV2SceneTypeEnum, subScene: PcmV2SubSceneTypeEnum|undefined, targetComponent: string) {
        if (!isStorageSubScene(subScene)) {
            return [];
        }

        const url = `${SubstrateApplicationBaseUrl}/storage/cost/sublist?${qs.stringify({
            startDate: startDate.format("YYYY-MM-DD"),
            endDate: endDate.format("YYYY-MM-DD"),
            rings,
            scene,
            subScene,
            targetComponent,
        })}`;

        return transformPcmV2Response(PcmV2ResponseType.List, await postJson<PcmV2Response[]>(url, filters));
    }

    static async getCarbonEmission(filters: IFiltersPostJsonData, startDate: Moment, endDate: Moment, scene: PcmV2SceneTypeEnum, enabled = true) {
        if (scene !== PcmV2SceneTypeEnum.Overview || !enabled) {
            return null;
        }

        const url = `api/v1.0/Substrate/GetSubstrateCarbonEmission?${qs.stringify({
            startDate: startDate.format("YYYY-MM-DD"),
            endDate: endDate.format("YYYY-MM-DD"),
        })}`;

        return postJson<SubstrateCarbonReponse>(url, filters);
    }

    static async getIOInsights(filters: IFiltersPostJsonData, startDate: Moment, endDate: Moment, rings: string[], scene: PcmV2SceneTypeEnum) {
        if (scene !== PcmV2SceneTypeEnum.Transaction) {
            return [];
        }

        const url = `${SubstrateApplicationBaseUrl}/insights/issues?${qs.stringify({
            startDate: startDate.format("YYYY-MM-DD"),
            endDate: endDate.format("YYYY-MM-DD"),
            rings: rings,
        })}`;

        return await postJson<AbnormalIssue[]>(url, filters);
    }

    static async getMemoryRedundancyData(filters: IFiltersPostJsonData, startDate: Moment, endDate: Moment, rings: string[], scene: PcmV2SceneTypeEnum) {
        if (scene !== PcmV2SceneTypeEnum.ProcessHosting) {
            return [];
        }

        const url = `${SubstrateApplicationBaseUrl}/memoryredundancy?${qs.stringify({
            startDate: startDate.format("YYYY-MM-DD"),
            endDate: endDate.format("YYYY-MM-DD"),
            rings: rings,
        })}`;

        return await postJson<MemoryRedundancySummary[]>(url, filters);
    }


    static async download(
        extraData: {
            appIdNameMap: Map<string, string>;
            appIdServiceMap: Map<string, ServiceTreeItem>;
            carbonEmissionByGriffinApp?: Record<string, SubstrateCarbonEmissionData>;
            serviceIdMap: Map<string, ServiceTreeItem>;
            flights?: Flight;
        },
        filters: IFiltersPostJsonData,
        startDate: Moment,
        endDate: Moment,
        rings: string[],
        scene: PcmV2SceneTypeEnum,
        subScene?: PcmV2SubSceneTypeEnum
    ) {
        const isDatasetView = subScene === PcmV2SubSceneTypeEnum.HDDDatasetCost || subScene === PcmV2SubSceneTypeEnum.SSDDatasetCost;
        const isMailboxCategoryView = subScene === PcmV2SubSceneTypeEnum.HDDMailboxCost || subScene === PcmV2SubSceneTypeEnum.SSDMailboxCost;
        const isScenarioTagView = subScene === PcmV2SubSceneTypeEnum.TransactionScenarioTagCost || subScene === PcmV2SubSceneTypeEnum.NetworkScenarioTagCost;

        const totalDays = endDate.diff(startDate, "days") + 1;

        const filename = `${scene}_${subScene || "TotalCost"}_${startDate.format("YYYY-MM-DD")}_${endDate.format("YYYY-MM-DD")}.csv`;
        const columns: string[] = (subScene ? SubstrateCostTableSubSceneColumnIds[subScene] : SubstrateCostTableSceneColumnIds[scene]).slice();

        const costKey = (subScene && PcmV2SubSceneCostMetric[subScene]) || PcmV2SceneCostMetric[scene];

        const data: Record<any, any>[] = transformPcmV2Response(
            PcmV2ResponseType.List,
            await postJson<PcmV2Response[]>(
                `${SubstrateApplicationBaseUrl}/download?${qs.stringify({
                    startDate: startDate.format("YYYY-MM-DD"),
                    endDate: endDate.format("YYYY-MM-DD"),
                    scene: isDatasetView || isMailboxCategoryView || isScenarioTagView ? scene : PcmV2SceneTypeEnum.Overview,
                    subScene: isDatasetView || isMailboxCategoryView || isScenarioTagView ? subScene : undefined,
                    rings,
                })}`,
                filters
            )
        ).filter((item) => item[costKey] > 0);

        data.forEach((item) => {

            if (isDatasetView || isMailboxCategoryView) {
                // add workload data in json format.
                const workloadData = JSON.parse(item.identity.workload);
                item.identity.workloadData = {
                    dataset: workloadData.DatasetName,
                    mailboxCategory: workloadData.MailboxCategory,
                };
            }
        });

        if (isDatasetView) {
            this.sortDownloadDataList(data, costKey, (item) => item.identity.workloadData.dataset);
        } else if (isMailboxCategoryView) {
            this.sortDownloadDataList(data, costKey, (item) => item.identity.workloadData.mailboxCategory);
        } else {
            columns.splice(1, 0, "workload"); // add workload column after identity column.

            this.sortDownloadDataList(data, costKey, (item) => item.identity.identity);
        }

        if (columns.indexOf("scenarioTags") >= 0) {
            columns.splice(columns.indexOf("scenarioTags"), 1);
        }

        exportToCsv(data, filename, columns, SubstrateCostTableColumnNames, (key, value, row) => {
            if (key === "identity") {
                return extraData.serviceIdMap.get(row.identity.identity)?.n || row.identity.identity;
            } else if (key === "workload") {
                if (row.identity.workloadType === PcmV2WorkloadType.ScenarioTag) {
                    return parseScenarioTag(row.identity.workload)[1];
                }

                return row.identity.workload;
            } else if (key === "griffinApps" && row.identity.workloadType === PcmV2WorkloadType.ScenarioTag) {
                const griffinAppId = row.identity.workload.split(".")[0];

                return extraData.appIdNameMap.get(griffinAppId) || griffinAppId;
            } else if (key === "dataset" || key === "mailboxCategory") {
                return row.identity.workloadData[key];
            } else if (key === "pmOwner") {
                return extraData.serviceIdMap.get(row.identity.identity)?.PmOwner || "";
            } else if (key === "devOwner") {
                return extraData.serviceIdMap.get(row.identity.identity)?.o || "";
            } else if (key === "hddUsage" || key === "ssdUsage" || key === "ssdKvCacheUsage" || key === "ssdmcdbUsage") {
                return value / totalDays;
            }

            return value?.toString() || "";
        });
    }

    // sort download data list by aggregated level cost.
    private static sortDownloadDataList(
        downloadDataList: Record<any, any>[],
        costKey: string,
        getAggregatedLevelIdentifier: (item: Record<any, any>) => string
    ) {
        const aggregatedLevelCost: Record<string, number> = {};

        for (const item of downloadDataList) {
            aggregatedLevelCost[getAggregatedLevelIdentifier(item)] = (aggregatedLevelCost[getAggregatedLevelIdentifier(item)] || 0) + item[costKey];
        }

        downloadDataList.sort((a, b) => {
            if (getAggregatedLevelIdentifier(a) == getAggregatedLevelIdentifier(b)) {
                return b[costKey] - a[costKey];
            }

            return aggregatedLevelCost[getAggregatedLevelIdentifier(b)] - aggregatedLevelCost[getAggregatedLevelIdentifier(a)];
        });
    }
}
