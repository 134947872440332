import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { SearchKey } from "../../../models/SearchKey";

export function useInsightId(): [string, (insightId?: string) => void] {
    const [searchParams, setSearchParams] = useSearchParams();

    const insightId: string = useMemo(() => {
        const insightId = searchParams.get(SearchKey.InsightId);
        return insightId || "";
    }, [searchParams]);

    const setInsightId = useCallback((insightId?: string) => {
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.set(SearchKey.InsightId, insightId || "");
        setSearchParams(newSearchParams);
    }, [searchParams, setSearchParams]);

    return [insightId, setInsightId];
}
