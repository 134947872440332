// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".b8XAEGp2n3EcJ9_xe9de {\n  margin-top: 20px;\n}\n.QvtLHPREmCWajV_SCeVA {\n  border-radius: 12px;\n  background-color: #F0F0F0;\n  height: 24px;\n  border: none;\n}\n.A6HYTjS8NWBkpXvn4r5Q {\n  display: block;\n  width: 160px;\n  border: none;\n}\n.A6HYTjS8NWBkpXvn4r5Q .ms-Button-flexContainer {\n  justify-content: start;\n}\n", "",{"version":3,"sources":["webpack://./src/components/InsightPlatform/InsightPortal/AnomalyDetailsTrend.less"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ;AAEA;EACI,mBAAA;EACA,yBAAA;EACA,YAAA;EACA,YAAA;AAAJ;AAGA;EACI,cAAA;EACA,YAAA;EACA,YAAA;AADJ;AAFA;EAMQ,sBAAA;AADR","sourcesContent":[".PivotByContainer {\n    margin-top: 20px;\n}\n\n.pivotButton {\n    border-radius: 12px;\n    background-color: #F0F0F0;\n    height: 24px;\n    border: none;\n}\n\n.listItem {\n    display: block;\n    width: 160px;\n    border: none;\n\n    :global(.ms-Button-flexContainer) {\n        justify-content: start;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PivotByContainer": "b8XAEGp2n3EcJ9_xe9de",
	"pivotButton": "QvtLHPREmCWajV_SCeVA",
	"listItem": "A6HYTjS8NWBkpXvn4r5Q"
};
export default ___CSS_LOADER_EXPORT___;
