import { Moment } from "moment";
import { QueryableClass } from "../hooks/useCustomQuery";
import { IFiltersPostJsonData } from "../models/FilterView";
import { IAnomalyData, IInsightBaseInfo, IInsightUsage, ITSG, InsightTypeEnum } from "../models/InsightsPlatformModels";
import {  getJson, postJson } from "../utils/apiServiceBase";
import qs from "qs";

// cache time: 5 mins
@QueryableClass({ cacheTime: 1800000, retry: 3 })
export class InsightPlatformService {
    static async getInsightsPortalData(filters: IFiltersPostJsonData, startDate: Moment, endDate: Moment) {
        const url = `api/v1.0/insightPlatform/insightPortalDetail?${qs.stringify({
            startDate: startDate.format("YYYY-MM-DD"),
            endDate: endDate.format("YYYY-MM-DD"),
        })}`;

        return await postJson<Record<InsightTypeEnum, IAnomalyData>>(url, filters);
    }

    static async getBaseInfoByInsightId(insightId: string) {
        const url = `api/v1.0/insightPlatform/baseInfo?${qs.stringify({
            insightId: insightId,
        })}`;

        return await getJson<IInsightBaseInfo>(url);
    }

    static async getRecommendationByInsightId(insightId: string) {
        const url = `api/v1.0/insightPlatform/recommendation?${qs.stringify({
            insightId: insightId,
        })}`;

        return await getJson<ITSG>(url);
    }

    static async getRCAByInsightId(insightId: string) {
        const url = `api/v1.0/insightPlatform/insightExtraInfo?${qs.stringify({
            insightId: insightId,
        })}`;

        return await getJson<string[]>(url);
    }

    static async getUsageTrendByInsightId(insightId: string) {
        const url = `api/v1.0/insightPlatform/insightUsage?${qs.stringify({
            insightId: insightId,
        })}`;

        return await getJson<IInsightUsage>(url);
    }

    static async getInsightsOverviewData(filters: IFiltersPostJsonData, startDate: Moment, endDate: Moment) {
        const url = `api/v1.0/insightPlatform/insightoverview?${qs.stringify({
            startDate: startDate.format("YYYY-MM-DD"),
            endDate: endDate.format("YYYY-MM-DD"),
        })}`;

        return await postJson<Record<InsightTypeEnum, IAnomalyData>>(url, filters);
    }
}