import React from "react";
import { Callout, DefaultButton } from "@fluentui/react";
import styles from "./AnomalyDetailsTrend.less";
import { BreakdownTypeEnum, IInsightUsage } from "../../../models/InsightsPlatformModels";
import { LineChartSeries } from "../../common/LineChart";
import CommonConstants from "../../../models/constants/CommonConstants";
import ChartContainer from "../../chart/ChartContainer";
import { CostCardProps } from "../../common/costCard/CostCard";
import { getMedian } from "../../../utils/ChartUtils";
import { DataSourceEnum, INotification } from "../../../models/NotificationModels";
import moment from "moment";
import { useBoolean, useId } from "@fluentui/react-hooks";
import { LogComponent, LogElement, LogTarget } from "../../../models/LogModel";
import { useTrackHovering } from "../../../hooks/useTrack";
import { trackEventCallback } from "../../../utils/AppInsights";

export function getBreakdownPivotString(pivotEnum: BreakdownTypeEnum): string {
    switch (pivotEnum) {
        case BreakdownTypeEnum.Resources:
            return "Resources";
        case BreakdownTypeEnum.Cost:
            return "Cost";
    }
}

const BreakdownTypePivotList = [
    BreakdownTypeEnum.Resources,
    BreakdownTypeEnum.Cost,
];

export interface IAnomalyDetailsTrendProps {
    insightUsage: IInsightUsage;
}

export const IAnomalyDetailsTrend: React.FunctionComponent<IAnomalyDetailsTrendProps> = (props) => {
    const trackingProps = useTrackHovering(LogComponent.InsightCard, LogElement.InsightTrendCard, "Insight trend card", LogTarget.Chart);

    const startDate = moment(props.insightUsage.insightStartTime);

    const [isCalloutVisible, { toggle: toggleIsCalloutVisible, setFalse: dismissCallout }] = useBoolean(false);
    const filterButtonId = useId('filterButton');
    const [pivot, setPivot] = React.useState<BreakdownTypeEnum>(BreakdownTypeEnum.Cost);

    const chartCards: CostCardProps[] | undefined = [];

    const costTrend:[number, (number | undefined)?][] = props.insightUsage.usage.map((unit) => [moment(unit.timeStamp).valueOf(), unit.currentCost]);
    costTrend.sort((a, b) => a[0] - b[0]);
    const seriesInCost: LineChartSeries = {
        color: CommonConstants.DefaultColors[0],
        name: "Pre Anomlay Cost",
        data: props.insightUsage.usage && costTrend,
    };
    
    const usageTrend:[number, (number | undefined)?][] = props.insightUsage.usage.map((unit) => [moment(unit.timeStamp).valueOf(), unit.currentUsage]);
    usageTrend.sort((a, b) => a[0] - b[0]);
    const seriesInUsage: LineChartSeries = {
        color: CommonConstants.DefaultColors[0],
        name: "Pre Anomlay Usage",
        data: props.insightUsage.usage && usageTrend,
    };

    const baseLineUsageTrend:[number, (number | undefined)?][] = props.insightUsage.usage.map((unit) => [moment(unit.timeStamp).valueOf(), unit.baselineUsage]);
    baseLineUsageTrend.sort((a, b) => a[0] - b[0]);
    const baseLineSeriesInUsage: LineChartSeries = {
        color: CommonConstants.MedianColor,
        name: "Median Usage",
        dashStyle: "Dash",
        disableAnomaly: true,
        data: props.insightUsage.usage && baseLineUsageTrend,
    };

    const baseLineCostTrend:[number, (number | undefined)?][] = props.insightUsage.usage.map((unit) => [moment(unit.timeStamp).valueOf(), unit.baselineCost]);
    baseLineCostTrend.sort((a, b) => a[0] - b[0]);
    const baseLineSeriesInCost: LineChartSeries = {
        color: CommonConstants.MedianColor,
        name: "Median Cost",
        dashStyle: "Dash",
        disableAnomaly: true,
        data: props.insightUsage.usage && baseLineCostTrend,
    };

    const baseLineCost = getMedian(baseLineCostTrend);
    const baselineUsage = getMedian(baseLineUsageTrend);

    // Get the latest date.
    const latestTimestamp = Math.max.apply(null, props.insightUsage.usage.map(entry => +moment(entry.timeStamp)));
    const latestDate = moment(latestTimestamp);

    const anomaliesContent: INotification[] = [];
    const anomaly: INotification = { 
        startDate : startDate,
        endDate: moment(latestDate),
        detectionDate: startDate,
        category: "Data abnormal",
        status: "Resolving",
        affectedMetrics: pivot === BreakdownTypeEnum.Cost ? ["Cost"] : ["Usage"],
        dataSourceName: DataSourceEnum.SubstrateV2,
    }

    anomaliesContent.push(anomaly);

    const chartSeries = pivot === BreakdownTypeEnum.Cost ? seriesInCost : seriesInUsage;
    const baseLineSeries = pivot === BreakdownTypeEnum.Cost ? baseLineSeriesInCost : baseLineSeriesInUsage;
    const medianValue = pivot === BreakdownTypeEnum.Cost ? baseLineCost : baselineUsage;

    return (

        <div style={{marginBottom:48}}>
            <div className={styles.PivotByContainer}>
                <span><strong>Breakdown By: </strong></span>
                <DefaultButton
                    className={styles.pivotButton}
                    id={filterButtonId}
                    onClick={toggleIsCalloutVisible}>
                    <strong>{getBreakdownPivotString(pivot)}</strong>
                </DefaultButton>
                {
                    isCalloutVisible && (
                        <Callout
                            gapSpace={5}
                            target={`#${filterButtonId}`}
                            onDismiss={dismissCallout}
                        >
                            {
                                BreakdownTypePivotList.map(pivotEnum => (
                                    <DefaultButton
                                        className={styles.listItem}
                                        key={pivotEnum}
                                        onClick={() => {
                                            trackEventCallback(LogComponent.InsightCard, LogElement.InsightBreakdownPivot, `Insight Breakdown Pivot Set To: ${pivotEnum}`, LogTarget.Title);
                                            setPivot(pivotEnum);
                                            toggleIsCalloutVisible();
                                        }}
                                    >
                                        {pivot === pivotEnum ? "✔" : "\u00a0\u00a0\u00a0"}
                                        &nbsp;{getBreakdownPivotString(pivotEnum)}
                                    </DefaultButton>
                                ))
                            }
                        </Callout>
                    )
                }
            </div>
            <ChartContainer
                cards={chartCards}
                headerProps={{ title: pivot + " Increase Since " + startDate.format("MM/DD/YYYY") }}
                chartType="line"
                chartProps={{
                    series: [chartSeries, baseLineSeries],
                    height: 220,
                    hideEmptySeries: true,
                    anomalies: anomaliesContent,
                    containerProps: trackingProps,
                    isIOInsightsChart: true,
                    deviationType: "Median",
                    medianValue: medianValue,
                }}
                summaryDescriptionText={"We detected an increase in your workload since " + startDate.format("MM/DD/YYYY") + " . To investigate, please follow the recommendations below."}
                isSummaryInsightsChart={true}
            />
        </div>
    );
}
