import moment from "moment";

export interface IFinOpsCogs {
    category: string;
    type: string;
    lastYearCost: number;
    lastYearQuantity: number;
    unit: string;
}

export interface IFinOpsBudget extends IFinOpsCogs {
    id: number;
    fiscalYear: string;
    serviceId: string;
    increaseRate: number;
    quantityBudget: number;
    costBudget: number;
    submitter?: string;
    version?: moment.Moment;
    approver?: string;
    approved?: number;
    approvedTime?: moment.Moment;
    justification?: string;
    comment?: string;
    children?: IFinOpsBudget[];
}

export interface IFinOpsBudgetFilters {
    fiscalYear?: string;
    serviceId?: string;
    serviceGM?: string;
    category?: string;
    type?: string;
    submitter?: string;
}

export enum FinOpsReviewStatus {
    Pending,
    Denied,
    Approved
}

export enum FinOpsBudgetFiltersEnum {
    FiscalYear = "FiscalYear",
    ServiceId = "ServiceId",
    Category = "Category",
    Type = "Type",
    Submitter = "Submitter"
}