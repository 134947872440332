import { ActionButton, Stack, TooltipHost } from '@fluentui/react';
import { CosmicPartnerServiceDetailsColumnNames, CosmicPartnerServiceDetailsColumnNames_AppCorev2, ICosmicServiceDetails } from '../../models/CosmicModels';
import { CosmicServiceDetailsTableOffset, CosmicServiceDetailsTableStyles } from './CosmicServiceDetailTableConstants';
import React, { useCallback, useEffect } from 'react';
import { getCellOption, getFooterOption, getHeaderOption, useCosmicDetailTable, useCosmicServiceDetailReportParams, useCosmicServiceDetailReportQuery, useDetailTableColumnSelection } from './PartnerServiceDetailTableUtils';

import { ColumnSelector } from '../common/table/ColumnSelector';
import {CosmicService} from "../../services/cosmicService";
import { SectionHeader } from '../common/SectionHeader';
import StickyTable from '../common/table/StickyTable';
import commonStyles from './PartnerServiceDetailTable.less';
import { difference } from 'lodash';
import { useBoolean } from "@fluentui/react-hooks";
import { useDownloadCosmicServiceDetails } from '../../hooks/useCosmicQuery';
import { useFlights } from '../../hooks/useSettings';
import { useRemap } from '../../hooks/useRemap';
import { useUserPreference } from '../../utils/preference/useUserPreference';

interface IPartnerServiceDetailTableProps {
    defaultSorting?: string;
}

const PartnerServiceDetailTable: React.FC<IPartnerServiceDetailTableProps> = ({ defaultSorting }) => {
    const { data: flights } = useFlights();
    const columns = flights?.enableCosmicAppCoreV2Flight ? CosmicPartnerServiceDetailsColumnNames_AppCorev2: CosmicPartnerServiceDetailsColumnNames;
    const heatmapParams = useCosmicServiceDetailReportParams(defaultSorting);
    const query = useCosmicServiceDetailReportQuery(heatmapParams);

    const [preferedTableConfig, setPreferedTableConfig] = useUserPreference("cosmicServiceDetail");
    const [isDownloadingEnabled, {setFalse: disableDownloading, setTrue: enableDownloading}] = useBoolean(true);
    const downloadCosmicServiceDetailsParams = useDownloadCosmicServiceDetails();

    const tableInstance = useCosmicDetailTable(
        columns,
        query.data,
        query.sort.setSort,
        query.sort.initialSortDesc,
        query.sort.initialSortKey || (flights?.enableCosmicAppCoreV2Flight ? "AppCores": "CpuRequestCores"),
        preferedTableConfig?.hiddenColumns || []
    );
    const { visibleColumns, changeVisibleColumns, columnSelectorOptions } = useDetailTableColumnSelection(tableInstance);

        
    const handleDownload = useCallback(async () => {
        disableDownloading();
        
        const response = await CosmicService.downloadCosmicServiceDetails(
            downloadCosmicServiceDetailsParams.startDate,
            downloadCosmicServiceDetailsParams.endDate,
            downloadCosmicServiceDetailsParams.cosmicSearchFilters,
            downloadCosmicServiceDetailsParams.filters,
            downloadCosmicServiceDetailsParams.serviceIdMap
        );

        const data = response.data;
        const url = window.URL.createObjectURL(data);
        const a = document.createElement('a');
        a.href = url;
        a.download = response.fileName;
        a.click();
        window.URL.revokeObjectURL(url);
        
        enableDownloading();
    }, [disableDownloading, enableDownloading, downloadCosmicServiceDetailsParams]);

    useEffect(() => {
        setPreferedTableConfig({ hiddenColumns: difference(columns, visibleColumns) as (keyof ICosmicServiceDetails)[] });
    }, [visibleColumns]);

    const [] = useRemap();

    return (
        <div className={commonStyles.detailTableView} style={{ margin: '44px 0 0' }}>
            <SectionHeader
                title="Daily Cosmic Service Details"
                extra={(
                    <Stack horizontal verticalAlign="center" horizontalAlign="end" className={commonStyles.headerExtra}>
                        <TooltipHost content="Downloading data across multiple dates may take a few minutes. Please wait.">
                            <ActionButton onClick={handleDownload} iconProps={{ iconName: 'Download' }} text={isDownloadingEnabled ? "Download All" : "Downloading"} disabled={!isDownloadingEnabled} />
                        </TooltipHost>
                        <ColumnSelector onConfirm={changeVisibleColumns} columns={columnSelectorOptions} initSelectedKeys={visibleColumns} />
                    </Stack>
                )}
            />
            <StickyTable
                styles={CosmicServiceDetailsTableStyles}
                cellOption={getCellOption}
                headerOption={getHeaderOption}
                footerOption={getFooterOption}
                loading={query.isInitialLoading}
                loadMoreText="Show more services"
                emptyText="The selected services don't have data"
                loadMoreLoadingText={`Loading more ${query.paging.pageSize} services...`}
                loadMoreLoading={query.isLoadingMore}
                loadMore={query.hasMore}
                onLoadMore={query.paging.loadMore}
                table={tableInstance}
                stickyTarget="#pageContainer"
                stickyPositon={CosmicServiceDetailsTableOffset}
            />
        </div>
    );
};

PartnerServiceDetailTable.defaultProps = {
    defaultSorting: "CpuRequestCores",
}

export default PartnerServiceDetailTable;
