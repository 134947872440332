import * as React from "react";

import { CategoryDivision, getCategoryString } from "../../models/CategoryDivision";
import { Checkbox, DefaultButton, IRectangle, IStackTokens, List, Panel, PanelType, Pivot, PivotItem, PrimaryButton, Stack, Text } from "@fluentui/react";
import { FiltersAction, FiltersView } from "../../reducers/filterReducer";
import { ISearchData, searchByKey } from "../../reducers/searchReducer";
import { ISearchPanelData, makeTogglePanelAction } from "../../reducers/searchPanelReducer";
import { LogComponent, LogElement, LogTarget } from "../../models/LogModel";
import { delay, min, throttle, values } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import { ActionTypes } from "../../actions/ActionTypes";
import { IAppState } from "../../store";
import { ISearchRichResultItem } from "../../reducers/SearchUtils";
import { IServiceTreeData } from "../../reducers/serviceTreeReducer";
import { escapeRegex } from "../../reducers/stringUtils";
import { getServiceTreeBreadcrumb } from "../../utils/ServiceTreeUtils";
import { parseScenarioTag } from "../../utils/PcmV2Utils";
import { sleep } from "../../utils/common";
import styles from "./SearchResultPanel.less";
import { trackEventCallback } from "../../utils/AppInsights";
import { useCategoryFilters } from "../../hooks/useFilters";
import { useGetAppIdNameMap } from "../../hooks/useSearchMetadataQuery";
import { useState } from "react";

const listCellStackTokens: IStackTokens = {
    childrenGap: 12
};

const checkboxTitleSplitter = "@@";

function isElementInViewport (element?: HTMLDivElement | null, viewport?: HTMLDivElement | null): boolean {
    if (!element || !viewport) return false;
    const rect = element.getBoundingClientRect();
    const viewportRect = viewport.getBoundingClientRect();

    return (viewportRect.top > rect.top && viewportRect.top < rect.bottom) ||
        (rect.top < viewportRect.top && rect.bottom > viewportRect.bottom);
}

const SearchResultPanel: React.FC = () => {
    const dispatch = useDispatch();
    const serviceTree = useSelector<IAppState, IServiceTreeData>(state => state.serviceTree);
    const searchPanelData = useSelector<IAppState, ISearchPanelData>(state => state.searchPanel);
    const searchData = useSelector<IAppState, ISearchData>(state => state.search);

    const searchResultCategories = new Array<CategoryDivision>()
                                    .concat(searchData.serviceTreeSearchResult?.map(item => item[0]) || [])
                                    .concat(searchData.tagSearchResultDescription?.map(item => item[0]) || [])

    const globalFiltersData = useCategoryFilters();
    const filtersNextAction = useSelector<IAppState, FiltersAction>(state => state.filtersNextAction);
    const [openingPivot, setOpeningPivot] = useState(searchPanelData.defaultCategory || searchResultCategories[0]);
    const isScrollingToTargetCategory = React.useRef(false);
    const appIdNameMap = useGetAppIdNameMap();

    const ownerFilter = useState<string[]>([]);
    const businessOwnerFilter = useState<string[]>([]);
    const serviceFilter = useState<string[]>([]);
    const serviceGroupFilter = useState<string[]>([]);
    const divisionFilter = useState<string[]>([]);
    const organizationFilter = useState<string[]>([]);
    const teamGroupFilter = useState<string[]>([]);
    const vDirFilter = useState<string[]>([]);
    const processFilter = useState<string[]>([]);
    const predefinedViewNameFilter = useState<string[]>([]);
    const subscriptionFilter = useState<string[]>([]);
    const clusterFilter = useState<string[]>([]);
    const ssdDataSetNameFilter = useState<string[]>([]);

    /** PCMv1 */
    const griffinAppFilter = useState<string[]>([]);
    const griffinProcessorFilter = useState<string[]>([]);
    const storeClientFilter = useState<string[]>([]);
    const storeClientComponentFilter = useState<string[]>([]);
    const scenarioTagFilter = useState<string[]>([]);

    /** PCMv2 */
    const griffinAppV2Filter = useState<string[]>([]);
    const griffinProcessorV2Filter = useState<string[]>([]);
    const storeClientV2Filter = useState<string[]>([]);
    const storeClientComponentV2Filter = useState<string[]>([]);
    const scenarioTagV2Filter = useState<string[]>([]);
    const vdirV2Filter = useState<string[]>([]);
    const processV2Filter = useState<string[]>([]);
    const datasetV2Filter = useState<string[]>([]);

    /** Platform */
    const platformServiceFilter = useState<string[]>([]);
    const platformProcessFilter = useState<string[]>([]);
    const platformAppFilter = useState<string[]>([]);
    const platformSubAppFilter = useState<string[]>([]);
    const platformClientFilter = useState<string[]>([]);
    const platformClientComponentFilter = useState<string[]>([]);

    /** Efficiency Tracker Big Bets search */
    const EFTrackerBigBetsCategoryFilter = useState<string[]>([]);
    const EFTrackerBigBetsWorkloadFilter = useState<string[]>([]);
    const EFTrackerBigBetsConfidenceFilter = useState<string[]>([]);
    const EFTrackerBigBetsExecutionStatusFilter = useState<string[]>([]);

    /** FinOps filter */
    const FinOpsFiscalYearFilter = useState<string[]>([]);
    const FinOpsServiceIdFilter = useState<string[]>([]);
    const FinOpsTypeFilter = useState<string[]>([]);
    const FinOpsSubmitterFilter = useState<string[]>([]);

    const localFiltersData: {[category in CategoryDivision]: [string[], React.Dispatch<React.SetStateAction<string[]>>]} = React.useMemo(() => ({
        [CategoryDivision.Owner]: ownerFilter,
        [CategoryDivision.BusinessOwner]: businessOwnerFilter,
        [CategoryDivision.Service]: serviceFilter,
        [CategoryDivision.ServiceGroup]: serviceGroupFilter,
        [CategoryDivision.Division]: divisionFilter,
        [CategoryDivision.Organization]: organizationFilter,
        [CategoryDivision.TeamGroup]: teamGroupFilter,
        [CategoryDivision.VDir]: vDirFilter,
        [CategoryDivision.Process]: processFilter,
        [CategoryDivision.ScenarioTag]: scenarioTagFilter,
        [CategoryDivision.GriffinApp]: griffinAppFilter,
        [CategoryDivision.GriffinProcessor]: griffinProcessorFilter,
        [CategoryDivision.PredefinedViewName]: predefinedViewNameFilter,
        [CategoryDivision.Subscription]: subscriptionFilter,
        [CategoryDivision.Cluster]: clusterFilter,
        [CategoryDivision.StoreClient]: storeClientFilter,
        [CategoryDivision.StoreClientComponent]: storeClientComponentFilter,
        [CategoryDivision.SSDDataSetName]: ssdDataSetNameFilter,

        /** V2 */
        [CategoryDivision.GriffinAppV2]: griffinAppV2Filter,
        [CategoryDivision.GriffinProcessorV2]: griffinProcessorV2Filter,
        [CategoryDivision.StoreClientComponentV2]: storeClientComponentV2Filter,
        [CategoryDivision.StoreClientV2]: storeClientV2Filter,
        [CategoryDivision.ScenarioTagV2]: scenarioTagV2Filter,
        [CategoryDivision.VdirV2]: vdirV2Filter,
        [CategoryDivision.ProcessV2]: processV2Filter,
        [CategoryDivision.DataSetV2]: datasetV2Filter,

        /** Platform */
        [CategoryDivision.PlatformProcess]: platformProcessFilter,
        [CategoryDivision.PlatformApp]: platformAppFilter,
        [CategoryDivision.PlatformSubApp]: platformSubAppFilter,
        [CategoryDivision.PlatformClient]: platformClientFilter,
        [CategoryDivision.PlatformClientComponent]: platformClientComponentFilter,

        /** Efficiency Tracker Big Bets search */
        [CategoryDivision.Category]: EFTrackerBigBetsCategoryFilter,
        [CategoryDivision.Workload]: EFTrackerBigBetsWorkloadFilter,
        [CategoryDivision.Confidence]: EFTrackerBigBetsConfidenceFilter,
        [CategoryDivision.ExecutionStatus]: EFTrackerBigBetsExecutionStatusFilter,

        /** FinOps filter */
        [CategoryDivision.FiscalYear]: FinOpsFiscalYearFilter,
        [CategoryDivision.ServiceId]: FinOpsServiceIdFilter,
        [CategoryDivision.Type]: FinOpsTypeFilter,
        [CategoryDivision.Submitter]: FinOpsSubmitterFilter,

    }), [ownerFilter, businessOwnerFilter, serviceFilter, serviceGroupFilter, divisionFilter, organizationFilter, teamGroupFilter, vDirFilter, processFilter, scenarioTagFilter, griffinAppFilter, griffinProcessorFilter, predefinedViewNameFilter, subscriptionFilter, clusterFilter, storeClientFilter, storeClientComponentFilter, ssdDataSetNameFilter, griffinAppV2Filter, griffinProcessorV2Filter, storeClientComponentV2Filter, storeClientV2Filter, scenarioTagV2Filter, vdirV2Filter, processV2Filter, datasetV2Filter, platformServiceFilter, platformProcessFilter, platformAppFilter, platformSubAppFilter, platformClientFilter, platformClientComponentFilter, EFTrackerBigBetsCategoryFilter, EFTrackerBigBetsWorkloadFilter, EFTrackerBigBetsConfidenceFilter, EFTrackerBigBetsExecutionStatusFilter, FinOpsFiscalYearFilter, FinOpsServiceIdFilter, FinOpsTypeFilter, FinOpsSubmitterFilter]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const listRefs: {[category in CategoryDivision]: React.RefObject<HTMLDivElement>} = {
        [CategoryDivision.Owner]: React.useRef<HTMLDivElement>(null),
        [CategoryDivision.BusinessOwner]: React.useRef<HTMLDivElement>(null),
        [CategoryDivision.Service]: React.useRef<HTMLDivElement>(null),
        [CategoryDivision.ServiceGroup]: React.useRef<HTMLDivElement>(null),
        [CategoryDivision.Division]: React.useRef<HTMLDivElement>(null),
        [CategoryDivision.Organization]: React.useRef<HTMLDivElement>(null),
        [CategoryDivision.TeamGroup]: React.useRef<HTMLDivElement>(null),
        [CategoryDivision.VDir]: React.useRef<HTMLDivElement>(null),
        [CategoryDivision.Process]: React.useRef<HTMLDivElement>(null),
        [CategoryDivision.ScenarioTag]: React.useRef<HTMLDivElement>(null),
        [CategoryDivision.GriffinApp]: React.useRef<HTMLDivElement>(null),
        [CategoryDivision.GriffinProcessor]: React.useRef<HTMLDivElement>(null),
        [CategoryDivision.PredefinedViewName]: React.useRef<HTMLDivElement>(null),
        [CategoryDivision.Subscription]: React.useRef<HTMLDivElement>(null),
        [CategoryDivision.Cluster]: React.useRef<HTMLDivElement>(null),
        [CategoryDivision.StoreClient]: React.useRef<HTMLDivElement>(null),
        [CategoryDivision.StoreClientComponent]: React.useRef<HTMLDivElement>(null),
        [CategoryDivision.SSDDataSetName]: React.useRef<HTMLDivElement>(null),
        /** V2 */
        [CategoryDivision.GriffinAppV2]: React.useRef<HTMLDivElement>(null),
        [CategoryDivision.GriffinProcessorV2]: React.useRef<HTMLDivElement>(null),
        [CategoryDivision.StoreClientComponentV2]: React.useRef<HTMLDivElement>(null),
        [CategoryDivision.StoreClientV2]: React.useRef<HTMLDivElement>(null),
        [CategoryDivision.ScenarioTagV2]: React.useRef<HTMLDivElement>(null),
        [CategoryDivision.VdirV2]: React.useRef<HTMLDivElement>(null),
        [CategoryDivision.ProcessV2]: React.useRef<HTMLDivElement>(null),
        [CategoryDivision.DataSetV2]: React.useRef<HTMLDivElement>(null),
        /** Platform */
        [CategoryDivision.PlatformProcess]: React.useRef<HTMLDivElement>(null),
        [CategoryDivision.PlatformApp]: React.useRef<HTMLDivElement>(null),
        [CategoryDivision.PlatformSubApp]: React.useRef<HTMLDivElement>(null),
        [CategoryDivision.PlatformClient]: React.useRef<HTMLDivElement>(null),
        [CategoryDivision.PlatformClientComponent]: React.useRef<HTMLDivElement>(null),
        /** Efficiency Tracker Big Bets search */
        [CategoryDivision.Category]: React.useRef<HTMLDivElement>(null),
        [CategoryDivision.Workload]: React.useRef<HTMLDivElement>(null),
        [CategoryDivision.Confidence]: React.useRef<HTMLDivElement>(null),
        [CategoryDivision.ExecutionStatus]: React.useRef<HTMLDivElement>(null),
        /** FinOps filter */
        [CategoryDivision.FiscalYear]: React.useRef<HTMLDivElement>(null),
        [CategoryDivision.ServiceId]: React.useRef<HTMLDivElement>(null),
        [CategoryDivision.Type]: React.useRef<HTMLDivElement>(null),
        [CategoryDivision.Submitter]: React.useRef<HTMLDivElement>(null),
    }

    const scrollRef = React.useRef<HTMLDivElement>(null);
    const tagSearchResultDescription = (searchData.tagSearchResultDescription || []).filter(item => item[1].length);
    const totalCount = (tagSearchResultDescription.reduce((prevValue, current) => prevValue + current[1].length, 0) || 0) + (searchData.serviceTreeSearchResult?.length || 0);
    const selectedCount = values(localFiltersData).reduce((prev, current) => prev + current[0].length, 0);

    const onSelectionChange = React.useCallback((ev?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean): void => {
        const title = ev?.currentTarget.title.split(checkboxTitleSplitter);
        if (!title) {
            return;
        }

        const targetCategory = title[0] as CategoryDivision;
        const filter = title[1];

        const [filters, setFiltersFunc] = localFiltersData[targetCategory];

        if (setFiltersFunc && filters) {
            if (isChecked) setFiltersFunc([... filters, filter]);
            else setFiltersFunc(filters.filter(value => value !== filter));
        }
    }, [localFiltersData]);

    const onRenderListCell = React.useCallback((item?: ISearchRichResultItem): JSX.Element => {
        if (!item) {
            return <></>;
        }

        let title = item?.title;
        const id = item?.id;
        let selected = false;

        if (id) {
            selected = localFiltersData[item?.type][0].includes(id);
        }

        if (item.type === CategoryDivision.ScenarioTagV2 || item.type === CategoryDivision.GriffinProcessorV2) {
            const [appId, scenarioTagName] = parseScenarioTag(title);
            const appName = appIdNameMap.get(appId) || appId;

            title = `${scenarioTagName}${appName ? `(${appName})` : ""}`;
        }

        const words = searchData.searchKey?.trim().split(/\s+/).filter(w => w.length > 0) || [];
        const pattern = new RegExp(`(${words.map(w => escapeRegex(w)).join('|')})`, "ig");
        const content = title?.replaceAll(pattern, '<span style="font-weight:bold;">$1</span>') || '';

        return (
            <Stack horizontal tokens={listCellStackTokens}>
                <div className={styles.checkBox}><Checkbox title={`${item?.type}${checkboxTitleSplitter}${id}`} onChange={onSelectionChange} checked={selected} /></div>
                <Stack>
                    <span
                        className={styles.cellTitle}
                        dangerouslySetInnerHTML={{__html: content}}></span>
                    <Text variant="smallPlus" block className={styles.description} title={item?.description}>{item?.description}</Text>
                </Stack>
            </Stack>
        );
    }, [localFiltersData, onSelectionChange, searchData.searchKey, appIdNameMap]);

    const scrollToCategory = async (category: CategoryDivision) => {
        isScrollingToTargetCategory.current = true;

        await sleep(100);
        listRefs[category].current?.scrollIntoView();

        await sleep(100);
        isScrollingToTargetCategory.current = false;
    }

    const onPivotChange = async (item?: PivotItem) => {
        if (item) {
            const category = item.props.itemKey as CategoryDivision;
            setOpeningPivot(category);
            scrollToCategory(category);

            trackEventCallback(LogComponent.RightSidePane, LogElement.Category, category, LogTarget.Tab);
        }
    }

    const onUpdateFilters = (): void => {
        globalFiltersData.updateFilters(filtersNextAction, {filters: {
            Owner: localFiltersData[CategoryDivision.Owner][0],
            Service: localFiltersData[CategoryDivision.Service][0],
            ServiceGroup: localFiltersData[CategoryDivision.ServiceGroup][0],
            VDir: localFiltersData[CategoryDivision.VDir][0],
            Process: localFiltersData[CategoryDivision.Process][0],
            ScenarioTag: localFiltersData[CategoryDivision.ScenarioTag][0],
            GriffinApp: localFiltersData[CategoryDivision.GriffinApp][0],
            GriffinProcessor: localFiltersData[CategoryDivision.GriffinProcessor][0],
            Subscription: localFiltersData[CategoryDivision.Subscription][0].map(subscriptionTitle => subscriptionTitle.split("(")[0]),
            Cluster: localFiltersData.Cluster[0],
            StoreClient: localFiltersData[CategoryDivision.StoreClient][0],
            StoreClientComponent: localFiltersData[CategoryDivision.StoreClientComponent][0],
            SSDDataSetName: localFiltersData[CategoryDivision.SSDDataSetName][0],
            PredefinedViewName: localFiltersData[CategoryDivision.PredefinedViewName][0],

            GriffinAppV2: localFiltersData[CategoryDivision.GriffinAppV2][0],
            GriffinProcessorV2: localFiltersData[CategoryDivision.GriffinProcessorV2][0],
            StoreClientComponentV2: localFiltersData[CategoryDivision.StoreClientComponentV2][0],
            StoreClientV2: localFiltersData[CategoryDivision.StoreClientV2][0],
            ScenarioTagV2: localFiltersData[CategoryDivision.ScenarioTagV2][0],
            VdirV2: localFiltersData[CategoryDivision.VdirV2][0],
            ProcessV2: localFiltersData[CategoryDivision.ProcessV2][0],
            DataSetV2: localFiltersData[CategoryDivision.DataSetV2][0],
        }, view: FiltersView.AddableList});
        onDismissPanel();
        trackEventCallback(LogComponent.RightSidePane, LogElement.ApplySelection, "Apply", LogTarget.Button);
    }

    const onDismissPanel = React.useCallback((): void => {
        dispatch(makeTogglePanelAction(false, openingPivot));
        if (serviceTree.serviceTree)
            searchByKey(dispatch, "", serviceTree.serviceTree, serviceTree.vdirs, serviceTree.processes,
                serviceTree.scenarioTags, serviceTree.griffinApps,
                serviceTree.griffinProcessors, serviceTree.owners,
                serviceTree.storeClients, serviceTree.storeClientComponents, serviceTree.ssdDataSetNames, serviceTree.indexMap,
                serviceTree.griffinAppV2,
                serviceTree.griffinProcessorV2,
                serviceTree.storeClientComponentV2,
                serviceTree.storeClientV2,
                serviceTree.scenarioTagV2,
                serviceTree.vdirV2,
                serviceTree.processV2,
                serviceTree.datasetV2);
        if (filtersNextAction === FiltersAction.Add) {
            dispatch({type: ActionTypes.UpdateSearchFiltersNextAction, nextAction: FiltersAction.Replace});
        }
    }, [dispatch, filtersNextAction, openingPivot, serviceTree.griffinApps, serviceTree.griffinProcessors, serviceTree.indexMap, serviceTree.owners, serviceTree.processes, serviceTree.scenarioTags, serviceTree.serviceTree, serviceTree.ssdDataSetNames, serviceTree.storeClientComponents, serviceTree.storeClients, serviceTree.vdirs]);

    const onCancel = (): void => {
        onDismissPanel();
        trackEventCallback(LogComponent.RightSidePane, LogElement.CancelSelection, "Cancel", LogTarget.Button);
    };

    const onRenderPanelFooter = (): JSX.Element => (
        <div className={styles.footer}>
            <PrimaryButton onClick={onUpdateFilters} style={{marginRight: 8}}>Apply ({selectedCount})</PrimaryButton>
            <DefaultButton onClick={onCancel}>Cancel</DefaultButton>
        </div>
    )

    const onToggleCheckAllBox = (event?: React.FormEvent<HTMLElement>, checked?: boolean) => {
        tagSearchResultDescription.forEach(description => {
            const setFiltersFunc = localFiltersData[description[0]][1];
            setFiltersFunc && setFiltersFunc(checked ? description[1].map(item => item.title): []);
        });
        searchData.serviceTreeSearchResult?.forEach(services => {
            const setFiltersFunc = localFiltersData[services[0]][1];
            setFiltersFunc && setFiltersFunc(checked ? services[1].map(service => service.id): []);
        })
        trackEventCallback(LogComponent.RightSidePane, LogElement.All, "All", LogTarget.Other);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onScroll = React.useCallback(throttle(() => {
        if (isScrollingToTargetCategory.current) {
            return;
        }

        for(const category of searchResultCategories) {
            const ref = listRefs[category as CategoryDivision];

            if (isElementInViewport(ref.current, scrollRef.current)) {
                setOpeningPivot(category as CategoryDivision);
                return;
            }
        }
    }, 100), []);

    React.useEffect(() => {
        if (searchPanelData.defaultCategory) {
            scrollToCategory(searchPanelData.defaultCategory);
        }
    }, []);

    if (!searchData.searchKey) {
        return null;
    }

    return (
        <Panel
            isBlocking={false}
            isOpen={true}
            headerText={`${totalCount} ${totalCount === 1 ? "result" : "results"} for "${searchData.searchKey}"`}
            onRenderFooter={onRenderPanelFooter}
            isFooterAtBottom
            type={PanelType.medium}
            styles={{scrollableContent: styles.panelScrollableContent, commands: styles.panelCommand, content: styles.panelContent, main: styles.panelMain}}
            onDismiss={onDismissPanel}
        >
            <Pivot
                selectedKey={openingPivot}
                onLinkClick={onPivotChange}
                className={styles.pivot}
                overflowBehavior={'menu'}
            >
                {
                    searchData.serviceTreeSearchResult?.map(services => {
                        const category = services[0];
                        return <PivotItem
                            key={category}
                            headerText={getCategoryString(category)}
                            itemKey={category}
                            itemCount={services[1].length}
                            />;
                    })
                }
                {
                    tagSearchResultDescription.map((item) => (
                        <PivotItem
                            key={item[0]}
                            headerText={getCategoryString(item[0])}
                            itemKey={item[0]}
                            itemCount={item[1].length}
                        />
                    ))
                }
            </Pivot>
            <Checkbox
                className={styles.selectAllBox}
                label="All"
                indeterminate={selectedCount !== totalCount && selectedCount > 0}
                checked={selectedCount === totalCount}
                onChange={onToggleCheckAllBox}
            />
            <div className={styles.pivotItemContainer} data-is-scrollable={true} onScroll={onScroll} ref={scrollRef}>
                {
                    searchData.serviceTreeSearchResult?.map(services => {
                        const category = services[0];
                        const nodes = services[1];

                        return (
                            <div ref={listRefs[category]} key={category}>
                                <div className={styles.listHeader}>{`${getCategoryString(category)} (${nodes.length})`}</div>
                                <List
                                    items={nodes.map(node => ({title: node.n, description: getServiceTreeBreadcrumb(node), type: category, id: node.id}))}
                                    onRenderCell={onRenderListCell}
                                />
                            </div>
                        )
                    })
                }
                {
                    tagSearchResultDescription.map(description => {
                        const category = description[0];
                        const innerItems = description[1];

                        return (
                            <div ref={listRefs[category]} key={category}>
                                <div className={styles.listHeader}>{`${getCategoryString(category)} (${innerItems.length})`}</div>
                                <List
                                    items={innerItems.map(lowerItem => ({...lowerItem, type: category, id: lowerItem.title}))}
                                    onRenderCell={onRenderListCell}
                                />
                                <div className={styles.listFooter} />
                            </div>
                        )
                    })
                }
            </div>
        </Panel>
    )
};

export default SearchResultPanel;