export const enum Pages {
    Substrate = "/Substrate",
    SubstrateV2 = "/SubstrateV2",
    SubstratePlatform = "/SubstratePlatform",
    Azure = "/Azure",
    ESCost = "/ESCost",
    ESCostCloudBuild = `CloudBuild`,
    ESCostCloudBuildGEMs = `CBUsersInfra`,
    ESCostCloudBuildUsageScenarios = `CBUsageScenarios`,
    ESCostArtifact = `Artifact`,
    ESCostArtifactDetails = `ADetails`,
    ESCostAzure = `Azure`,
    ESCostAzureDetails = `AzureDetails`,
    LandingPage = "/LandingPage",
    CogsCalculator = "/CogsCalculator",

    /*EfficiencyTracker*/
    EfficiencyTracker = "/EfficiencyTracker",
    EfficiencyTrackerV2 = "/EfficiencyTrackerV2",
    MyProjects = "MyProjects",
    AllProjects = "AllProjects",

    BaremetalTracker = "Baremetal",
    VirtualizedTracker = "Virtualized",
    BigBetsTracker = "BigBets",

    EFSubPage = "SubPage",
    EFAddProjectPage = "AddProjectPage",
    EFAddPlanPage = "AddPlanPage",
    EFEditPage = "EditPage",
    /*end_EfficiencyTracker*/

    UnsubscribeMonthlyReport = "/UnsubscribeMonthlyReport",
    /*Insight Platform*/
    InsightPortal = "/InsightPortal",
    /*End Of Insight Platform*/

    Budget = "/Budget",
    SubmitBudget = "Submit",
    ReviewBudget = "Review",
    Report = "Report",
}

export const enum SubPages {
    Transaction = "Transaction",
    Ssd = "SSD",
    Hdd = "HDD",
    ProcessHosting = "ProcessHosting",
    Network = "Network",

    Public = "Public",
    AzSC = "AzSC",

    /*Insight Platform*/
    InsightPortalOverview = "InsightPortalOverview",
    /*End Of Insight Platform*/
}

export const enum Tabs {
    ItemRWQ = "Item Reads, Writes, and Queries",
    Assistants = "Assistants",
    ServiceInstance = "Service Instance",
    RequestsProcessed = "Requests Processed",
    DsapiRequests = "DSAPI Requests",
    CfmSubmitted = "CFM Submitted",
    ItemSize = "Item Size",
    SdsFastStorage = "SDS Fast Storage",
    KvCache = "KvCache",
    Overview = "Overview",
    ServiceDetails = "ServiceDetail",
    RegionDetails = "RegionDetail",
    SubscriptionDetails = "SubscriptionDetail",
    BudgetDetails = "BudgetDetail",
}

export const enum bannerTabs {
    Substrate = "Substrate",
    Transaction = "Transaction",
    Ssd = "SSD",
    AzureCompute = "Azure Compute",
    Telemetry = "Telemetry",
}